import { z } from "zod";
import { parseSchema } from "../../jason-proof-of-concept/shared/utils";

export const roleGroupDtoSchema = z.object({
    id: z.number(),
    name: z.string(),
    description: z.string().nullish(),
    isForCompany: z.coerce.boolean().nullish(),
    owner: z.number().nullish(),
    isPublic: z.coerce.boolean().nullish(),
});

export type RoleGroupDto = z.infer<typeof roleGroupDtoSchema>;

export const parseRoleGroup = (dto: RoleGroupDto) => {
    const roleGroup = parseSchema(roleGroupSchema, { ...dto, isForCompany: dto.isForCompany || false });
    return roleGroup;
};

export const roleGroupSchema = z.object({
    id: z.number(),
    name: z.string(),
    description: z.string().nullish(),
    isForCompany: z.coerce.boolean().nullish(),
    owner: z.number().nullish(),
    isPublic: z.coerce.boolean().nullish(),
});

export type RoleGroup = z.infer<typeof roleGroupSchema>;
