import { ClearERCUser } from "../../typings/api/clear-erc-user";

export function sortUsers(users: ClearERCUser[]) {
    return users.slice().sort((a, b) => {
        if (!a.firstName && !b.firstName) {
            const lastNameA = a.lastName ?? "";
            const lastNameB = b.lastName ?? "";
            const lastNameComparison = lastNameA.localeCompare(lastNameB);
            if (lastNameComparison !== 0) {
                return lastNameComparison;
            }
            return a.email.localeCompare(b.email);
        } else if (!a.firstName) {
            return 1;
        } else if (!b.firstName) {
            return -1;
        }
        const firstNameComparison = a.firstName.localeCompare(b.firstName);
        if (firstNameComparison !== 0) {
            return firstNameComparison;
        }
        const lastNameA = a.lastName ?? "";
        const lastNameB = b.lastName ?? "";
        const lastNameComparison = lastNameA.localeCompare(lastNameB);
        if (lastNameComparison !== 0) {
            return lastNameComparison;
        }
        return a.email.localeCompare(b.email);
    });
}
