/* eslint-disable prettier/prettier */
import { QueryCache, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useRecoilValue } from "recoil";
import Swal from "sweetalert2";
import userAtom from "../../../atoms/userAtom";
import { sendSms } from "../../../jason-proof-of-concept/sms/actions/send-sms";
import { Sms } from "../../../jason-proof-of-concept/sms/domain/sms";
import CheckBoxNeoGenControlled from "../../../layout/checkbox-controlled";
import Filter from "../../../layout/filter";
import ModalDialog from "../../../layout/modal-dialog";
import PrintPre from "../../../layout/print-pre";
import ProgressBar from "../../../layout/progress-bar";
import SelectNeoGen from "../../../layout/select-neogen";
import TextAreaNeoGenControlled from "../../../layout/text-area-controlled";
import TextAreaNeoGen from "../../../layout/text-area-neogen";
import { getAuthToken } from "../../../services/auth-header";
import authService from "../../../services/auth.service";
import interactionTypesService from "../../../services/interaction-types.service";
import interactionsService from "../../../services/interactions.service";
import usersService from "../../../services/users.service";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import { Interaction } from "../../../typings/api/interaction";
import Loader2 from "../../utilities/Loader2";

function compareUsers(a: ClearERCUser, b: ClearERCUser) {
    const aSort = a.name ?? a.email ?? "";
    const bSort = b.name ?? b.email ?? "";
    if (aSort.toLowerCase() < bSort.toLowerCase()) {
        return -1;
    }
    if (aSort.toLowerCase() > bSort.toLowerCase()) {
        return 1;
    }
    return 0;
}

export default function NewMessage({
    show,
    setShow,
    type,
    to,
}: {
    show: boolean;
    type: number;
    setShow: (show: boolean) => void;
    to?: string
}) {
    const user = useRecoilValue<ClearERCUser>(userAtom);
    const [interactionTo, setInteractionTo] = useState(user.id);
    const [interactionFrom, setInteractionFrom] = useState(user.id);
    const [interactionRegarding, setInteractionRegarding] = useState("");
    const [interactionType, setInteractionType] = useState<number>(type ?? 1);
    const [message, setMessage] = useState("");
    const [needsFollowUp, setNeedsFollowUp] = useState(0);
    const [followupDatetime, setFollowupDatetime] = useState<number>(Date.now());
    const [admin, setAdmin] = useState(false);
    const [modalTitle, setModalTitle] = useState("New Message");
    const [messageTitle, setMessageTitle] = useState("Message");
    const [messageRegardingTitle, setMessageRegardingTitle] = useState("Regarding");
    const [smsMessageHeader, setSmsMessageHeader] = useState("Sent via ClearERC: ");
    const [smsMessageFooter, setSmsMessageFooter] = useState("https://clearerc.com/m/dNwC3");
    const [smsMessage, setSmsMessage] = useState("");
    const [okText, setOkText] = useState("Send Message");
    const cache = useQueryClient();
    useEffect(() => {
        // TODO: Add this role to the backend and frontend
        authService.canIAccess("SEND_MESSAGES_FROM_SOMEONE_ELSE").then((res) => {
            if (res) {
                setAdmin(res);
            }
        });
    }, []);
    const messagesQuery = useQuery(["messages"], async () => {
        const response = await interactionsService.getAll();
        if (response) {
            return response.data;
        }
    });
    const messageTypesQuery = useQuery(["messageTypes"], async () => {
        const response = await interactionTypesService.getAll();
        if (response) {
            return response.data;
        }
    });

    const usersQuery = useQuery(["users", "sorted"], async () => {
        const response = await usersService.getAll();
        if (response) {
            return response.data.sort(compareUsers);
        }
    });

    useEffect(() => {

        const fullMessageLength = message.length + smsMessageHeader.length + smsMessageFooter.length
        // > 154) ? message.substring(0, 154) + "... " : message + " ")
        const messageBody = (fullMessageLength > 156) ? message.substring(0, 156 - smsMessageHeader.length - smsMessageFooter.length) + "... " : message + " "

        setSmsMessage(
            smsMessageHeader + " " + messageBody +
            smsMessageFooter
        )

    }, [message, smsMessageHeader, smsMessageFooter])


    useEffect(() => {
        if (to) {
            setInteractionTo(to);
        }
        if (type) {
            setInteractionType(type);
            switch (type) {
                case 1:
                    setInteractionFrom(user.id);
                    setModalTitle("Send Message");
                    setMessageTitle("Message");
                    setMessageRegardingTitle("Regarding");
                    setOkText("Send Message")
                    break;
                case 2:
                    setInteractionFrom(user.id);
                    setModalTitle("Add Phone Call");
                    setMessageTitle("Notes");
                    setMessageRegardingTitle("Regarding");
                    setOkText("Add Phone Call")
                    break;
                case 3:
                    setInteractionFrom(user.id);
                    setModalTitle("Add Notes");
                    setMessageTitle("Notes");
                    setMessageRegardingTitle("Regarding");
                    setOkText("Add Notes")
                    break;

            }
        }
    }, [to, type, user.id])

    if (messageTypesQuery.isLoading || usersQuery.isLoading || messagesQuery.isLoading) {
        return <Loader2 />;
    }

    return (
        <>
            <ModalDialog
                size="md"
                title={modalTitle}
                show={show}
                close={() => {
                    setShow(false);
                }}
                okText={okText}
                okAction={() => {
                    // Create the interaction
                    const interaction: Interaction = {
                        type: interactionType,
                        interaction_to: interactionTo ?? "",
                        interaction_from: interactionFrom ?? "",
                        interactionRegarding: interactionRegarding,
                        activity: message,
                        needsFollowUp: needsFollowUp,
                        followupDatetime: new Date(followupDatetime),
                        dateCreated: new Date(),
                        read: 0,
                        summary: "",
                    };
                    console.log(interaction);
                    // TODO: Create the notifications
                    const authToken = getAuthToken();
                    const to = usersQuery.data?.find((user) => user.id === interactionTo)?.phone;
                    const from = usersQuery.data?.find((user) => user.id === interactionFrom)?.phone;
                    if (to && from) {
                        const body = smsMessage
                        const data: Sms = {
                            to, from, body
                        }
                        sendSms({ authToken, data })
                    }

                    interactionsService
                        .create(interaction, true)
                        .then((response) => {
                            console.log(response);
                            cache.invalidateQueries(["messages"]);
                            cache.invalidateQueries(["notes"]);
                            messagesQuery.refetch();


                            setShow(false);
                        })
                        .catch((error) => {
                            console.log(error);
                            Swal.fire({
                                title: "Error",
                                text: "There was an error creating the interaction",
                                icon: "error",
                            });
                        });
                }}
            >
                <div className="flex flex-col space-y-4">
                    {!type && (
                        <div className="flex flex-col space-y-2">
                            <SelectNeoGen
                                label="Message Type"
                                options={messageTypesQuery.data}
                                value={interactionType}
                                onChange={(value) => {
                                    setInteractionType(Number(value));
                                }}
                            />
                        </div>
                    )}
                    {type === 1 && (
                        <>
                            <div className="flex flex-col space-y-2">
                                <Filter
                                    hideReset={true}
                                    label={"Message To"}
                                    options={usersQuery.data?.map((u) => {
                                        return {
                                            id: u.id,
                                            name: (u.name ?? "") + " (Email: " + u.email + " Phone: " + u.phone + ")",
                                        }
                                    })}
                                    value={interactionTo}
                                    onChange={(value) => {
                                        setInteractionTo(value.toString());
                                    }}
                                />
                            </div>
                        </>
                    )}

                    {admin && (
                        <>
                            <div className="flex flex-col space-y-2">
                                <Filter
                                    hideReset={true}
                                    label="Interaction By"
                                    options={usersQuery.data?.map((u) => {
                                        return {
                                            id: u.id,
                                            name: (u.name ?? "") + " (" + u.email + ")",
                                        };
                                    })}
                                    value={interactionFrom}
                                    onChange={(value) => {
                                        setInteractionFrom(value.toString());
                                    }}
                                />
                            </div>
                        </>
                    )}

                    <div className="flex flex-col space-y-2">
                        <Filter
                            label={messageRegardingTitle}
                            hideReset={true}
                            options={usersQuery.data?.map((u) => {
                                return {
                                    id: u.id,
                                    name: (u.name ?? "") + " (" + u.email + ")",
                                };
                            })}
                            value={interactionRegarding}
                            onChange={(value) => {
                                setInteractionRegarding(value.toString());
                            }}
                        />
                    </div>
                    <TextAreaNeoGenControlled
                        label={messageTitle}
                        value={message}
                        setValue={(value) => {
                            setMessage(value);
                        }}
                    />
                    {type === 1 && (
                        <div className="grid grid-cols-1 max-w-xs ">
                            <div className={
                                "block text-sm font-medium leading-normal " +
                                "text-gray-600  dark:text-gray-400"
                            }>

                                {smsMessage.length > 160 ? (
                                    <span className={smsMessage.length > 160 ? "text-red-500 font-bold " : ""}>
                                        SMS Version trimmed. {message.length + smsMessageHeader.length + smsMessageFooter.length + "...".length}/160 characters including ellipsis, header and footer
                                    </span>
                                ) : (
                                    <span className="">SMS Version {smsMessage.length}/160 characters</span>
                                )}



                            </div>
                            <div className="mt-2">
                                <ProgressBar percentDone={Math.round((smsMessage.length / 160) * 100)} />
                            </div>

                            <div className={
                                "block mt-0  text-sm font-medium leading-normal  shadow " +
                                "text-gray-600  dark:text-gray-400 bg-gray-200 dark:bg-gray-700 " +
                                "border-gray-300 dark:border-gray-600 border-2 rounded-xl p-5"
                            }>
                                {smsMessage}
                            </div>

                        </div>
                    )
                    }
                    <div className="flex flex-col space-y-2">
                        <div className=" ">
                            <CheckBoxNeoGenControlled
                                label="Needs Follow Up"
                                name="needsFollowUp"
                                className="flex-grow"
                                value={needsFollowUp === 1}
                                setValue={(value) => {
                                    setNeedsFollowUp(value.target.checked ? 1 : 0);
                                }}
                            />
                            {needsFollowUp === 1 && (
                                <div className="mt-5 ml-0">
                                    <label className="text-xs text-gray-500 dark:text-gray-400">Follow Up Date</label>
                                    <ReactDatePicker
                                        className="flex-shrink text-xs border rounded-md  border-gray-300 dark:border-gray-900 w-full h-[38px] dark:bg-gray-900"
                                        // selected={props.value}
                                        // setSelected={setSelected}
                                        selected={new Date(followupDatetime)}
                                        showTimeSelect={true}
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        onChange={(date: Date) => {
                                            // alert(date.toLocaleString());
                                            setFollowupDatetime(date.getTime());
                                            // setSelected(date);
                                            // props.onChange(date);
                                        }}
                                    />
                                    {/* <PrintPre>{new Date(followupDatetime).toLocaleString()}</PrintPre> */}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </ModalDialog>
        </>
    );
}
