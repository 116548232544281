import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { CompanyReviewUser } from "../domain/company-review-user";
import { companyReviewUserSchema } from "./create-company-review-users";

export const getCompanyReviewUsers = async ({ authToken }: { authToken?: string }) => {
    const response = await makeRequest({
        method: "get",
        path: "/company-review-users",
        authToken,
    });

    const companyReviewUsers = parseSchema(z.array(companyReviewUserSchema), response.data);

    return companyReviewUsers;
};
