/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/23/20, 7:36 PM
 *
 */

import { AxiosResponse } from "axios";
// import { Company } from "../typings/api";
import APIService from "./api.service";
import authService from "./auth.service";
import AuthService from "./auth.service";
import { Company } from "../companies/domain/company";

class CompanyService extends APIService<Company> {
    constructor() {
        super("companies");
    }
    async getAll(params?: { filters?: any }): Promise<void | AxiosResponse<Company[]>> {
        const queryString = params?.filters ? `?filters=${JSON.stringify(params.filters)}` : "";
        return super.getURL(`companies?${queryString}`);
    }
}

export default new CompanyService();
