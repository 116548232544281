import { z } from "zod";
import { makeRequest, parseSchema } from "../../../jason-proof-of-concept/shared/utils";
import { alertRuleSchema } from "../domain/alert-rule";
import { alertRuleChannelSchema } from "../domain/alert-rule-channel";

export const getAlertRuleChannels = async ({ authToken, filters }: { authToken: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: "/alert-rule-channels",
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });

    const alertRuleChannels = parseSchema(z.array(alertRuleChannelSchema), response.data);

    return alertRuleChannels;
};
