import { ClearERCUser } from "../../typings/api/clear-erc-user";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { useMutation } from "@tanstack/react-query";
import { useRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import ButtonNeoGen from "../../layout/button-neogen";
import { calendarEventEngagementTypes } from "../domain/calendar-event";
import { useUsers } from "../../jason-proof-of-concept/users/hooks/use-users";
import { createCalendarEvent } from "../actions/create-calendar-event";
import { z } from "zod";
import { useForm } from "../../hooks/useForm";
import ErrorSection from "../../layout/error-section";
import { TextField } from "../../layout/form/text-field";
import { TextAreaField } from "../../layout/form/text-area-field";

import { Form } from "../../layout/form/form";
import { DateTimeField } from "../../layout/form/date-field";
import { SelectField } from "../../layout/form/select-field";

const schema = z.object({
    title: z.string(),
    description: z.string(),
    assigneeId: z.string(),
    date: z.date(),
    engagementType: z.string(),
    userId: z.string().optional(),
});

type Data = z.infer<typeof schema>;

export const AddCalendarEventSection = ({ onClose, user }: { onClose: () => void; user?: ClearERCUser }) => {
    const [loggedInUser, setUser] = useRecoilState(userAtom);
    const authToken = getAuthTokenNoThrow() || "no-token";

    const form = useForm({ schema });

    const usersQuery = useUsers({ authToken });
    const users = usersQuery.data || [];

    const mutation = useMutation({
        mutationFn: async (data: Record<string, any>) => {
            const result = await createCalendarEvent({
                authToken,
                data: { ...data, createdById: loggedInUser.id, userId: user?.id || data.userId },
            });
            return result;
        },
    });

    const handleSubmit = async (data: Data) => {
        const result = await mutation.mutateAsync(data);
        onClose();
    };

    return (
        <>
            {mutation.error && <ErrorSection errors={[(mutation.error as any)?.message || "Something went wrong"]} />}
            <Form onSubmit={form.handleSubmit(handleSubmit)}>
                <div className="flex flex-col gap-4 justify-between items-start w-full">
                    <div className="w-full">
                        <DateTimeField label="Date" {...form.getFieldProps("date")} />
                    </div>
                    <div className="w-full">
                        <SelectField
                            label="Assignee (Agent)"
                            isRequired
                            {...form.getFieldProps("assigneeId")}
                            options={Object.values(users).map((a) => ({
                                value: a.id,
                                label: `${a.firstName} ${a.lastName} ${a.name ? `(${a.name})` : ""}`,
                            }))}
                        />
                    </div>
                    {!user && (
                        <div className="w-full">
                            <SelectField
                                label="User (Customer)"
                                isRequired
                                {...form.getFieldProps("userId")}
                                options={Object.values(users).map((a) => ({
                                    value: a.id,
                                    label: `${a.firstName} ${a.lastName} ${a.name ? `(${a.name})` : ""}`,
                                }))}
                            />
                        </div>
                    )}
                    <div className="w-full">
                        <SelectField
                            label="Engagement type"
                            isRequired
                            {...form.getFieldProps("engagementType")}
                            options={Object.values(calendarEventEngagementTypes).map((a) => ({
                                value: a,
                                label: a.slice(0, 1).toUpperCase() + a.slice(1).replaceAll("_", " "),
                            }))}
                        />
                    </div>
                    <div className="w-full">
                        <TextField label="Title" {...form.getFieldProps("title")} isRequired />
                    </div>

                    <div className="w-full">
                        <TextAreaField label="Description" {...form.getFieldProps("description")} />
                    </div>
                    <div className="flex gap-2 justify-end w-full">
                        <ButtonNeoGen text="Cancel" onClick={onClose} className="!bg-red-500" />
                        <ButtonNeoGen type="submit" disabled={mutation.isLoading}>
                            {mutation.isLoading ? "Loading..." : "Save"}
                        </ButtonNeoGen>
                    </div>
                </div>
            </Form>
        </>
    );
};
