// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { faCheck } from "@fortawesome/pro-solid-svg-icons";
//
// library.add(faCheck);
interface ToastProps {
    position: "topLeft" | "topRight" | "bottomLeft" | "bottomRight";
    message: string;
    onClose: () => void;
}

function getToastPosition(position: ToastProps["position"]) {
    switch (position) {
        case "topRight":
            return "top-40 right-10";
        case "topLeft":
            return "top-40 left-10";
        case "bottomRight":
            return "-bottom-8 right-10";
        case "bottomLeft":
            return "-bottom-8 left-10";
        default:
            return "top-4 right-4";
    }
}

export const Toast = ({ position = "bottomRight", message, onClose }: ToastProps) => {
    return (
        <div
            className={`${getToastPosition(
                position,
            )} z-50 flex items-center justify-center py-2 px-4 mb-4 text-sm font-medium leading-5 text-white transition-all duration-150 ease-in-out rounded-lg  outline-none focus:outline-none absolute`}
        >
            <div
                id="toast-success"
                className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800 transform opacity-1 translate-y-[-50%] transition-all duration-150 ease-in-out relative"
                role="alert"
            >
                <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                    <span className={"fas fa-check"} />
                    <span className="sr-only">Success icon</span>
                </div>
                <div className="mx-4 text-md font-normal">{message}</div>
                <button
                    type="button"
                    className="outline-none ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                    data-dismiss-target="#toast-success"
                    aria-label="Close"
                    onClick={onClose}
                >
                    <span className="sr-only">Close</span>
                    <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </button>
            </div>
        </div>
    );
};
