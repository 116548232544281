import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { paymentRequestSchema } from "../domain/payment-request";
import { getPaymentRequest } from "./get-payment-request";

const updatePaymentRequestSchema = paymentRequestSchema.omit({ id: true, createdAt: true, updatedAt: true }).partial();

export type UpdatePaymentRequestData = z.infer<typeof updatePaymentRequestSchema>;

export const updatePaymentRequest = async ({
    authToken,
    id,
    data,
}: {
    authToken: string;
    id: string;
    data: UpdatePaymentRequestData;
}) => {
    await makeRequest({
        method: "patch",
        path: `/payment-requests/${id}`,
        authToken,
        data,
    });
    const paymentRequest = await getPaymentRequest({ authToken, id });
    return paymentRequest;
};
