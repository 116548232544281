import { makeRequest } from "../../shared/utils";
import { User } from "../domain/user";
import { getUser } from "./get-user";

export const updateUser = async ({ authToken, id, data }: { authToken: string; id: string; data: Partial<User> }) => {
    await makeRequest({
        method: "patch",
        path: `/users/${id}`,
        authToken,
        data,
    });

    const updatedUser = await getUser({ authToken, id });

    return updatedUser;
};
