import { HTMLAttributes, ReactNode } from "react";

export const Table = ({ children }: { children?: ReactNode }) => {
    return (
        <table className="w-full divide-y divide-gray-300 dark:divide-gray-900 rounded-lg table-fixed">
            {children}
        </table>
    );
};

export const THead = ({ children }: { children?: ReactNode }) => {
    return <thead className="bg-gray-50 dark:bg-gray-800 rounded-t-xl shadow-md">{children}</thead>;
};

export const TBody = ({ children }: { children?: ReactNode }) => {
    return (
        <tbody className="rounded-xl divide-y divide-gray-200 bg-white dark:bg-gray-800 dark:divide-gray-900">
            {children}
        </tbody>
    );
};

export const Tr = ({ children, ...other }: { children: ReactNode } & HTMLAttributes<HTMLTableRowElement>) => {
    return <tr {...other}>{children}</tr>;
};

export const Th = ({ children, ...other }: { children?: ReactNode } & HTMLAttributes<HTMLTableHeaderCellElement>) => {
    return (
        <th
            scope="col"
            className="dark:text-gray-500 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            {...other}
        >
            {children}
        </th>
    );
};

export const Td = ({
    children,
    ...other
}: { children?: ReactNode } & HTMLAttributes<HTMLTableDataCellElement> & { colSpan?: number }) => {
    return (
        <td className="py-4 pl-4 pr-3 text-sm sm:pl-6" {...other}>
            {children}
        </td>
    );
};
