import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { userResponseSchema } from "../../jason-proof-of-concept/users/actions/get-users";

export const getCompanyUsers = async ({ authToken, id, filters }: { authToken: string; id: number; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: `/companies/${id}/users`,
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });

    const users = parseSchema(z.array(userResponseSchema), response.data);

    return users;
};
