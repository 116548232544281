import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import PageDescription from "../../layout/page-description";
import SearchField from "../../layout/search-field";
import TableNeogen from "../../layout/table-neogen";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import { search as ssSearch } from "ss-search";
import usersService from "../../services/users.service";
import { debounce } from "lodash";

export default function Affiliates() {
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");

    const affiliatesQuery = useQuery(["affiliates"], async () => {
        const response = await usersService.getAll({ filters: { role: "Affiliate" } });
        return response?.data || [];
    });

    const affiliates = React.useMemo(
        () =>
            debouncedSearch
                ? (ssSearch(
                      affiliatesQuery.data || [],
                      ["firstName", "lastName", "email", "phone"],
                      debouncedSearch,
                  ) as ClearERCUser[])
                : affiliatesQuery.data || [],
        [debouncedSearch, affiliatesQuery.data],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateDebouncedSearch = useCallback(
        debounce(
            (term) => {
                setDebouncedSearch(term);
            },
            700,
            { trailing: true, maxWait: 1000 },
        ),
        [],
    );

    useEffect(() => {
        updateDebouncedSearch(search);
    }, [search, updateDebouncedSearch]);

    return (
        <>
            <PageDescription
                title="Affiliates"
                description="A list of people that are working for you to bring in clients."
            >
                <SearchField search={search} setSearch={setSearch} placeholder="Search affiliates..." />
            </PageDescription>

            <div className=" pb-56 md:overflow-hidden rounded-xl ">
                <TableNeogen
                    entries={affiliates.map((user: ClearERCUser) => {
                        const subAffiliatesOrDeals = user.affiliateUsers || [];
                        const subAffiliates: ClearERCUser[] = [];
                        const deals = [];
                        subAffiliatesOrDeals.forEach((user) => {
                            if (affiliates.find((u) => u.id === user.id)) {
                                subAffiliates.push(user);
                            } else {
                                deals.push(user);
                            }
                        });

                        return {
                            firstName: `${user.firstName} ${user.lastName}` || "-",
                            email: user.email || "-",
                            phone: user.phone || "-",
                            numberOfDeals: deals.length,
                            numberOfSubAffiliates: subAffiliates.map((sa) => sa.email),
                        };
                    })}
                    headers={["Name", "Email", "Phone", "Number of deals", "Number of Sub Affiliates"]}
                    formatters={[
                        {
                            field: "phone",
                            type: "Phone",
                        },
                        {
                            field: "email",
                            type: "Email",
                        },
                    ]}
                />
            </div>
        </>
    );
}
