import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { Alert, AlertSchema } from "../domain/alert";

export const updateAlerts = async ({
    authToken,
    data,
    id,
}: {
    authToken?: string;
    data: Partial<Alert>;
    id: number;
}) => {
    const response = await makeRequest({
        method: "patch",
        path: "/alerts/" + id,
        authToken,
        data,
    });

    const alerts = parseSchema(AlertSchema, response.data);

    return alerts;
};
