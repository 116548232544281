import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { calendarEventSchema } from "../domain/calendar-event";

export const getCalendarEvents = async ({ authToken, filters }: { authToken: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: "/calendar-events",
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });

    const dtos = parseSchema(z.array(calendarEventSchema), response.data);

    const calendarEvents = parseSchema(
        z.array(calendarEventSchema),
        dtos.map((dto) => ({ ...dto })),
    );

    return calendarEvents;
};
