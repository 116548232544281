import { Dispatch, SetStateAction } from "react";
import TableNeogen from "../../../layout/table-neogen";
import { AlertRule } from "../domain/alert-rule";
import { AlertRuleChannel } from "../domain/alert-rule-channel";
import { AlertType } from "../domain/alert-type";

export const AlertsTable = ({
    alertRulesData,
    alertRuleChannelsData,
    alertTypesData,
    setAlertRuleToDelete,
    setAlertRuleToUpdate,
}: {
    alertRulesData: AlertRule[] | undefined;
    alertRuleChannelsData: AlertRuleChannel[] | undefined;
    alertTypesData: AlertType[] | undefined;
    setAlertRuleToDelete: Dispatch<SetStateAction<number | undefined>>;
    setAlertRuleToUpdate: Dispatch<SetStateAction<number | undefined>>;
}) => {
    return (
        <div className="mt-5">
            {alertRulesData && alertRulesData.length > 0 ? (
                <TableNeogen
                    entries={(alertRulesData || []).map((alertRule) => {
                        const alertType = alertTypesData?.find((a) => a.id === alertRule.alertTypeId)?.alertType;
                        const channels = alertRuleChannelsData
                            ?.filter((d) => d.alertRuleId === alertRule.id)
                            .map((a) => a.channel)
                            .join(", ");
                        return {
                            id: alertRule.id,
                            createdAt: alertRule.createdAt,
                            alertType: alertType || "-",
                            channels: channels || "-",
                            title: alertRule.title,
                            body: alertRule.body,
                        };
                    })}
                    headers={["Created At", "Notification type", "Channels", "Title", "Body"]}
                    actionsAreDropDown={true}
                    actions={[
                        {
                            label: "Edit alert",
                            onClick: (id: number) => {
                                if (typeof id === "number") {
                                    setAlertRuleToUpdate(id);
                                }
                            },
                            icon: "fal fa-pencil",
                        },
                        {
                            label: "Delete alert",
                            onClick: (id: number) => {
                                if (typeof id === "number") {
                                    setAlertRuleToDelete(id);
                                }
                            },
                            icon: "fad fa-trash !text-red-500",
                        },
                    ]}
                    formatters={[
                        {
                            field: "createdAt",
                            type: "DateTime",
                        },
                    ]}
                />
            ) : (
                <p className="text-gray-600">
                    No notification configs are configured. Click on &quot;Add a notification config&quot; to set up a
                    notification config.
                </p>
            )}
        </div>
    );
};
