/* eslint-disable react/display-name */
import React, { forwardRef } from "react";

import { Action, ActionProps } from "../Action";

export const Handle = forwardRef<HTMLButtonElement, ActionProps>((props, ref) => {
    return (
        <Action ref={ref} cursor="grab" data-cypress="draggable-handle" {...props} className="">
            <span className="fal fa-2x fa-grip-dots-vertical my-auto ml-2 mr-2  text-gray-400 hover:text-gray-800" />
        </Action>
    );
});
