import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { PaymentRequest } from "../domain/payment-request";
import { getPaymentRequests } from "../actions/get-payment-requests";

type Filters = { where?: any };

const getPaymentRequestsQueryKey = ({ authToken, filters }: { authToken: string; filters?: Filters }) => {
    return ["payment-requests", { authToken, filters }];
};

export const usePaymentRequests = (
    { authToken, filters }: { authToken: string; filters?: Filters },
    options?: UseQueryOptions<PaymentRequest[]>,
) => {
    const query = useQuery({
        queryKey: getPaymentRequestsQueryKey({ authToken, filters }),
        queryFn: async () => {
            const paymentRequests = await getPaymentRequests({ authToken, filters });
            return paymentRequests;
        },
        ...options,
    });
    return query;
};
