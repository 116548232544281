import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { Form } from "../../../layout/form/form";
import { EmailField } from "../../../layout/form/email-field";
import { useForm } from "../../../hooks/useForm";
import { useMutation } from "@tanstack/react-query";
import { createUserCompany } from "../../../user-companies/actions/create-user-company";
import { getUsers } from "../../../jason-proof-of-concept/users/actions/get-users";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { getUserCompanies } from "../../../user-companies/actions/get-user-companies";
import { useRecoilState } from "recoil";
import userAtom from "../../../atoms/userAtom";
import { User } from "../../../jason-proof-of-concept/users/domain/user";
import { SelectField } from "../../../layout/form/select-field";
import { inviteUserToCompany } from "../../actions/invite-user-to-company";
import authService from "../../../services/auth.service";
import { roleAssignments, roleGroups } from "../../../services/role-group.service";
import { useUsers } from "../../../jason-proof-of-concept/users/hooks/use-users";
import { orderBy } from "lodash";
import { useEffect, useMemo } from "react";
import { NumberField } from "../../../layout/form/number-field";

const defaultCommissions = {
    [roleGroups.DocCollector]: 2,
    [roleGroups.SalesRep]: 1,
    [roleGroups.SubstantiationTeam]: 2,
    [roleGroups.DocCollectionManager]: 1,
    [roleGroups.SubstantiationManager]: 1,
    [roleGroups.SalesManager]: 1,
    [roleGroups.AffiliateManager]: 2,
    [roleGroups.DirectorOfSales]: 2,
};

const initialFormSchema = z.object({
    userId: z.string().uuid().optional(),
    email: z.string().email().optional(),
    roleId: z.number(),
    commissionPercentage: z.number().optional(),
});

type InitialFormData = z.infer<typeof initialFormSchema>;

export const InviteExistingUserModal = ({
    onClose,
    companyId,
    onUserAdded,
    companyRoles,
}: {
    onClose: () => any;
    companyId: number;
    onUserAdded: (user: User) => any;
    companyRoles: NonNullable<User["companyRoleGroups"]>;
}) => {
    const [user] = useRecoilState(userAtom);
    if (!user.id) {
        throw new Error("User not defined.");
    }
    const initialForm = useForm({ schema: initialFormSchema });
    const authToken = getAuthTokenNoThrow() || "no-token";

    const initialMutation = useMutation({
        mutationFn: async (data: InitialFormData) => {
            await inviteUserToCompany({
                authToken,
                id: companyId,
                data: { userId: data.userId, roleId: data.roleId, commissionPercentage: data.commissionPercentage },
            });
            return { user };
        },
    });

    const handleSubmit = async (data: InitialFormData) => {
        const { user } = await initialMutation.mutateAsync(data);
        if (user) {
            onUserAdded(user as any);
        }
    };

    const usersQuery = useUsers({ authToken });
    const users = usersQuery.data || [];

    const roleId = initialForm.watch("roleId");

    useEffect(() => {
        const commissionToUse = defaultCommissions[roleId] || 0;
        initialForm.setValue("commissionPercentage", commissionToUse);
    }, [roleId]);

    const filteredUsers = users.filter((user) => {
        if (roleId) {
            return (user.roleGroups || []).find((rg) => rg.id === roleId);
        }
        return true;
    });

    const usersRoleGroups = (authService.getCurrentUser()?.user?.roleGroups || []) as any[];
    const roleGroupsIds = usersRoleGroups.reduce<number[]>((acc, roleGroup: any) => {
        return [...acc, ...(roleAssignments?.[roleGroup.id] || [])];
    }, []);
    const finalRoleGroups = companyRoles.filter((rg) => roleGroupsIds.includes(rg.id || 9999));
    const isSuperUser = !!usersRoleGroups.find((rg) => rg.id === roleGroups.SuperUser);

    const roleOptions = useMemo(
        () =>
            orderBy(
                finalRoleGroups.map((role) => ({ label: role.name || "", value: role.id as number })),
                (o) => o.label,
            ),
        [finalRoleGroups],
    );
    const userOptions = useMemo(
        () =>
            orderBy(
                filteredUsers.map((user) => ({
                    label: `${user.firstName} ${user.lastName} (${user.email})` || "",
                    value: user.id,
                })),
                (o) => o.label,
            ),
        [filteredUsers],
    );

    return (
        <ModalDialog title="Add user to company" close={onClose} show showOk={false} showCancel={false}>
            <p className="px-4 text-gray-400 text-center">Invite an existing user on the system to this company.</p>
            <div className="pt-6">
                <Form onSubmit={initialForm.handleSubmit(handleSubmit)} error={initialMutation.error as any}>
                    {isSuperUser ? (
                        <>
                            <SelectField
                                label="Select role"
                                {...initialForm.getFieldProps("roleId")}
                                options={roleOptions}
                            />
                            <SelectField
                                label="Select user"
                                {...initialForm.getFieldProps("userId")}
                                options={userOptions}
                            />
                            <NumberField
                                label="Commission percentage"
                                {...initialForm.getFieldProps("commissionPercentage")}
                            />
                        </>
                    ) : (
                        <>
                            <EmailField label="Email" {...initialForm.getFieldProps("email")} />
                            <SelectField
                                label="Select role"
                                {...initialForm.getFieldProps("roleId")}
                                options={roleOptions}
                            />
                        </>
                    )}
                    <div className="flex flex-row justify-end">
                        <div className="flex flex-row gap-2">
                            <ButtonNeoGen type="outline" onClick={onClose}>
                                Cancel
                            </ButtonNeoGen>
                            <ButtonNeoGen type="submit" disabled={initialMutation.isLoading}>
                                Invite user
                            </ButtonNeoGen>
                        </div>
                    </div>
                </Form>
            </div>
        </ModalDialog>
    );
};
