import { z } from "zod";
import { userSchema } from "../../jason-proof-of-concept/users/domain/user";

export enum MagicLinkTypes {
    clientSignUp = "clientSignUp",
    affiliateSignUp = "affiliateSignUp",
    userCompanyInvite = "userCompanyInvite",
    userInvite = "userInvite",
}

export const magicLinkSchema = z.object({
    id: z.string().uuid(),
    createdAt: z.coerce.date().nullish(),
    updatedAt: z.coerce.date().nullish(),
    createdById: z.string().uuid(),
    createdByUser: userSchema.optional(),
    type: z.nativeEnum(MagicLinkTypes),
    data: z.record(z.any()),
    code: z.string(),
    usedAt: z.coerce.date().nullish(),
    options: z
        .object({ affiliateUserId: z.string().uuid().optional(), docCollectorId: z.string().uuid().optional() })
        .nullish(),
});

export const magicLinkResponseSchema = magicLinkSchema.extend({
    createdByUser: z
        .object({
            affiliateUserId: z.string().uuid().optional(),
            docCollectorId: z.string().uuid().optional(),
            companyId: z.number().optional(),
        })
        .nullish(),
});

export type MagicLink = z.infer<typeof magicLinkSchema>;
