import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { companySchema } from "../domain/company";

export const getCompanies = async ({ authToken, filters }: { authToken: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: "/companies",
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });

    const companies = parseSchema(z.array(companySchema), response.data);

    return companies;
};
