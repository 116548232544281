import ButtonNeoGen from "../../../layout/button-neogen";
import InputControlled from "../../../layout/input-controlled";
import InputNeoGen from "../../../layout/input-neogen";
import PageDescription from "../../../layout/page-description";
import PrintPre from "../../../layout/print-pre";
import authService from "../../../services/auth.service";

export function GetAuthToken() {
    return (
        <PageDescription>
            <div className="break-all w-full text-right">
                <InputControlled
                    label="Your Auth Token"
                    className="h-8 w-full overflow-y-scroll break-all"
                    value={authService.getCurrentUser().token}
                />
                <ButtonNeoGen
                    className="mt-5"
                    onClick={() => {
                        navigator.clipboard.writeText(authService.getCurrentUser().token);
                    }}
                >
                    Copy To Clipboard
                </ButtonNeoGen>
            </div>
        </PageDescription>
    );
}
