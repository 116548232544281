import { useMutation } from "@tanstack/react-query";
import { CreateInteractionData, createInteraction } from "../actions/create-interaction";

export const useCreateInteraction = () => {
    const mutation = useMutation({
        mutationFn: async ({ authToken, data }: { authToken: string; data: CreateInteractionData }) => {
            const interaction = await createInteraction({ authToken, data });
            return interaction;
        },
    });
    return mutation;
};
