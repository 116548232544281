import { useState } from "react";
import { NewNoteModal } from "../../interactions/components/new-note-modal";
import ButtonNeoGen from "../../layout/button-neogen";
import { Interaction } from "../../interactions/domain/interaction";
import { timeAgo } from "../../sections/users/cards/calls-list";
import { NewCallModal } from "../../interactions/components/new-call-modal";
import { User } from "../../jason-proof-of-concept/users/domain/user";
import { RoleGroup } from "../../role-groups/domain/role-group";
import { Company } from "../domain/company";
import { UserCompany } from "../../user-companies/domain/user-company";
import { NewMessageModal } from "../../interactions/components/new-message-moda";
import Avatar from "react-avatar";

export const Messages = ({
    messages: messages,
    onMessageCreated: onMessageCreated,
    companyOwnerId,
    users,
    company,
    userCompanies,
    roles,
}: {
    messages: Interaction[];
    onMessageCreated: (note: Interaction) => void;
    companyOwnerId: string;
    users: User[];
    company: Company;
    userCompanies: UserCompany[];
    roles: RoleGroup[];
}) => {
    const [showNewMessageModal, setShowNewMessageModal] = useState(false);

    return (
        <div>
            {showNewMessageModal && (
                <NewMessageModal
                    forUserId={companyOwnerId}
                    onClose={() => setShowNewMessageModal(false)}
                    users={users}
                    company={company}
                    roles={roles}
                    userCompanies={userCompanies}
                    onMessageCreated={(message) => {
                        onMessageCreated(message);
                        setShowNewMessageModal(false);
                    }}
                />
            )}
            {messages.length === 0 && (
                <div className="flex justify-center items-center h-[70vh]">
                    <div className="text-center">
                        <div className="text-xl mb-4">No messages here yet...</div>
                        <ButtonNeoGen type="primary" onClick={() => setShowNewMessageModal(true)}>
                            Send new message
                        </ButtonNeoGen>
                    </div>
                </div>
            )}
            {messages.length > 0 && (
                <>
                    <div className="flex flex-row justify-end">
                        <ButtonNeoGen type="primary" onClick={() => setShowNewMessageModal(true)}>
                            New message
                        </ButtonNeoGen>
                    </div>
                    <div className="flow-root">
                        <ul role="list" className="-mb-8">
                            {messages.map((message) => (
                                <li key={message.id}>
                                    <div className="relative pb-8">
                                        <div className="relative flex items-start space-x-3">
                                            <div className="relative pl-2 pt-2 pr-1">
                                                <Avatar
                                                    name={[
                                                        message.interactionFromUser?.firstName,
                                                        message.interactionFromUser?.lastName,
                                                    ].join(" ")}
                                                    size="44"
                                                    email={message.interactionFromUser?.email}
                                                    round={true}
                                                    className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-slate-200 dark:ring-gray-700"
                                                />
                                            </div>
                                            <div className="min-w-0 flex-1">
                                                <div>
                                                    <div className="inline-block">
                                                        {message.interactionFromUser?.firstName}{" "}
                                                        {message.interactionFromUser?.lastName} to{" "}
                                                        {message.interactionToUser?.firstName}{" "}
                                                        {message.interactionToUser?.lastName}
                                                    </div>
                                                </div>
                                                <div className="text-sm text-gray-700">
                                                    <p className="text-xs text-gray-400">
                                                        {message.dateCreated && timeAgo(message.dateCreated).toString()}{" "}
                                                    </p>
                                                    <p>{message.activity}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            )}
        </div>
    );
};
