import { useQuery } from "@tanstack/react-query";
import { getCompanies } from "../actions/get-companies";
import { getCompany } from "../actions/get-company";

type Params = {
    authToken: string;
    id: number;
};

const getCompanyQueryKey = ({ authToken, id }: Params) => {
    return ["company", { authToken, id }];
};

export const useCompany = ({ authToken, id }: Params) => {
    const query = useQuery({
        queryKey: getCompanyQueryKey({ authToken, id }),
        queryFn: async () => {
            const company = await getCompany({ authToken, id });
            return company;
        },
    });
    return query;
};
