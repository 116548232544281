import { z } from "zod";
import { Form, FormModalButtons } from "../../layout/form/form";
import { TextField } from "../../layout/form/text-field";
import ButtonNeoGen from "../../layout/button-neogen";
import ModalDialog from "../../layout/modal-dialog";
import { useForm } from "../../hooks/useForm";
import { MagicLink } from "../domain/magic-link";
import ErrorSection from "../../layout/error-section";
import { EditMagicLinkData } from "../actions/edit-magic-link";
import { isEmpty } from "lodash";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import { sortUsers } from "../../sections/utilities/sortUsers";
import { SelectField } from "../../layout/form/select-field";

const validationMessage = "This field is required";
const schema = z.object({
    id: z.string().nullish(),
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    companyName: z
        .string()
        .min(2, "This field must contain at least 2 characters")
        .nonempty()
        .nullable()
        .refine((value) => value !== null, validationMessage),
    code: z.string().nullish(),
    email: z
        .string()
        .nonempty()
        .email("This is not a valid email address")
        .nullable()
        .refine((value) => value !== null, validationMessage),
    phone: z.string().nullish(),
    w2Employees2020: z
        .number()
        .nullable()
        .refine((value) => (value === null ? false : value >= 0), validationMessage)
        .or(z.string().min(1, validationMessage).nonempty()),
    w2Employees2021: z
        .number()
        .nullable()
        .refine((value) => (value === null ? false : value >= 0), validationMessage)
        .or(z.string().min(1, validationMessage).nonempty()),
    affiliateId: z.string().nullish(),
    docCollectorId: z.string().nullish(),
});

export type Data = z.infer<typeof schema>;

type magicLinkMode = "edit" | "create";
interface MagicLinkModalProps {
    defaultValues?: EditMagicLinkData;
    onClose: () => void;
    magicLink: MagicLink;
    magicLinkLink?: string;
    hasCopied: boolean;
    setHasCopied: (hasCopied: boolean) => void;
    handleSubmit: (data: Data) => void;
    affiliates: any;
    users: ClearERCUser[];
    mutation: any;
    okText: string;
    title: string;
    type: magicLinkMode;
}

export const MagicLinkModal = ({
    defaultValues,
    onClose,
    magicLink,
    magicLinkLink,
    hasCopied,
    setHasCopied,
    handleSubmit,
    affiliates,
    users,
    mutation,
    okText,
    title,
    type,
}: MagicLinkModalProps) => {
    const form = useForm({
        schema,
        defaultValues: !isEmpty(defaultValues)
            ? {
                  firstName: defaultValues.data.firstName,
                  lastName: defaultValues.data.lastName,
                  companyName: defaultValues.data.companyName,
                  email: defaultValues.data.email,
                  phone: defaultValues.data.phone,
                  w2Employees2020: defaultValues.data.w2Employees2020,
                  w2Employees2021: defaultValues.data.w2Employees2021,
                  affiliateId: defaultValues.data.affiliateId,
              }
            : {},
    });

    return (
        <ModalDialog size="sm" show={true} close={onClose} title={title} showCancel={false} showOk={false}>
            {mutation.error && <ErrorSection errors={[(mutation.error as any)?.message || "Something went wrong"]} />}
            {mutation.isSuccess && magicLink ? (
                <div>
                    <h2 className="mb-4 text-center">{`Magic Link ${type === "create" ? "created" : "updated"}`}</h2>
                    <p className="text-center text-gray-400 mt-2 text-sm mb-2">
                        Share this Magic Link with the client so that they can set up their ClearERC account.
                    </p>
                    {magicLinkLink && (
                        <div className="mt-8 flex justify-between">
                            <div className="flex-1 h-10 border-opacity-0 dark:border-opacity-0">
                                <ButtonNeoGen
                                    className="w-full h-full border-opacity-0 dark:border-opacity-0"
                                    onClick={onClose}
                                    type="outline"
                                >
                                    Dismiss
                                </ButtonNeoGen>
                            </div>
                            {magicLinkLink && (
                                <div className="flex-1 ml-2 h-10 mt-0">
                                    <ButtonNeoGen
                                        type="primary"
                                        onClick={() => {
                                            navigator.clipboard.writeText(magicLinkLink);
                                            setHasCopied(true);
                                        }}
                                        className="w-full h-full"
                                    >
                                        {hasCopied ? "Copied!" : "Copy Link"}
                                    </ButtonNeoGen>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <Form onSubmit={form.handleSubmit(handleSubmit)}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            flex: 1,
                            justifyContent: "space-between",
                            gap: 20,
                        }}
                    >
                        <div style={{ flex: 1 }}>
                            <TextField label="First Name" {...form.getFieldProps("firstName")} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <TextField label="Last Name" {...form.getFieldProps("lastName")} />
                        </div>
                    </div>
                    <TextField label="Company name" {...form.getFieldProps("companyName")} />
                    <TextField
                        label="Email Address"
                        helperText="Magic Link will be sent to this email address."
                        {...form.getFieldProps("email")}
                    />
                    <TextField
                        label="Phone Number"
                        helperText="Magic Link will be sent to this phone number."
                        {...form.getFieldProps("phone")}
                    />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            flex: 1,
                            justifyContent: "space-between",
                            gap: 20,
                        }}
                    >
                        <div style={{ flex: 1 }}>
                            <TextField label="Estimated W2 Employees 2020" {...form.getFieldProps("w2Employees2020")} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <TextField label="Estimated W2 Employees 2021" {...form.getFieldProps("w2Employees2021")} />
                        </div>
                    </div>
                    <SelectField
                        label="Affiliate"
                        options={sortUsers(affiliates).map((user: any) => ({
                            value: user.id || "no-id",
                            label: `${[user.firstName, user.lastName].join(" ")} (${user.email})`,
                        }))}
                        {...form.getFieldProps("affiliateId")}
                        placeholder="Search from the list of Affiliates..."
                    />
                    <SelectField
                        label="Doc Collector"
                        options={sortUsers(users || []).map((user: any) => ({
                            value: user.id || "no-id",
                            label: `${[user.firstName, user.lastName].join(" ")} (${user.email})`,
                        }))}
                        {...form.getFieldProps("docCollectorId")}
                        placeholder="Search from the list of Doc Collectors..."
                    />
                    <FormModalButtons form={form} onCancel={onClose} okText={okText} isLoading={mutation.isLoading} />
                </Form>
            )}
        </ModalDialog>
    );
};
