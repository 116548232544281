import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { CalendarEvent, calendarEventSchema } from "../domain/calendar-event";

export const createCalendarEvent = async ({ authToken, data }: { authToken: string; data: Partial<CalendarEvent> }) => {
    const response = await makeRequest({
        method: "post",
        path: "/calendar-events",
        authToken,
        data,
    });

    const calendarEvent = parseSchema(calendarEventSchema, response.data);
    console.log("calendarEvent", calendarEvent);
    return calendarEvent;
};
