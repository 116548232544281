import { getProcessflowProgresses } from "../../processflow-progresses/actions/get-processflow-progresses";
import { getDataFromProgressData } from "../../wizard/wizard-container";

export const getEstimatedPayout = async ({ authToken, userId }: { authToken: string; userId: string }) => {
    const group = 7;
    const [usersProgress] = await getProcessflowProgresses({
        authToken,
        filters: { userId, group },
    });
    const data = usersProgress?.data ? getDataFromProgressData({ ...usersProgress?.data, group } as any) : {};
    const estimatedAmount = getEstimatedPayoutFromData({ data });
    return estimatedAmount;
};

export const getW2Employees = ({ data }: { data: Record<string, any> }) => {
    const w2Employees2020 = Number.parseInt(data["Average full time W2 Employees in 2020?"] || "0", 10) || 0;
    const w2Employees2021 = Number.parseInt(data["Average full time W2 Employees in 2021?"] || "0", 10) || 0;
    return { w2Employees2020, w2Employees2021 };
};

export const getEstimatedPayoutFromData = ({ data }: { data: Record<string, any> }) => {
    const w2Employees2020 = Number.parseInt(data["Average full time W2 Employees in 2020?"] || "0", 10) || 0;
    const w2Employees2021 = Number.parseInt(data["Average full time W2 Employees in 2021?"] || "0", 10) || 0;
    const estimatedAmount = w2Employees2020 * 4500 + w2Employees2021 * 11000;
    return estimatedAmount;
};

export const clearErcFeesMultiplier = 0.2;
export const affiliateCommissionMultiplier = 0.15; // 15% of the clearERC fees payable
