import { z } from "zod";

export const calendarEventStatusEnums = {
    upcoming: "upcoming",
    completed: "completed",
    cancelled: "cancelled",
};

export const calendarEventEngagementTypes = {
    inboundCall: "inbound_call",
    outboundCall: "outbound_call",
    inboundApiCall: "inbound_api_call",
    outboundApiCall: "outbound_api_call",
    followUpCall: "follow_up_call",
};

export const calendarEventSchema = z.object({
    id: z.number(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    date: z.coerce.date(),
    title: z.string().nullish(),
    description: z.string().nullish(),
    userId: z.string().uuid().nullish(),
    assigneeId: z.string().uuid().nullish(),
    createdById: z.string().uuid().nullish(),
    engagementType: z.string().nullish(),
    status: z.string().nullish(),
    user: z
        .object({
            firstName: z.string().nullish(),
            lastName: z.string().nullish(),
            name: z.string().nullish(),
        })
        .nullish(),
    assignee: z
        .object({
            firstName: z.string().nullish(),
            lastName: z.string().nullish(),
            name: z.string().nullish(),
        })
        .nullish(),
    createdByUser: z
        .object({
            firstName: z.string().nullish(),
            lastName: z.string().nullish(),
            name: z.string().nullish(),
        })
        .nullish(),
});

export type CalendarEvent = z.infer<typeof calendarEventSchema>;
