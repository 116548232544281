import { useMutation } from "@tanstack/react-query";
import { UpdateCompanyData, updateCompany } from "../actions/update-company";

export const useUpdateCompany = () => {
    const mutation = useMutation({
        mutationFn: async ({ authToken, id, data }: { authToken: string; id: number; data: UpdateCompanyData }) => {
            const company = await updateCompany({ authToken, id, data });
            return company;
        },
    });
    return mutation;
};
