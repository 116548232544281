import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { Company } from "../../domain/company";
import { useMutation } from "@tanstack/react-query";
import { useUpdateCompany } from "../../hooks/use-update-company";
import { NumberField } from "../../../layout/form/number-field";
import {
    getEstimatedPayoutFromData,
    getW2Employees,
} from "../../../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { Currency } from "../../../billing/components";

const schema = z.object({
    finalRefundAmount: z.number(),
});

type Data = z.infer<typeof schema>;

export const EditIrsPayoutModal = ({
    onClose,
    company,
    onCompanyUpdated,
    finalRefundAmount,
    applicationData,
}: {
    onClose: () => any;
    company: Company;
    onCompanyUpdated: (company: Company) => any;
    finalRefundAmount?: number;
    applicationData?: any;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const form = useForm({
        schema,
        defaultValues: { finalRefundAmount },
    });

    const updateCompanyMutation = useUpdateCompany();

    const w2 = getW2Employees({ data: applicationData });
    const estimatedAmount = getEstimatedPayoutFromData({ data: applicationData });

    const submitMutation = useMutation({
        mutationFn: async (data: Data) => {
            const updatedCompany = await updateCompanyMutation.mutateAsync({
                authToken,
                id: company.id,
                data: { finalRefundAmount: data.finalRefundAmount },
            });
            return updatedCompany;
        },
    });

    const handleSubmit = async (data: Data) => {
        const company = await submitMutation.mutateAsync(data);
        onCompanyUpdated(company);
    };

    return (
        <>
            <ModalDialog
                show
                title={"Edit Final Refund Amount"}
                close={onClose}
                showOk={false}
                showCancel={false}
                size="xs"
            >
                <Form onSubmit={form.handleSubmit(handleSubmit as any)} error={updateCompanyMutation.error as any}>
                    <div className="flex flex-col gap-1 mb-4" style={{ fontSize: 14 }}>
                        <div>
                            W2 2020: <b>{w2.w2Employees2020}</b>
                        </div>
                        <div>
                            W2 2021: <b>{w2.w2Employees2021}</b>
                        </div>
                        <div>
                            Estimated deal value:{" "}
                            <b>
                                <Currency amount={estimatedAmount} />
                            </b>
                        </div>
                    </div>
                    <NumberField label="Final Refund Amount" {...form.getFieldProps("finalRefundAmount")} />
                    <div className="flex justify-end gap-4">
                        <ButtonNeoGen
                            type="outline"
                            disabled={updateCompanyMutation.isLoading}
                            onClick={() => onClose()}
                        >
                            Cancel
                        </ButtonNeoGen>
                        <ButtonNeoGen type="submit" disabled={updateCompanyMutation.isLoading}>
                            Update Refund Amount
                        </ButtonNeoGen>
                    </div>
                </Form>
            </ModalDialog>
        </>
    );
};
