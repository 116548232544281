import { useController } from "react-hook-form";
import { GetFieldPropsReturn } from "../../hooks/useForm";
import { Field, FormFieldProps } from "./field";
import { NumberInput, NumberInputProps } from "./number-input";
import { RangeSlider } from "./range-slider";

export const RangeSliderField = ({
    name,
    form,
    isRequired,
    label,
    maxValue,
    disabled,
    ...other
}: FormFieldProps<number> & GetFieldPropsReturn<any> & NumberInputProps & { maxValue?: number }) => {
    const { field, fieldState } = useController({
        name,
        control: form.control,
        rules: { required: isRequired ? "This field is required" : undefined },
        defaultValue: other.defaultValue || null,
    });
    const error = fieldState.error?.message;
    return (
        <Field label={label} isRequired={isRequired} helpText={other.helperText} error={error}>
            <RangeSlider {...other} {...field} hasError={!!error} maxValue={maxValue} disabled={disabled} />
        </Field>
    );
};
