import ModalDialog from "../../layout/modal-dialog";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import { AddCalendarEventSection } from "./add-calendar-event-section";

export const AddCalendarEventModal = ({ onClose, user }: { onClose: () => void; user: ClearERCUser }) => {
    return (
        <ModalDialog
            size="sm"
            show={true}
            close={onClose}
            title={"Add calendar event"}
            showCancel={false}
            showOk={false}
        >
            <AddCalendarEventSection onClose={onClose} user={user} />
        </ModalDialog>
    );
};
