import { z } from "zod";
import { companySchema } from "../../companies/domain/company";

export const transactionSchema = z.object({
    id: z.string().uuid(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    createdById: z.string().uuid(),
    companyId: z.number(),
    paymentId: z.string().uuid().nullish(),
    date: z.coerce.date(),
    amount: z.number(),
    from: z.string().nullish(),
    fromUserId: z.string().uuid().nullish(),
    fromClearErc: z.boolean().nullish(),
    fromCompanyId: z.number().nullish(),
    fromCompany: companySchema.nullish(),
    to: z.string().nullish(),
    toUserId: z.string().uuid().nullish(),
    toClearErc: z.boolean().nullish(),
    toCompanyId: z.number().nullish(),
    toCompany: companySchema.nullish(),
    reference: z.string().nullish(),
    notes: z.string().nullish(),
    description: z.string().nullish(),
});

export type Transaction = z.infer<typeof transactionSchema>;
