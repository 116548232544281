import { z } from "zod";
import { roleGroupSchema } from "../../../role-groups/domain/role-group";

export const userSchema = z.object({
    id: z.string(),
    createdAt: z.coerce.date().nullish(),
    updatedAt: z.coerce.date().nullish(),
    invitedAt: z.coerce.date().nullish(),
    accountSetupAt: z.coerce.date().nullish(),
    company: z.number().nullish(),
    name: z.string().nullish(),
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
    phone: z.string().nullish(),
    email: z.string(),
    emailVerified: z.coerce.boolean().nullish(),
    userStatus: z.enum(["invited", "active"]).nullish(),
    affiliateUserId: z.string().nullish(),
    accountManagerUserId: z.string().nullish(),
    cpaUserId: z.string().nullish(),
    closerId: z.string().nullish(),
    taxAttorneyUserId: z.string().nullish(),
    roleGroups: z.array(roleGroupSchema).nullish(),
    companyRoleGroups: z.array(roleGroupSchema).nullish(),
    lastLoggedIn: z.coerce.date().nullish(),
});

export type User = z.infer<typeof userSchema>;
