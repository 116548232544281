import React from "react";

import { Action, ActionProps } from "../Action";

export function Remove(props: ActionProps) {
    return (
        <Action
            {...props}
            active={{
                fill: "rgba(255, 70, 70, 0.95)",
                background: "rgba(255, 70, 70, 0.1)",
            }}
        >
            <span className="fa fa-eye-slash  my-auto ml-2 mr-2  text-gray-400 hover:text-gray-800" />
        </Action>
    );
}
