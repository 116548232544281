import { z } from "zod";

export const alertRuleSchema = z.object({
    id: z.number().nullish(),
    createdAt: z.coerce.date().nullish(),
    updatedAt: z.coerce.date().nullish(),
    alertTypeId: z.number(),
    title: z.string(),
    body: z.string(),
});

export const alertRuleAndChannelSchema = z.object({
    id: z.number().nullish(),
    createdAt: z.coerce.date().nullish(),
    updatedAt: z.coerce.date().nullish(),
    alertTypeId: z.number(),
    title: z.string(),
    body: z.string(),
    channels: z.array(
        z.object({
            id: z.number().nullish(),
            channel: z.string(),
            channelId: z.string().nullish(),
            recipients: z.string().nullish(),
            link: z.string().nullish(),
        }),
    ),
});

export const alertRuleAndChannelResponseSchema = z.object({
    alertRule: z.object({
        id: z.number().nullish(),
        createdAt: z.coerce.date().nullish(),
        updatedAt: z.coerce.date().nullish(),
        alertTypeId: z.number(),
        title: z.string(),
        body: z.string(),
    }),
    alertRuleChannels: z.array(
        z.object({
            id: z.number(),
            alertRuleId: z.number(),
            channel: z.string(),
            channelId: z.string().nullish(),
            recipients: z.string().nullish(),
            link: z.string().nullish(),
        }),
    ),
});

export type AlertRule = z.infer<typeof alertRuleSchema>;
export type AlertRuleAndChannel = z.infer<typeof alertRuleAndChannelSchema>;
