import { useMutation } from "@tanstack/react-query";
import { SetupCompanyData, setupCompany } from "../actions/setup-company";

export const useSetupCompany = () => {
    const mutation = useMutation({
        mutationFn: async ({ authToken, data }: { authToken: string; data: SetupCompanyData }) => {
            const result = await setupCompany({ authToken, data });
            return result;
        },
    });
    return mutation;
};
