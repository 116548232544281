import { z } from "zod";
import { makeRequest, parseSchema } from "../../../jason-proof-of-concept/shared/utils";
import { auditLogSchema } from "../domain/audit-log";

export const getAuditLogs = async ({ authToken }: { authToken: string }) => {
    const response = await makeRequest({
        method: "get",
        path: `/logs`,
        authToken,
    });

    return response.data;
};
