import { makeRequest, parseSchema } from "../../shared/utils";
import { userSchema } from "../domain/user";
import { userResponseSchema } from "./get-users";

export const replaceGlobalRoles = async ({
    authToken,
    id,
    data,
}: {
    authToken: string;
    id: string;
    data: { roleIds: number[] };
}) => {
    const response = await makeRequest({
        method: "post",
        path: `/users/${id}/replace-global-roles`,
        authToken,
        data: { roleIds: data.roleIds },
    });

    const userDto = parseSchema(userResponseSchema, response.data);

    const user = parseSchema(userSchema, {
        ...userDto,
        company: userDto.company || undefined,
        name: userDto.name || undefined,
    });

    return user;
};
