import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { TextField } from "../../../layout/form/text-field";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { Company, CompanyStatus } from "../../domain/company";
import { SelectField } from "../../../layout/form/select-field";
import { useEffect, useMemo, useState } from "react";
import { User } from "../../../jason-proof-of-concept/users/domain/user";
import { sortUsers } from "../../../sections/utilities/sortUsers";
import { useMutation } from "@tanstack/react-query";
import { useSetupCompany } from "../../hooks/use-setup-company";
import { RoleGroup } from "../../../role-groups/domain/role-group";
import { useUpdateCompany } from "../../hooks/use-update-company";
import { startCase } from "lodash";
import { NumberField } from "../../../layout/form/number-field";
import {
    getEstimatedPayoutFromData,
    getW2Employees,
} from "../../../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { Currency } from "../../../billing/components";

const schema = z.object({
    grossFeePercentage: z.number().optional(),
    grossFeeAmount: z.number().optional(),
    feeType: z.enum(["amount", "percentage"]),
});

type Data = z.infer<typeof schema>;

export const EditGrossFeeModal = ({
    onClose,
    company,
    onCompanyUpdated,
    applicationData,
}: {
    onClose: () => any;
    company: Company;
    onCompanyUpdated: (company: Company) => any;
    applicationData?: any;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const defaultFeeType = company.grossFeeType || "percentage";
    const defaultValues: Data = {
        grossFeePercentage:
            defaultFeeType === "percentage"
                ? company.grossFeePercentage || 20
                : ((company.grossFeeAmount || 0) / (company.finalRefundAmount || 0)) * 100,
        grossFeeAmount:
            defaultFeeType === "amount"
                ? company.grossFeeAmount || 0
                : ((company.grossFeePercentage || 0) * (company.finalRefundAmount || 0)) / 100,
        feeType: defaultFeeType,
    };
    const form = useForm({ schema, defaultValues });
    const updateCompanyMutation = useUpdateCompany();
    const w2 = getW2Employees({ data: applicationData });

    const submitMutation = useMutation({
        mutationFn: async (data: Data) => {
            const updatedCompany = await updateCompanyMutation.mutateAsync({
                authToken,
                id: company.id,
                data: {
                    grossFeeType: data.feeType,
                    ...(data.feeType === "amount"
                        ? {
                              grossFeeAmount: data.grossFeeAmount,
                              grossFeePercentage: null,
                          }
                        : {
                              grossFeeAmount: null,
                              grossFeePercentage: data.grossFeePercentage,
                          }),
                },
            });
            return updatedCompany;
        },
    });

    const handleSubmit = async (data: Data) => {
        const company = await submitMutation.mutateAsync(data);
        onCompanyUpdated(company);
    };

    const irsPayout = company.finalRefundAmount || 0;

    return (
        <>
            <ModalDialog
                show
                title={"Edit ClearERC Gross Fee"}
                close={onClose}
                showOk={false}
                showCancel={false}
                size="sm"
            >
                <Form onSubmit={form.handleSubmit(handleSubmit as any)} error={updateCompanyMutation.error as any}>
                    <div className="flex flex-col gap-1 mb-4" style={{ fontSize: 14 }}>
                        <div>
                            W2 2020: <b>{w2.w2Employees2020}</b>
                        </div>
                        <div>
                            W2 2021: <b>{w2.w2Employees2021}</b>
                        </div>
                        <div>
                            IRS Payout:{" "}
                            <b>
                                <Currency amount={irsPayout} />
                            </b>
                        </div>
                    </div>
                    <SelectField
                        label="Fee type"
                        options={[
                            { label: "Amount", value: "amount" },
                            { label: "Percentage", value: "percentage" },
                        ]}
                        {...form.getFieldProps("feeType")}
                    />
                    <div className="flex flex-row gap-3 items-center">
                        <div className="flex-1">
                            <NumberField
                                label="Gross fee %"
                                {...form.getFieldProps("grossFeePercentage")}
                                onChange={(e) => {
                                    form.setValue("feeType", "percentage");
                                    form.setValue(
                                        "grossFeeAmount",
                                        Number.parseFloat(
                                            (irsPayout * (Number.parseFloat(e.target.value || "0") / 100)).toFixed(2),
                                        ),
                                    );
                                }}
                            />
                        </div>
                        <div className="flex-1">
                            <NumberField
                                label="Gross fee amount"
                                {...form.getFieldProps("grossFeeAmount")}
                                onChange={(e) => {
                                    form.setValue("feeType", "amount");
                                    form.setValue(
                                        "grossFeePercentage",
                                        Number.parseFloat(
                                            ((Number.parseFloat(e.target.value || "0") * 100) / irsPayout).toFixed(2),
                                        ),
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex justify-end gap-4">
                        <ButtonNeoGen
                            type="outline"
                            disabled={updateCompanyMutation.isLoading}
                            onClick={() => onClose()}
                        >
                            Cancel
                        </ButtonNeoGen>
                        <ButtonNeoGen type="submit" disabled={updateCompanyMutation.isLoading}>
                            Update gross fee
                        </ButtonNeoGen>
                    </div>
                </Form>
            </ModalDialog>
        </>
    );
};
