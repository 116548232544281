import { useState } from "react";
import PageDescription from "../../layout/page-description";
import SearchField from "../../layout/search-field";
import TableNeogen from "../../layout/table-neogen";
import { formatRelative, isAfter, isPast } from "date-fns";
import { search as ssSearch } from "ss-search";
import { useCalls } from "../hooks/use-calls";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { Call } from "../domain/call";
import { useCalendarEvents } from "../../calendar-events/hooks/use-calendar-events";
import { CalendarEvent } from "../../calendar-events/domain/calendar-event";
import { EditCalendarEventModal } from "../../calendar-events/components/edit-calendar-event-modal";
import { AddCalendarEventModal } from "../../calendar-events/components/add-calendar-event-modal";
import { isFuture } from "date-fns";

export const MyCallsPage = () => {
    const [search, setSearch] = useState("");
    const now = new Date();
    const authToken = getAuthTokenNoThrow() || "no-auth";
    const [calendarEventToEdit, setCalendarEventToEdit] = useState<CalendarEvent | undefined>();
    const [showAddCalendarEventModal, setShowAddCalendarEventModal] = useState(false);
    const calendarEventsQuery = useCalendarEvents({ authToken });
    const calendarEvents = calendarEventsQuery.data || [];

    const callsQuery = useCalls({ authToken });
    const calls = callsQuery.data || [];
    const filteredFutureCalls = (
        search
            ? ssSearch(
                  calls.filter((c) => isFuture(c.date)),
                  ["notes"],
                  search,
              )
            : calendarEvents.filter((c) => isFuture(c.date))
    ) as CalendarEvent[];
    const filteredPastCalls = (
        search
            ? ssSearch(
                  calls.filter((c) => isPast(c.date)),
                  ["notes"],
                  search,
              )
            : calls.filter((c) => isPast(c.date))
    ) as Call[];

    return (
        <>
            {calendarEventToEdit && (
                <EditCalendarEventModal
                    calendarEvent={calendarEventToEdit}
                    onClose={() => setCalendarEventToEdit(undefined)}
                    onUpdate={() => {
                        calendarEventsQuery.refetch();
                    }}
                />
            )}
            <div>
                <PageDescription
                    title="Calls"
                    description="See your call history and upcoming calls"
                    buttons={[
                        {
                            label: "Add calendar event",
                            icon: "fas fa-calendar",
                            onClick: () => {
                                setShowAddCalendarEventModal(true);
                            },
                        },
                    ]}
                >
                    <SearchField
                        search={search}
                        setSearch={setSearch}
                        placeholder="Search calls or calendar events..."
                    />
                </PageDescription>
                <div className="flex flex-col gap-2">
                    <div>
                        <h2 className="mb-2">Upcoming call events</h2>
                        {filteredFutureCalls.length === 0 ? (
                            <div className="text-gray-600 m-4 text-center">No upcoming call events</div>
                        ) : (
                            <TableNeogen
                                hideMargin
                                entries={filteredFutureCalls.map((event) => ({
                                    id: event.id,
                                    date: formatRelative(new Date(event.date), now),
                                    user: `${event.user?.firstName} ${event.user?.lastName} ${
                                        event.user?.name ? `(${event.user?.name})` : ""
                                    }`,
                                    assignee: `${event.assignee?.firstName} ${event.assignee?.lastName} ${
                                        event.assignee?.name ? `(${event.assignee?.name})` : ""
                                    }`,
                                    title: event.title,
                                    description: event.description,
                                    engagementType:
                                        (event.engagementType || "").slice(0, 1).toUpperCase() +
                                        (event.engagementType || "").slice(1).replaceAll("_", " "),
                                }))}
                                actionsAreDropDown
                                actions={[
                                    {
                                        label: "Call now",
                                        icon: "fa fa-phone",
                                        onClick: () => {
                                            console.log("Making call..");
                                        },
                                    },
                                    {
                                        label: "Edit",
                                        icon: "fa fa-pencil-square",
                                        onClick: (calendarEventId: number) => {
                                            const calendarEvent = calendarEvents.find((c) => c.id === calendarEventId);
                                            console.log({ calendarEventId, calendarEvent });
                                            if (calendarEvent) {
                                                setCalendarEventToEdit(calendarEvent);
                                            }
                                        },
                                    },
                                ]}
                            />
                        )}
                    </div>
                    <div>
                        <h2>Past calls</h2>
                        {filteredPastCalls.length === 0 ? (
                            <div className="text-gray-600 m-4 text-center">No past calls</div>
                        ) : (
                            <TableNeogen
                                hideMargin
                                entries={filteredPastCalls.map((call) => ({
                                    date: formatRelative(new Date(call.date), now),
                                    duration: "1:34",
                                    caller: call.dialerNumber,
                                    callee: call.receiverNumber,
                                    notes: call.notes,
                                }))}
                                actionsAreDropDown
                                actions={[
                                    {
                                        label: "Call now",
                                        icon: "fa fa-phone",
                                        onClick: () => {
                                            console.log("Call again");
                                        },
                                    },
                                    {
                                        label: "Edit",
                                        icon: "fa fa-pencil-square",
                                        onClick: () => {
                                            console.log("Call again");
                                        },
                                    },
                                    {
                                        label: "Schedule new call",
                                        icon: "fa fa-calendar-plus",
                                        onClick: () => {
                                            console.log("Call again");
                                        },
                                    },
                                ]}
                            />
                        )}
                    </div>
                </div>
            </div>
            {showAddCalendarEventModal && (
                // <CustomerCallModal user={user} onClose={() => setShowCallingModal(false)} />
                // @ts-ignore
                <AddCalendarEventModal
                    onClose={() => {
                        calendarEventsQuery.refetch();
                        setShowAddCalendarEventModal(false);
                    }}
                />
            )}
        </>
    );
};
