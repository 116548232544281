import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";

export const alertUserSettingsSchema = z.object({
    id: z.number().nullish(),
    createdAt: z.coerce.date().nullish(),
    updatedAt: z.coerce.date().nullish(),
    userId: z.string().nullish(),
    alertRuleId: z.number().nullish(),
    appEnabled: z.boolean().nullish(),
    emailEnabled: z.boolean().nullish(),
});

export const getAlertUserSettings = async ({ authToken, filter }: { authToken?: string; filter?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: "/alert-user-settings",
        queryParams: { filter: JSON.stringify(filter) },
        authToken,
    });

    if (!response.data) return null;
    const appAlertSettings = parseSchema(z.array(alertUserSettingsSchema), response.data);

    return appAlertSettings;
};
