import { z } from "zod";
import { alertRuleAndChannelResponseSchema, alertRuleAndChannelSchema, alertRuleSchema } from "../domain/alert-rule";
import { makeRequest, parseSchema } from "../../../jason-proof-of-concept/shared/utils";

export type UpdateAlertRuleAndChannelData = z.infer<typeof alertRuleAndChannelSchema>;

export const updateAlertRule = async ({
    authToken,
    data,
    id,
}: {
    authToken: string;
    data: UpdateAlertRuleAndChannelData;
    id: number;
}) => {
    const response = await makeRequest({
        method: "patch",
        path: `/alert-rules/${id}/setup`,
        authToken,
        data,
    });

    const alertRuleAndChannels = parseSchema(alertRuleAndChannelResponseSchema, response.data);
    return alertRuleAndChannels;
};
