import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { Interaction, interactionSchema } from "../domain/interaction";

export type CreateInteractionData = Partial<Interaction>;

export const createInteraction = async ({ authToken, data }: { authToken: string; data: CreateInteractionData }) => {
    const response = await makeRequest({
        method: "post",
        path: `/interactions`,
        authToken,
        data,
    });

    const interaction = parseSchema(interactionSchema, response.data);

    return interaction;
};
