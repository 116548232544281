import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { transactionSchema } from "../domain/transaction";

export const getTransactions = async ({ authToken, filters }: { authToken: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: "/transactions",
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });

    const transactions = parseSchema(z.array(transactionSchema), response.data);

    return transactions;
};
