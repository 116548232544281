import { z } from "zod";

export enum ChannelEnum {
    slack = "slack",
    email = "email",
    app = "app",
}

export const alertRuleChannelSchema = z.object({
    id: z.number().nullish(),
    createdAt: z.coerce.date().nullish(),
    updatedAt: z.coerce.date().nullish(),
    alertRuleId: z.number(),
    channel: z.enum(["slack", "email", "app"]),
    channelId: z.string().nullish(),
    link: z.string().nullish(),
    recipients: z.string().nullish(),
});

export type AlertRuleChannel = z.infer<typeof alertRuleChannelSchema>;
