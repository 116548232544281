import { useController } from "react-hook-form";
import { GetFieldPropsReturn } from "../../hooks/useForm";
import { Field, FormFieldProps } from "./field";
import { Option, Select } from "./select-input";

type Other = { options: Option[] };
type Disabled = { disabled?: boolean };

export const SelectField = ({
    name,
    form,
    isRequired,
    label,
    disabled,
    isMultiple,
    ...other
}: FormFieldProps<string> & GetFieldPropsReturn<any> & Other & Disabled & { isMultiple?: boolean }) => {
    const { field, fieldState } = useController({
        name,
        control: form.control,
        rules: { required: isRequired ? "This field is required" : undefined },
        defaultValue: other.defaultValue || null,
    });
    const error = fieldState.error?.message;
    return (
        <Field label={label} isRequired={isRequired} helpText={other.helperText} error={error}>
            <Select isMultiple={isMultiple} {...field} options={other.options} disabled={disabled} />
        </Field>
    );
};
