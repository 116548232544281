import { z } from "zod";
import { makeRequest, parseSchema } from "../../../jason-proof-of-concept/shared/utils";
import { alertRuleSchema } from "../domain/alert-rule";

export const getAlertRules = async ({ authToken, filters }: { authToken: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: "/alert-rules",
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });

    const alertRules = parseSchema(z.array(alertRuleSchema), response.data);

    return alertRules;
};
