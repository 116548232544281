import { z } from "zod";
import { useState } from "react";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { RangeSliderField } from "../../../layout/form/range-slider-field";
import ModalDialog from "../../../layout/modal-dialog";
import { NumberField } from "../../../layout/form/number-field";

const schema = z.object({
    commission: z.number(),
});

interface CommissionModalProps {
    onClose: () => void;
    onSubmit: (commission: number) => void;
    totalCommissionPercentage?: number;
}

const HUNDRED_PERCENT = 100;

export const CommissionModal = ({ onClose, onSubmit, totalCommissionPercentage }: CommissionModalProps) => {
    const form = useForm({
        schema,
        defaultValues: { commission: 0 },
    });

    const maximumCommissionAllocatable = totalCommissionPercentage
        ? HUNDRED_PERCENT - totalCommissionPercentage
        : HUNDRED_PERCENT;

    const currentCommissionValue = form.watch("commission");

    const isHigherThanMaximum = currentCommissionValue > maximumCommissionAllocatable;

    const isInvalidCommissionValue = currentCommissionValue < 0;

    const totalCommissionExceedsMaximum = totalCommissionPercentage
        ? totalCommissionPercentage >= HUNDRED_PERCENT
        : false;

    const isDisabled = isHigherThanMaximum || totalCommissionExceedsMaximum;

    const handleSubmit = async ({ commission }: { commission: number }) => {
        if (commission === null || commission < 0) return onClose();
        onSubmit(commission);
        onClose();
    };

    const getHelperText = () => {
        if (isInvalidCommissionValue)
            return "Commission cannot be less than 0. Please enter a valid commission percentage.";

        if (isHigherThanMaximum)
            return `Allocating ${currentCommissionValue}% commission will exceed the maximum commission percentage of 100%. Please reduce the commission percentage to ${maximumCommissionAllocatable}% or less.`;

        if (totalCommissionExceedsMaximum)
            return "The total allocated commission is 100%. Please reduce the commission percentage for other members in order to allocate commission to this member.";
    };

    const helperText = getHelperText();

    return (
        <ModalDialog
            show
            title="Commission"
            close={onClose}
            okText="Add commission"
            showOk
            showCancel
            size="sm"
            isSaveDialog
            disabled={isDisabled}
            okAction={() => {
                const { commission } = form.getValues();
                // Number because getValues returns string
                handleSubmit({ commission: Number(commission) });
            }}
        >
            <Form onSubmit={form.handleSubmit(handleSubmit)}>
                <RangeSliderField
                    {...form.getFieldProps("commission")}
                    label="Commission"
                    maxValue={maximumCommissionAllocatable || HUNDRED_PERCENT}
                    disabled={totalCommissionExceedsMaximum}
                />
                {!totalCommissionExceedsMaximum && (
                    <div className="w-1/6 flex flex-end justify-end items-baseline ml-auto -mt-2">
                        <NumberField
                            {...form.getFieldProps("commission")}
                            disabled={totalCommissionExceedsMaximum}
                            defaultValue={0}
                        />
                        <span className="ml-2">%</span>
                    </div>
                )}
                {helperText && (
                    <p id="commission-error-text" className="text-red-500 mb-2 text-sm font-light">
                        {helperText}
                    </p>
                )}
            </Form>
        </ModalDialog>
    );
};
