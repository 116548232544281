import { useState, useMemo } from "react";
import TWSelect from "react-tailwindcss-select";
import ModalDialog from "../../../layout/modal-dialog";
import { TextField } from "../../../layout/form/text-field";
import { useForm } from "../../../hooks/useForm";
import { AlertType } from "../domain/alert-type";
import { AlertRuleAndChannel, alertRuleAndChannelSchema } from "../domain/alert-rule";
import { Option, SelectValue } from "react-tailwindcss-select/dist/components/type";
import { useMutation } from "@tanstack/react-query";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import { SelectField } from "../../../layout/form/select-field";
import { CreateAlertRuleAndChannelData } from "../actions/create-alert-rule";
import { Form } from "../../../layout/form/form";
import ButtonNeoGen from "../../../layout/button-neogen";
import { TextAreaField } from "../../../layout/form/text-area-field";
import { Select } from "../../../layout/form/select-input";
import { useFieldArray } from "react-hook-form";
import { ChannelEnum } from "../domain/alert-rule-channel";
import CheckBoxNeoGenControlled from "../../../layout/checkbox-controlled";
import { updateAlertRule } from "../actions/update-alert-rule";
import { RoleGroup } from "../../../typings/api/role-group";

const schema = alertRuleAndChannelSchema;

export const UpdateAlertRuleModal = ({
    onClose,
    alertTypes,
    defaultValues,
    users,
    roleGroups,
}: {
    onClose: () => void;
    alertTypes: AlertType[] | undefined;
    defaultValues: AlertRuleAndChannel;
    users: ClearERCUser[];
    roleGroups: RoleGroup[];
}) => {
    const authToken = getAuthTokenNoThrow() || "no-token";

    const form = useForm({ schema, defaultValues });
    const [selectedRoleGroups, setSelectedRoleGroups] = useState<Record<number, SelectValue>>(
        defaultValues.channels.reduce((acc, channel, index) => {
            if (!channel.recipients) return acc;
            const recipients = JSON.parse(channel.recipients);
            const roleGroupIds = (recipients?.roleGroupIds || []).map((rgId: string) => Number.parseInt(rgId));
            acc[index] = roleGroupIds?.map((rg: number) => ({
                label: `${roleGroups.find((r) => r.id === rg)?.name}`,
                value: rg,
            }));
            return acc;
        }, {} as { [index: number]: SelectValue }),
    );
    const [selectedUsers, setSelectedUsers] = useState<Record<number, SelectValue>>(
        defaultValues.channels.reduce((acc, channel, index) => {
            if (!channel.recipients) return acc;
            const recipients = JSON.parse(channel.recipients);
            const userIds = recipients.userIds;
            acc[index] = userIds?.map((u: string) => ({
                label: `${users.find((user) => user.id === u)?.firstName} ${
                    users.find((user) => user.id === u)?.lastName
                }`,
                value: u,
            }));
            return acc;
        }, {} as { [index: number]: SelectValue }),
    );
    const [sendSelectedUsersEmail, setSendSelectedUsersEmail] = useState<Record<number, boolean>>(
        defaultValues.channels.reduce((acc, channel, index) => {
            if (!channel.recipients) return acc;
            const recipients = JSON.parse(channel.recipients);
            acc[index] = recipients.sendSelectedUsersEmail;
            return acc;
        }, {} as { [index: number]: boolean }),
    );
    const [sendSelectedRoleGroupsEmail, setSendSelectedRoleGroupsEmail] = useState<Record<number, boolean>>(
        defaultValues.channels.reduce((acc, channel, index) => {
            if (!channel.recipients) return acc;
            const recipients = JSON.parse(channel.recipients);
            acc[index] = recipients.sendSelectedRoleGroupsEmail;
            return acc;
        }, {} as { [index: number]: boolean }),
    );

    const values = form.watch();

    const alertsMutation = useMutation({
        mutationFn: async ({ authToken, data }: { authToken: string; data: CreateAlertRuleAndChannelData }) => {
            if (!defaultValues.id) {
                throw new Error("Alert rule must have an id");
                return;
            }
            return await updateAlertRule({ authToken, data, id: defaultValues.id });
        },
    });

    const handleSubmit = async (data: CreateAlertRuleAndChannelData) => {
        const alertRule = await alertsMutation.mutateAsync({
            authToken,
            data: {
                ...data,
                channels: data.channels.map((channel, index) => ({
                    ...channel,
                    recipients:
                        selectedUsers[index] === null && selectedRoleGroups[index] === null
                            ? undefined
                            : JSON.stringify({
                                  userIds:
                                      selectedUsers[index] === null || selectedUsers[index] === undefined
                                          ? undefined
                                          : (selectedUsers[index] as Option[]).map((user) => user.value),
                                  sendSelectedUsersEmail:
                                      sendSelectedUsersEmail[index] === null ||
                                      sendSelectedUsersEmail[index] === undefined
                                          ? undefined
                                          : !!sendSelectedUsersEmail[index],
                                  roleGroupIds:
                                      selectedRoleGroups[index] === null || selectedRoleGroups[index] === undefined
                                          ? undefined
                                          : (selectedRoleGroups[index] as Option[]).map((rg) => rg.value),
                                  sendSelectedRoleGroupsEmail:
                                      sendSelectedRoleGroupsEmail[index] === null ||
                                      sendSelectedRoleGroupsEmail[index] === undefined
                                          ? undefined
                                          : !!sendSelectedRoleGroupsEmail[index],
                              }),
                })),
            },
        });

        onClose();

        return alertRule;
    };

    const interceptorDetails = useMemo(() => {
        if (values.alertTypeId === null || values.alertTypeId === undefined) return [];

        const json = alertTypes?.find((a) => a.id === values.alertTypeId)?.interceptorDetails;

        if (!json) return [];

        const data = JSON.parse(json);
        const flatList = [...(data.body || []), ...(data?.interceptorExtraDetails || [])];

        return flatList;
    }, [values.alertTypeId, alertTypes]);

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: "channels",
    });

    return (
        <ModalDialog title="Edit Alert" close={onClose} size="md" show={true} showOk={false} showCancel={false}>
            <Form onSubmit={form.handleSubmit(handleSubmit)} error={alertsMutation.error as any}>
                <SelectField
                    {...form.getFieldProps("alertTypeId")}
                    isRequired
                    label="Select alert type"
                    options={(alertTypes || []).map((a) => ({
                        label: a.alertType.replaceAll("-", " "),
                        value: a.id,
                    }))}
                    disabled={true}
                    helperText="Cannot edit alert type"
                />
                <br />
                <TextField label="Title" {...form.getFieldProps("title")} isRequired />
                <div className="border-[1px] rounded-md border-gray-300 p-3">
                    <TextAreaField
                        label="Body"
                        {...form.getFieldProps("body")}
                        helperText="Add templated values for dynamic data. If a templated value is missing please ask someone in the developer team for help."
                        isRequired
                    />

                    <Select
                        label="Add templated value"
                        options={interceptorDetails.map((d) => ({ value: d, label: d }))}
                        placeholder="Select templated string"
                        onChange={(v) => form.setValue("body", values.body + "${" + v + "} ")}
                        value={null}
                    />
                </div>
                <br />
                <div className="text-center">
                    <ButtonNeoGen text="Add channel" onClick={() => append({ channel: "email" })} />
                </div>
                <ul>
                    {fields.map((item, index) => (
                        <li key={item.id} className="border-[1px] rounded-md border-gray-300 p-3 my-4">
                            <div className="flex justify-between">
                                <p className="my-2">{index + 1}. Channel entry</p>
                                <ButtonNeoGen type="outline" onClick={() => remove(index)}>
                                    Delete
                                </ButtonNeoGen>
                            </div>
                            <SelectField
                                {...form.register(`channels.${index}.channel`)}
                                {...form.getFieldProps(`channels.${index}.channel`)}
                                isRequired
                                label="Select channel"
                                options={[
                                    { value: ChannelEnum.app, label: "App" },
                                    { value: ChannelEnum.slack, label: "Slack" },
                                    { value: ChannelEnum.email, label: "Email" },
                                ]}
                            />
                            {values.channels[index].channel === ChannelEnum.slack && (
                                <>
                                    <TextField
                                        label="Slack URL"
                                        {...form.getFieldProps(`channels.${index}.channelId`)}
                                        isRequired={values.channels[index].channel === ChannelEnum.slack}
                                    />
                                </>
                            )}
                            {values.channels[index].channel === ChannelEnum.email && (
                                <>
                                    <TextField
                                        label="Email address"
                                        helperText="This user will receive an email when the alert is triggered"
                                        {...form.getFieldProps(`channels.${index}.channelId`)}
                                        isRequired={values.channels[index].channel === ChannelEnum.email}
                                    />
                                </>
                            )}

                            {values.channels[index].channel === ChannelEnum.app && (
                                <>
                                    <p className="mb-2 text-center">Where should the app alert navigate to?</p>
                                    <div className="border-[1px] rounded-md border-gray-300 p-3 mb-2">
                                        <TextField
                                            label="Link"
                                            {...form.getFieldProps(`channels.${index}.link`)}
                                            helperText="eg. /users/${id}"
                                        />

                                        <Select
                                            label="Add templated value"
                                            options={interceptorDetails.map((d) => ({ value: d, label: d }))}
                                            placeholder="Select templated string"
                                            onChange={(v) =>
                                                form.setValue(
                                                    `channels.${index}.link`,
                                                    values.channels[index].link + "${" + v + "}",
                                                )
                                            }
                                            value={null}
                                        />
                                    </div>

                                    <p className="mb-2 text-center">Who should receive the alert?</p>
                                    <p className="mb-1 inline-block relative text-sm font-normal tracking-wider leading-normal text-gray-400 dark:text-gray-400">
                                        Select users
                                    </p>
                                    <TWSelect
                                        isSearchable={true}
                                        isMultiple={true}
                                        value={selectedUsers[index]}
                                        onChange={(v) => setSelectedUsers({ ...selectedUsers, [index]: v })}
                                        options={
                                            (users || []).map((u) => ({
                                                label: u.firstName + " " + u.lastName + " (" + u.email + ")",
                                                value: u.id,
                                            })) as Option[]
                                        }
                                        primaryColor="#6610f2"
                                    />
                                    <CheckBoxNeoGenControlled
                                        className="my-2"
                                        label="Send email to selected users when alert is triggered"
                                        value={sendSelectedUsersEmail[index]}
                                        name="sendSelectedUsersEmail"
                                        setValue={(v) =>
                                            setSendSelectedUsersEmail({
                                                ...sendSelectedUsersEmail,
                                                [index]: v.target.checked,
                                            })
                                        }
                                    />

                                    <p className="my-2 text-center">and / or</p>
                                    <p className="mb-1 inline-block relative text-sm font-normal tracking-wider leading-normal text-gray-400 dark:text-gray-400">
                                        Select role groups
                                    </p>
                                    <TWSelect
                                        isSearchable={true}
                                        isMultiple={true}
                                        value={selectedRoleGroups[index]}
                                        onChange={(v) => setSelectedRoleGroups({ ...selectedRoleGroups, [index]: v })}
                                        options={
                                            (roleGroups || []).map((rg) => ({
                                                label: rg.name || "Unknown",
                                                value: rg.id?.toString(),
                                            })) as Option[]
                                        }
                                        primaryColor="#6610f2"
                                    />
                                    <p className="text-gray-500 text-xs font-extralight">
                                        It is required that the ID of the role group exists as a templated value in
                                        order to be selected. Eg. if you want affiliates to be alerted then
                                        &quot;affiliateId&quot; must exist as a templated value. This rule does not
                                        apply to superusers. Please contract the developer team if you need templated
                                        values added.
                                    </p>
                                    <CheckBoxNeoGenControlled
                                        className="my-2"
                                        label="Send email to selected role groups when alert is triggered"
                                        value={sendSelectedRoleGroupsEmail[index]}
                                        name="sendSelectedRoleGroupsEmail"
                                        setValue={(v) =>
                                            setSendSelectedRoleGroupsEmail({
                                                ...sendSelectedRoleGroupsEmail,
                                                [index]: v.target.checked,
                                            })
                                        }
                                    />
                                    <br />
                                </>
                            )}
                        </li>
                    ))}
                </ul>

                <div className="flex justify-end gap-4">
                    <ButtonNeoGen type="outline" disabled={alertsMutation.isLoading} onClick={() => onClose()}>
                        Cancel
                    </ButtonNeoGen>
                    <ButtonNeoGen type="submit" disabled={alertsMutation.isLoading}>
                        Update alert
                    </ButtonNeoGen>
                </div>
            </Form>
        </ModalDialog>
    );
};
