import { useController } from "react-hook-form";
import { GetFieldPropsReturn } from "../../hooks/useForm";
import { Field, FormFieldProps } from "./field";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const DateTimeField = ({
    name,
    form,
    isRequired,
    label,
    ...other
}: FormFieldProps<Date> & GetFieldPropsReturn<any> & Omit<ReactDatePickerProps, "onChange">) => {
    const { field, fieldState } = useController({
        name,
        control: form.control,
        rules: { required: isRequired ? "This field is required" : undefined },
        defaultValue: other.defaultValue || null,
    });
    const error = fieldState.error?.message;
    return (
        <Field label={label} isRequired={isRequired} helpText={other.helperText} error={error}>
            <ReactDatePicker
                showTimeSelect
                showTimeInput
                {...field}
                selected={field.value}
                className="rounded-md border-gray-300 placeholder:text-sm px-2 py-1.5 cursor-pointer flex w-[100%]"
                {...other}
            />
        </Field>
    );
};
