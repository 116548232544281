import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { Company } from "../../domain/company";
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useUpdateCompany } from "../../hooks/use-update-company";
import { NumberField } from "../../../layout/form/number-field";
import {
    affiliateCommissionMultiplier,
    getW2Employees,
} from "../../../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { Currency } from "../../../billing/components";

const schema = z.object({
    feePercentage: z.number().optional(),
});

type Data = z.infer<typeof schema>;

export const EditAffiliateFeeModal = ({
    onClose,
    company,
    onCompanyUpdated,
    irsPayout,
    clearErcGrossFee,
    applicationData,
}: {
    onClose: () => any;
    company: Company;
    onCompanyUpdated: (company: Company) => any;
    irsPayout: number;
    clearErcGrossFee: number;
    applicationData?: any;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const defaultValues: Data = {
        feePercentage: company.affiliateFeePercentage || 0,
    };
    const form = useForm({ schema, defaultValues });
    const updateCompanyMutation = useUpdateCompany();
    const w2 = getW2Employees({ data: applicationData });
    const affiliateFeePercentage = form.watch("feePercentage") || 0;

    const submitMutation = useMutation({
        mutationFn: async (data: Data) => {
            const updatedCompany = await updateCompanyMutation.mutateAsync({
                authToken,
                id: company.id,
                data: {
                    affiliateFeePercentage: data.feePercentage,
                },
            });
            return updatedCompany;
        },
    });

    const handleSubmit = async (data: Data) => {
        const company = await submitMutation.mutateAsync(data);
        onCompanyUpdated(company);
    };

    return (
        <>
            <ModalDialog show title={"Edit Affiliate Fee"} close={onClose} showOk={false} showCancel={false} size="sm">
                <Form onSubmit={form.handleSubmit(handleSubmit as any)} error={updateCompanyMutation.error as any}>
                    <div className="flex flex-col gap-1 mb-4" style={{ fontSize: 14 }}>
                        <div>
                            W2 2020: <b>{w2.w2Employees2020}</b>
                        </div>
                        <div>
                            W2 2021: <b>{w2.w2Employees2021}</b>
                        </div>
                        <div>
                            IRS Payout:{" "}
                            <b>
                                <Currency amount={irsPayout} />
                            </b>
                        </div>
                        <div>
                            ClearERC gross fee:{" "}
                            <b>
                                <Currency amount={clearErcGrossFee} />
                            </b>
                        </div>
                    </div>
                    <div className="flex flex-row gap-4 items-center">
                        <div className="flex-1">
                            <NumberField label="Affiliate fee %" {...form.getFieldProps("feePercentage")} />
                        </div>
                        <div className="flex-1">
                            Fee amount: <Currency amount={(affiliateFeePercentage / 100) * clearErcGrossFee} />
                        </div>
                    </div>
                    <div className="flex justify-end gap-4">
                        <ButtonNeoGen
                            type="outline"
                            disabled={updateCompanyMutation.isLoading}
                            onClick={() => onClose()}
                        >
                            Cancel
                        </ButtonNeoGen>
                        <ButtonNeoGen type="submit" disabled={updateCompanyMutation.isLoading}>
                            Update affiliate fee
                        </ButtonNeoGen>
                    </div>
                </Form>
            </ModalDialog>
        </>
    );
};
