import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { parseRoleGroup, roleGroupDtoSchema, roleGroupSchema } from "../domain/role-group";

export const getRoleGroups = async ({ authToken, filters }: { authToken: string; filters: any }) => {
    const response = await makeRequest({
        method: "get",
        path: `/role-groups`,
        authToken,
    });

    const roleGroupDtos = parseSchema(z.array(roleGroupDtoSchema), response.data);

    const roleGroups = parseSchema(
        z.array(roleGroupSchema),
        roleGroupDtos.map((dto) => parseRoleGroup(dto)),
    );
    return roleGroups;
};
