import { makeRequest } from "../../../jason-proof-of-concept/shared/utils";

export const deleteAlertRule = async ({ authToken, id }: { authToken: string; id: number }) => {
    const response = await makeRequest({
        method: "delete",
        path: `/alert-rules/${id}/setup`,
        authToken,
    });

    return response.data;
};
