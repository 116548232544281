import { z } from "zod";
import { userSchema } from "../../jason-proof-of-concept/users/domain/user";

export const interactionSchema = z.object({
    id: z.number(),
    dateCreated: z.coerce.date().nullish(),
    summary: z.string().nullish(),
    activity: z.string().nullish(),
    interaction_from: z.string().uuid().nullish(),
    interactionFromUser: userSchema.nullish(),
    interaction_to: z.string().uuid().nullish(),
    interactionToUser: userSchema.nullish(),
    type: z.number().nullish(),
    isInternal: z.boolean().nullish(),
    companyId: z.number().nullish(),
    followupDatetime: z.coerce.date().nullish(),
});

export type Interaction = z.infer<typeof interactionSchema>;
