import { createContext } from "react";

const WizardContext = createContext<{
    companyId?: number;
    processflowProgressId?: number;
}>({
    companyId: undefined,
    processflowProgressId: undefined,
});

export default WizardContext;
