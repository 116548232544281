import { UniqueIdentifier } from "@dnd-kit/core";
import React, { useEffect } from "react";
import PrintPre from "../layout/print-pre";

import { MultipleContainers } from "./MultipleContainers";

export default {
    title: "Presets/Sortable/Multiple Containers",
};

export const BasicSetup = () => <MultipleContainers />;

async function fetchData(url: RequestInfo | URL) {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        return response.json();
    } catch (error) {
        console.error("Unable to fetch data:", error);
    }
}

function fetchNames(nameType: string) {
    return fetchData(`https://www.randomlists.com/data/names-${nameType}.json`);
}

function pickRandom(list: string | any[]) {
    return list[Math.floor(Math.random() * list.length)];
}

export type Items = Record<UniqueIdentifier, UniqueIdentifier[]>;

async function generateName(gender: any): Promise<UniqueIdentifier> {
    try {
        // Fetch both name lists in parallel
        const response = await Promise.all([fetchNames(gender), fetchNames("surnames")]);

        // Promise.all returns an array of responses
        // to our two requests, so select them
        const [firstNames, lastNames] = response;

        // Pick a random name from each list
        const firstName = pickRandom(firstNames.data);
        const lastName = pickRandom(lastNames.data);

        // Use a template literal to format the full name
        return `${firstName} ${lastName}`;
    } catch (error) {
        console.error("Unable to generate name:", error);
        return "bla";
    }
}

export const DragHandle = () => {
    const [items, setItems] = React.useState<Items>({
        "New Customers": ["x", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
        "Started Form Filling": ["11", "12", "13", "14", "15", "16", "17", "18", "19", "20"],
        "With CPA": ["21", "22", "23", "24", "25", "26", "27", "28", "29", "30"],
        "With IRS": ["31", "32", "33", "34", "35", "36", "37", "38", "39", "40"],
        Approved: ["41", "42", "43", "44", "45", "46", "47", "48", "49", "50"],
        Rejected: ["51", "52", "53", "54", "55", "56", "57", "58", "59", "60"],
    });
    useEffect(() => {
        async function genItems() {
            const items: Items = {
                "New Customers2": ["xx", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
                "Started Form Filling": ["11", "12", "13", "14", "15", "16", "17", "18", "19", "20"],
                "With CPA": ["21", "22", "23", "24", "25", "26", "27", "28", "29", "30"],
                "With IRS": ["31", "32", "33", "x", "35", "36", "37", "38", "39", "40"],
                Approved: ["41", "42", "43", "44", "45", "46", "47", "48", "49", "50"],
                Rejected: ["51", "52", "53", "54", "55", "56", "57", "58", "59", "60"],
            };
            // alert(JSON.stringify(items));
            setItems(items);
        }
        genItems();
    }, []);

    return (
        <div className="overflow-auto">
            <MultipleContainers handle items={items} />
            <PrintPre>{items}</PrintPre>
        </div>
    );
};

export const ManyItems = () => (
    <MultipleContainers
        containerStyle={{
            maxHeight: "80vh",
        }}
        itemCount={15}
        scrollable
    />
);
