import TableNeogen from "../../../layout/table-neogen";
import { AuditLogs } from "../domain/audit-log";

export const AuditLogsTable = ({ auditLogsData }: { auditLogsData: AuditLogs[] | undefined }) => {
    return (
        <div className="mt-5">
            <TableNeogen
                entries={(auditLogsData || []).map((data) => ({
                    date: data.eventTimestamp,
                    url: data.url,
                    entity: data.entity,
                    entityId: data.entityId,
                    action: data.action,
                    body: data.body,
                    extraDetails: JSON.stringify(data.interceptorExtraDetails),
                    executionTime: `${Math.round(data.executionTime || 0)}ms`,
                }))}
                headers={["Date", "Url", "Entity", "Entity ID", "Action", "Body", "Extra Details", "Execution Time"]}
                formatters={[
                    {
                        field: "date",
                        type: "DateTime",
                    },
                ]}
            />
        </div>
    );
};
