import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { paymentRequestSchema } from "../domain/payment-request";

export const getPaymentRequest = async ({ authToken, id }: { authToken: string; id: string }) => {
    const response = await makeRequest({
        method: "get",
        path: `/payment-requests/${id}`,
        authToken,
    });
    const paymentRequest = parseSchema(paymentRequestSchema, response.data);
    return paymentRequest;
};
