import { z } from "zod";

export const alertTypeSchema = z.object({
    id: z.number(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date().nullish(),
    alertType: z.string(),
    interceptorDetails: z.string().nullish(),
});

export type AlertType = z.infer<typeof alertTypeSchema>;
