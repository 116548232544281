import { useMutation } from "@tanstack/react-query";
import { CreatePaymentData, createPayment } from "../actions/create-payment";

export const useCreatePaymentMutation = () => {
    const mutation = useMutation({
        mutationFn: async ({ authToken, data }: { authToken: string; data: CreatePaymentData }) => {
            const payment = await createPayment({ authToken, data });
            return payment;
        },
    });
    return mutation;
};
