import { useEffect, useMemo, useState } from "react";
import Loader2 from "../../sections/utilities/Loader2";
import PageDescription from "../../layout/page-description";
import { startCase } from "lodash";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { useUsers } from "../../jason-proof-of-concept/users/hooks/use-users";
import { useNavigate } from "react-router";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import { timeAgo } from "../../sections/users/cards/calls-list";
import ButtonNeoGen from "../../layout/button-neogen";
import { useBreakpoint } from "../../hooks/appMedia";
import { BuildingOffice2Icon, InformationCircleIcon, UserIcon } from "@heroicons/react/24/outline";
import OptionsDropDown from "../../layout/options-dropdown";
import { useCompanies } from "../../companies/hooks/use-companies";
import ModalDialog from "../../layout/modal-dialog";
import TWSelect from "react-tailwindcss-select";
import { Option, SelectValue } from "react-tailwindcss-select/dist/components/type";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CompanyReviewUser } from "../domain/company-review-user";
import { createCompanyReviewUsers } from "../actions/create-company-review-users";
import { usePromise } from "../../jason-proof-of-concept/shared/hooks";
import { getCompanyReviewUsers } from "../actions/get-company-review-users";
import userAtom from "../../atoms/userAtom";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import { User } from "../../typings/api";

export const CompanyReviewPage = ({ darkMode }: { darkMode?: boolean }) => {
    const [showReviewersModal, setShowReviewersModal] = useState(false);
    const authToken = getAuthTokenNoThrow() || "no-auth";
    const navigate = useNavigate();

    const companiesQuery = useCompanies({ authToken, filters: { where: { status: "inReview" } } });
    const companies = useMemo(() => companiesQuery.data || [], [companiesQuery.data]);
    const usersQuery = useUsers({ authToken });
    const users = useMemo(() => usersQuery.data || [], [usersQuery.data]);
    const breakpoints = useBreakpoint();
    const isMobile = breakpoints.breakpoint === "mobile";
    const isTablet = breakpoints.breakpoint === "tablet";
    const [selectedUsers, setSelectedUsers] = useState<SelectValue[]>([]);
    const [isNextReviewer, setIsNextReviewer] = useState<any>();

    const createCompanyReviewUsersMutation = useMutation({
        mutationFn: async ({ authToken, data }: { authToken?: string; data: Partial<CompanyReviewUser[]> }) => {
            return await createCompanyReviewUsers({ authToken, data });
        },
    });

    const handleSubmitCompanyReviewUsers = async () => {
        if (selectedUsers.length === 0 || (selectedUsers.length === 1 && selectedUsers[0] === null)) {
            return;
        }

        await createCompanyReviewUsersMutation.mutateAsync({
            authToken,
            data: ((selectedUsers[0] as Option[]) || []).map((user: Option, index) => ({
                userId: user?.value,
                isNextReviewer: index === 0 ? true : false,
            })),
        });

        getCompanyReviewUserIds.execute();
        setShowReviewersModal(false);
    };

    const getCompanyReviewUserIds = usePromise(async () => {
        const reviewUserIds = await getCompanyReviewUsers({ authToken });
        return reviewUserIds;
    }, []);

    useEffect(() => {
        const reviewerIds: CompanyReviewUser[] | undefined = getCompanyReviewUserIds.result || [];

        const nextReviewer = reviewerIds.find((user: CompanyReviewUser) => user.isNextReviewer === true);

        if (!nextReviewer && reviewerIds.length > 0) {
            const newNextReviewer = users.find((user) => user.id === reviewerIds[0].userId);
            if (newNextReviewer) {
                setIsNextReviewer(newNextReviewer);
            }
        } else {
            const newNextReviewer = users.find((user) => user.id === nextReviewer?.userId);
            if (newNextReviewer) {
                setIsNextReviewer(newNextReviewer);
            }
        }

        if (reviewerIds) {
            setSelectedUsers([
                reviewerIds.map((user) => {
                    const foundUser = users.find((u) => u.id === user.userId);
                    return {
                        label: `${foundUser?.firstName} ${foundUser?.lastName}`,
                        value: user.userId,
                        disabled: false,
                    };
                }),
            ] as SelectValue[]);
        }
    }, [getCompanyReviewUserIds.result, users]);

    return (
        <>
            <PageDescription title="Company Review" description2="This is a list of companies under review.">
                <div className="text-right flex flex-col gap-3">
                    <ButtonNeoGen onClick={() => setShowReviewersModal(true)}>Select reviewers</ButtonNeoGen>
                </div>
            </PageDescription>
            <div>
                {companiesQuery.isLoading ? (
                    <div className={"p-5 text-center"}>
                        <Loader2 />
                    </div>
                ) : !isMobile && !isTablet ? (
                    <>
                        <Table>
                            <THead>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th>Created</Th>
                                    <Th>Status</Th>
                                    <Th>Owner</Th>
                                    <Th>Affiliate</Th>
                                    <Th>Reviewer</Th>
                                    <Th />
                                </Tr>
                            </THead>
                            <TBody>
                                {companies.map((company) => {
                                    const companyAffiliate = users.find(
                                        (u) => u.id === company.ownedBy?.affiliateUserId,
                                    );
                                    const reviewer = users.find((u) => u.id === company.reviewerId);
                                    return (
                                        <Tr key={company.id}>
                                            <Td>{company.name || "-"}</Td>
                                            <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                <div className="flex flex-col">
                                                    {company.createdAt?.toDateString()}
                                                    <span style={{ color: "gray" }}>
                                                        {company.createdAt
                                                            ? timeAgo(company.createdAt).toLocaleString()
                                                            : ""}
                                                    </span>
                                                </div>
                                            </Td>
                                            <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                <div className="flex flex-col">
                                                    <div>{company.status ? startCase(company.status) : "-"}</div>
                                                    <div style={{ color: "gray" }}>
                                                        {company.statusUpdatedAt
                                                            ? timeAgo(company.statusUpdatedAt).toLocaleString()
                                                            : null}
                                                    </div>
                                                </div>
                                            </Td>
                                            <Td>{[company.ownedBy?.firstName, company.ownedBy?.lastName].join(" ")}</Td>
                                            <Td>
                                                {[
                                                    companyAffiliate?.firstName || "No Affiliate",
                                                    companyAffiliate?.lastName,
                                                ].join(" ")}
                                            </Td>
                                            <Td>{[reviewer?.firstName || "-", reviewer?.lastName].join(" ")}</Td>
                                            <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                <ButtonNeoGen
                                                    size="sm"
                                                    onClick={() => {
                                                        navigate(`/companies/${company.id}`);
                                                    }}
                                                >
                                                    Open company
                                                </ButtonNeoGen>
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </TBody>
                        </Table>
                    </>
                ) : (
                    <ul>
                        {companies.map((company) => (
                            <li
                                key={company.id}
                                className="bg-gray-50 dark:bg-gray-700 dark:border-gray-900 p-4 rounded-lg shadow-md mb-4"
                            >
                                <div className="flex items-start justify-between">
                                    <div>
                                        <div className="flex gap-2 pb-1">
                                            <BuildingOffice2Icon
                                                color={darkMode ? "#4ade80" : "#4338ca"}
                                                className="w-5 h-5"
                                            />
                                            <span>{company.name ? company.name : "Unknown"}</span>
                                        </div>
                                        <div className="flex gap-2 pb-1">
                                            <UserIcon color={darkMode ? "#4ade80" : "#4338ca"} className="w-5 h-5" />
                                            <span>
                                                {company.ownedBy?.firstName
                                                    ? [company.ownedBy?.firstName, company.ownedBy?.lastName].join(" ")
                                                    : "Unknown"}
                                            </span>
                                        </div>
                                        <div className="flex gap-2 pb-1">
                                            <InformationCircleIcon
                                                color={darkMode ? "#4ade80" : "#4338ca"}
                                                className="w-5 h-5"
                                            />
                                            <span>{company.status ? startCase(company.status) : "Unknown"}</span>
                                        </div>
                                    </div>
                                    <OptionsDropDown
                                        asEllipses
                                        options={[
                                            {
                                                label: "Open company",
                                                action: () => navigate(`/companies/${company.id}`),
                                                icon: "fa fa-pencil",
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="flex justify-start w-full mt-2">
                                    <div className="text-xs font-light italic">
                                        Created at:{" "}
                                        <span>
                                            {company.createdAt
                                                ? timeAgo(company.createdAt).toLocaleString()
                                                : "Unknown"}
                                        </span>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            {showReviewersModal && (
                <ModalDialog
                    title="Select reviewers"
                    close={() => setShowReviewersModal(false)}
                    size="sm"
                    show={true}
                    showOk={false}
                    showCancel={false}
                >
                    <p className="mb-2 text-center">Who should review companies?</p>
                    <p className="mb-1 inline-block relative text-sm font-normal tracking-wider leading-normal text-gray-400 dark:text-gray-400">
                        Select users
                    </p>
                    <TWSelect
                        isSearchable={true}
                        isMultiple={true}
                        value={selectedUsers[selectedUsers.length - 1]}
                        onChange={(v) => setSelectedUsers([v])}
                        options={
                            (users || []).map((u) => ({
                                label: u.firstName + " " + u.lastName + " (" + u.email + ")",
                                value: u.id,
                            })) as Option[]
                        }
                        primaryColor="#6610f2"
                    />
                    <ButtonNeoGen className="w-full mt-3" onClick={handleSubmitCompanyReviewUsers}>
                        Save
                    </ButtonNeoGen>
                </ModalDialog>
            )}
        </>
    );
};
