import { useQuery } from "@tanstack/react-query";
import { getTransactions } from "../actions/get-transactions";

type Params = {
    authToken: string;
    filters?: any;
};

const getTransactionsQueryKey = ({ authToken, filters }: Params) => {
    return ["transactions", { authToken, filters }];
};

export const useTransactions = ({ authToken, filters }: Params) => {
    const query = useQuery({
        queryKey: getTransactionsQueryKey({ authToken, filters }),
        queryFn: async () => {
            const transactions = await getTransactions({ authToken, filters });
            return transactions;
        },
    });
    return query;
};
