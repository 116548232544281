import { makeRequest, parseSchema } from "../../shared/utils";
import { userSchema } from "../domain/user";

export const adminResetPassword = async ({
    authToken,
    id,
    data,
}: {
    authToken: string;
    id: string;
    data: {
        password: string;
    };
}) => {
    const response = await makeRequest({
        authToken,
        method: "post",
        path: `/users/${id}/reset-password`,
        data: {
            password: data.password,
        },
    });

    const user = parseSchema(userSchema, response.data);

    return user;
};
