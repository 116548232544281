export default function CardNeoGen(props: CardNeoGenProps) {
    return (
        <main className={"p-0 m-0 -ml-2 -mr-2 pb-8"}>
            <div className="max-w-3xl mx-auto px-0 sm:px-6 lg:max-w-7xl lg:px-2">
                {/* Main 3 column grid */}
                <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-1 ">
                    {/* Left column */}
                    <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                        {/* Welcome panel */}
                        <section aria-labelledby="profile-overview-title">
                            <div className="rounded-lg bg-white overflow-hidden shadow dark:bg-indigo-800">
                                <div className="bg-white p-6 dark:bg-indigo-900">
                                    <div className="sm:flex sm:items-center sm:justify-between">
                                        <div className="sm:flex sm:space-x-5">
                                            <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left text-sm font-medium text-gray-500">
                                                {props.children}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="border-t border-gray-200 bg-gray-50 dark:bg-gray-800 dark:border-gray-900 grid grid-cols-1 divide-y divide-gray-200 dark:divide-gray-900 sm:grid-cols-3 sm:divide-y-0 sm:divide-x"></div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
    );
}
type CardNeoGenProps = {
    children: any;
};
