import { z } from "zod";

export type AlertStatus = "read" | "unread" | "archived";

const AlertStatusSchema = z.string().refine((value) => ["read", "unread", "archived"].includes(value as AlertStatus));

export const AlertSchema = z.object({
    id: z.number(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date().nullish(),
    link: z.string().nullish(),
    channel: z.string(),
    alertRuleId: z.number(),
    channelId: z.string().nullish(),
    userId: z.string(),
    body: z.string(),
    title: z.string(),
    status: AlertStatusSchema,
});

export type Alert = z.infer<typeof AlertSchema>;
