import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { TextField } from "../../../layout/form/text-field";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { Company, CompanyStatus } from "../../domain/company";
import { SelectField } from "../../../layout/form/select-field";
import { useEffect, useMemo, useState } from "react";
import { User } from "../../../jason-proof-of-concept/users/domain/user";
import { sortUsers } from "../../../sections/utilities/sortUsers";
import { useMutation } from "@tanstack/react-query";
import { useSetupCompany } from "../../hooks/use-setup-company";
import { RoleGroup } from "../../../role-groups/domain/role-group";
import { useUpdateCompany } from "../../hooks/use-update-company";
import { startCase } from "lodash";
import { NumberField } from "../../../layout/form/number-field";
import {
    getEstimatedPayoutFromData,
    getW2Employees,
} from "../../../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { Currency } from "../../../billing/components";
import { UserCompany } from "../../../user-companies/domain/user-company";
import { useUpdateUserCompany } from "../../../user-companies/hooks/use-update-user-company";

const schema = z.object({
    commissionPercentage: z.number().optional(),
});

type Data = z.infer<typeof schema>;

export const EditUserCommissionModal = ({
    onClose,
    company,
    onUpdated,
    dealValue,
    clearErcFee,
    user,
    userCompany,
}: {
    onClose: () => any;
    company: Company;
    onUpdated: (userCompany: UserCompany) => void;
    dealValue: number;
    clearErcFee: number;
    user: User;
    userCompany: UserCompany;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const form = useForm({ schema, defaultValues: { commissionPercentage: userCompany.commissionPercentage || 0 } });
    const commissionPercentage = form.watch("commissionPercentage") || 0;

    const updateUserCompanyMutation = useUpdateUserCompany();

    const submitMutation = useMutation({
        mutationFn: async (data: Data) => {
            const updatedCompany = await updateUserCompanyMutation.mutateAsync({
                authToken,
                id: userCompany.id,
                data: {
                    commissionPercentage: data.commissionPercentage,
                },
            });
            return updatedCompany;
        },
    });

    const handleSubmit = async (data: Data) => {
        const company = await submitMutation.mutateAsync(data);
        onUpdated(company);
    };

    return (
        <>
            <ModalDialog
                show
                title={"Edit user commission"}
                close={onClose}
                showOk={false}
                showCancel={false}
                size="sm"
            >
                <Form onSubmit={form.handleSubmit(handleSubmit as any)} error={updateUserCompanyMutation.error as any}>
                    <div className="flex flex-col gap-1 mb-4" style={{ fontSize: 14 }}>
                        <div>
                            <b>
                                {user.firstName} {user.lastName}
                            </b>
                        </div>
                        <div>
                            Confirmed deal value:{" "}
                            <b>
                                <Currency amount={dealValue} />
                            </b>
                        </div>
                        <div>
                            Gross ClearERC fee:{" "}
                            <b>
                                <Currency amount={clearErcFee} />
                            </b>
                        </div>
                    </div>
                    <div className="flex flex-row gap-3 items-center">
                        <div className="flex-1">
                            <NumberField label="Commission %" {...form.getFieldProps("commissionPercentage")} />
                        </div>
                        <div className="flex-1">
                            Commission amount: <Currency amount={(commissionPercentage / 100) * clearErcFee} />
                        </div>
                    </div>
                    <div className="flex justify-end gap-4">
                        <ButtonNeoGen
                            type="outline"
                            disabled={updateUserCompanyMutation.isLoading}
                            onClick={() => onClose()}
                        >
                            Cancel
                        </ButtonNeoGen>
                        <ButtonNeoGen type="submit" disabled={updateUserCompanyMutation.isLoading}>
                            Update commission
                        </ButtonNeoGen>
                    </div>
                </Form>
            </ModalDialog>
        </>
    );
};
