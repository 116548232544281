import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { AlertSchema } from "../domain/alert";

export const getAlerts = async ({ authToken, filters }: { authToken?: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: "/alerts",
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });

    if (!response.data) return null;
    const alerts = parseSchema(z.array(AlertSchema), response.data);

    return alerts;
};
