import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { TextField } from "../../../layout/form/text-field";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { Company } from "../../domain/company";
import { SelectField } from "../../../layout/form/select-field";
import { useMemo } from "react";
import { User } from "../../../jason-proof-of-concept/users/domain/user";
import { sortUsers } from "../../../sections/utilities/sortUsers";
import { useMutation } from "@tanstack/react-query";
import { useSetupCompany } from "../../hooks/use-setup-company";
import { startCase } from "lodash";
import { RoleGroup } from "../../../role-groups/domain/role-group";
import { NumberField } from "../../../layout/form/number-field";
import { useRecoilState } from "recoil";
import userAtom from "../../../atoms/userAtom";
import { CheckboxField } from "../../../layout/form/checkbox-field";
import { PasswordField } from "../../../layout/form/password-field";
import authService from "../../../services/auth.service";
import { roleAssignments } from "../../../services/role-group.service";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";

const schema = z.object({
    name: z.string().min(1),
    roleId: z.number().nullish(),
    address: z
        .object({
            line1: z.string().nullish(),
            line2: z.string().nullish(),
            city: z.string().nullish(),
            state: z.string().nullish(),
            country: z.string().nullish(),
        })
        .nullish(),
    affiliateId: z.string().uuid().nullish(),
    ownerFullName: z.string().min(1),
    ownerEmail: z.string().email().min(1),
    ownerPhone: z.string().min(1),
    preSetPassword: z.boolean().optional(),
    password: z.string().min(8).optional(),
    confirmPassword: z.string().min(8).optional(),
    w2_2020: z.coerce.number().nullish(),
    w2_2021: z.coerce.number().nullish(),
});

type Data = z.infer<typeof schema>;

export const NewCompanyModal = ({
    onClose,
    onCompanyCreated,
    affiliates,
    roleId,
    roleGroups,
}: {
    onClose: () => any;
    onCompanyCreated: ({ company, owner }: { company: Company; owner: User }) => void;
    affiliates?: User[];
    roleId?: number;
    roleGroups?: RoleGroup[];
}) => {
    const [theUser] = useRecoilState(userAtom);
    const isSuperUser = theUser?.roleGroups?.find((r: any) => r.id === 4);
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const form = useForm({ schema, defaultValues: { affiliateId: isSuperUser ? undefined : theUser.id, roleId } });

    const setupCompanyMutation = useSetupCompany();

    const submitMutation = useMutation({
        mutationFn: async (data: Data) => {
            if (data.preSetPassword && data.password !== data.confirmPassword) {
                throw new Error("Passwords do not match");
            }

            const { company, owner } = await setupCompanyMutation.mutateAsync({
                authToken,
                data: {
                    company: {
                        name: data.name,
                        address: [data.address?.line1, data.address?.line2].join(", "),
                        city: data.address?.city,
                        state: data.address?.state,
                        country: data.address?.country,
                    },
                    owner: {
                        firstName: data.ownerFullName.split(" ")[0],
                        lastName: data.ownerFullName.split(" ")[1],
                        email: data.ownerEmail,
                        phone: data.ownerPhone,
                        userStatus: "invited",
                        affiliateUserId: data.affiliateId,
                    },
                    ownerRoleId: roleId || data.roleId || undefined,
                    ownerPassword: data.preSetPassword ? data.password : undefined,
                    ...(data.w2_2020 || data.w2_2021
                        ? {
                              w2Employees: {
                                  "2020": data.w2_2020 || 0,
                                  "2021": data.w2_2021 || 0,
                              },
                          }
                        : {}),
                },
            });
            return { company, owner };
        },
    });

    const handleSubmit = async (data: Data) => {
        const { company, owner } = await submitMutation.mutateAsync(data);
        onCompanyCreated({ company, owner });
    };

    const affiliateOptions = useMemo(
        () =>
            sortUsers((affiliates || []) as ClearERCUser[]).map((affiliate) => ({
                value: affiliate.id || "",
                label: `${[affiliate.firstName, affiliate.lastName].join(" ")} (${affiliate.email})`,
            })),
        [affiliates],
    );

    const values = form.watch();

    const usersRoleGroups = (authService.getCurrentUser()?.user?.roleGroups || []) as any[];
    const roleGroupsIds = usersRoleGroups.reduce<number[]>((acc, roleGroup: any) => {
        return [...acc, ...(roleAssignments?.[roleGroup.id] || [])];
    }, []);
    const finalRoleGroups = (roleGroups || []).filter((rg) => roleGroupsIds.includes(rg.id || 9999));

    const options = finalRoleGroups.map((role) => ({ label: role.name || "", value: role.id as number }));

    return (
        <>
            <ModalDialog show title={"New Company"} close={onClose} showOk={false} showCancel={false} size="sm">
                <Form onSubmit={form.handleSubmit(handleSubmit)} error={setupCompanyMutation.error as any}>
                    <div className="flex flex-row gap-10">
                        <div className="flex-1">
                            <TextField label="Name" {...form.getFieldProps("name")} placeholder="ACME Corp" />
                            {options.length > 0 && !roleId && (
                                <>
                                    <h3>Role</h3>
                                    <SelectField label="Role" {...form.getFieldProps("roleId")} options={options} />
                                </>
                            )}
                            <h3>Address</h3>
                            <TextField label="Line 1" {...form.getFieldProps("address.line1")} />
                            <TextField label="Line 2" {...form.getFieldProps("address.line2")} />
                            <div className="flex flex-row gap-5">
                                <div className="flex-1">
                                    <TextField label="City" {...form.getFieldProps("address.city")} />
                                </div>
                                <div className="flex-1">
                                    <TextField label="State" {...form.getFieldProps("address.state")} />
                                </div>
                            </div>
                            <div className="flex flex-row gap-5">
                                <div className="flex-1">
                                    <TextField label="Country" {...form.getFieldProps("address.country")} />
                                </div>
                            </div>
                            {values.roleId === 6 && (
                                <div className="flex flex-row gap-5 flex-1">
                                    <div className="flex-1">
                                        <NumberField
                                            label="Est. W2 Employees 2020"
                                            {...form.getFieldProps("w2_2020")}
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <NumberField
                                            label="Est. W2 Employees 2021"
                                            {...form.getFieldProps("w2_2021")}
                                        />
                                    </div>
                                </div>
                            )}
                            <h3>Owner details</h3>
                            <TextField
                                label="Full name"
                                {...form.getFieldProps("ownerFullName")}
                                placeholder="John Doe"
                            />
                            <TextField
                                label="Email"
                                {...form.getFieldProps("ownerEmail")}
                                placeholder="john@example.com"
                            />
                            <TextField
                                label="Phone"
                                {...form.getFieldProps("ownerPhone")}
                                placeholder="+44 123 456 789"
                            />
                            <CheckboxField label="Pre-set password" {...form.getFieldProps("preSetPassword")} />
                            {values.preSetPassword && (
                                <>
                                    <PasswordField label="Password" {...form.getFieldProps("password")} />
                                    <PasswordField
                                        label="Confirm password"
                                        {...form.getFieldProps("confirmPassword")}
                                    />
                                </>
                            )}
                            {isSuperUser && affiliateOptions.length > 0 && (
                                <SelectField
                                    label="Affiliate"
                                    {...form.getFieldProps("affiliateId")}
                                    options={affiliateOptions}
                                />
                            )}
                        </div>
                    </div>
                    <div className="flex justify-end gap-4">
                        <ButtonNeoGen
                            type="outline"
                            disabled={setupCompanyMutation.isLoading}
                            onClick={() => onClose()}
                        >
                            Cancel
                        </ButtonNeoGen>
                        <ButtonNeoGen type="submit" disabled={setupCompanyMutation.isLoading}>
                            Create Company
                        </ButtonNeoGen>
                    </div>
                </Form>
            </ModalDialog>
        </>
    );
};
