/* eslint-disable react/display-name */
import { forwardRef, InputHTMLAttributes, useState } from "react";

export type NumberInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "form">;

export const RangeSlider = forwardRef(
    (
        props: NumberInputProps & {
            hasError?: boolean;
            value: number;
            maxValue?: number;
            helpText?: string;
        },
        ref: any,
    ) => {
        const [value, setValue] = useState(props.value || null);
        const isControlled = props.value !== undefined;

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (props.onChange) {
                props.onChange(e);
            }

            if (!isControlled) {
                setValue(Number.parseInt(e.target.value, 10));
            }
        };

        return (
            <div style={{ position: "relative" }}>
                <input
                    id="default-range"
                    step={0.1}
                    type="range"
                    max={props.maxValue || 100}
                    disabled={props.disabled || false}
                    onChange={handleChange}
                    {...props}
                    defaultValue={0}
                    value={isControlled ? props.value || undefined : value || undefined}
                    className="accent-blue-700 w-full h-2 bg-gray-200 rounded-lg range-md appearance-none cursor-pointer dark:bg-gray-700"
                    ref={ref}
                />
                {props.helpText && (
                    <p id="commission-error-text" className="text-gray-500 text-xs font-extralight">
                        {props.helpText}
                    </p>
                )}
            </div>
        );
    },
);
