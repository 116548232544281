import { z } from "zod";
import { alertRuleAndChannelResponseSchema, alertRuleAndChannelSchema, alertRuleSchema } from "../domain/alert-rule";
import { makeRequest, parseSchema } from "../../../jason-proof-of-concept/shared/utils";

const createAlertRuleSchema = alertRuleSchema;

export type CreateAlertRuleData = z.infer<typeof createAlertRuleSchema>;
export type CreateAlertRuleAndChannelData = z.infer<typeof alertRuleAndChannelSchema>;

export const createAlertRule = async ({ authToken, data }: { authToken: string; data: CreateAlertRuleData }) => {
    const response = await makeRequest({
        method: "post",
        path: "/alert-rules/setup",
        authToken,
        data,
    });

    const alertRuleAndChannels = parseSchema(alertRuleAndChannelResponseSchema, response.data);
    return alertRuleAndChannels;
};
