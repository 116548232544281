import { z } from "zod";

export const userCompanySchema = z.object({
    id: z.number(),
    ercUserId: z.string().uuid(),
    companyId: z.number(),
    roleGroupId: z.number(),
    commissionPercentage: z.number().nullish(),
});

export type UserCompany = z.infer<typeof userCompanySchema>;
