import { Fragment, useCallback, useEffect, useLayoutEffect, useState, useMemo } from "react";
import { Dialog, Switch, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import logo from "../../images/logo.png";
import { NavLink, useLocation } from "react-router-dom";
import authService from "../../services/auth.service";
import { useQuery } from "@tanstack/react-query";
import Loader2 from "../utilities/Loader2";
import menuService from "../../services/menu.service";
import { Navigation } from "../../typings/api/navigations";
import ButtonNeoGen from "../../layout/button-neogen";
import { useCompanies } from "../../companies/hooks/use-companies";
import { getAuthTokenNoThrow } from "../../services/auth-header";

type NavigationType = {
    name: string;
    icon: string;
    href: string;
    current: boolean;
    requiredRoles?: string[];
};

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

const checkAllPresent = (arr: string[], target: string[]) => {
    return target.every((v) => arr.includes(v));
};
const checkOnePresent = (arr: string[], target: string[]) => {
    return target.some((v) => arr.includes(v));
};

export default function SideMenu(props: MenuProps): JSX.Element {
    const location = useLocation();
    const [navigation, setNavigation] = useState<Navigation[]>([]);
    function setWrapperEnabledDarkMode(enabled: boolean) {
        props.setDarkMode(enabled);
        if (enabled) {
            document.body.classList.add("dark-theme");
            document.body.classList.add("dark");
            localStorage.setItem("theme", "dark");
        } else {
            document.body.classList.remove("dark-theme");
            document.body.classList.remove("dark");
            localStorage.setItem("theme", "light");
        }
    }

    const menusQuery = useQuery(["menus", "visible", "ordered"], async () => {
        return await menuService.getAllVisibleOrdered();
    });

    useLayoutEffect(() => {
        setNavigation((current) =>
            current.map((item) => {
                if (location.pathname.startsWith(item.href)) {
                    item.current = 1;
                } else {
                    item.current = 0;
                }
                return item;
            }),
        );
    }, [location.pathname]);

    function identifyDuplicates(data: any[], key: string, unique = true) {
        const uniques: any[] = [];
        const duplicates = data.filter((x) => {
            if (uniques.length > 0 && uniques?.find((u) => u[key] == x[key]) != null) {
                return true;
            }
            uniques.push(x);
            return false;
        });
        return unique ? uniques : duplicates;
    }

    const getUnique = useCallback((data: any[], key: string) => {
        return identifyDuplicates(data, key);
    }, []);

    function getDuplicate(data: any[], key: string) {
        return identifyDuplicates(data, key, false);
    }

    const authToken = getAuthTokenNoThrow() || "no-auth";
    const companiesQuery = useCompanies({ authToken, filters: { where: { status: "inReview" } } });
    const companiesCount = useMemo(() => (companiesQuery.data || []).length, [companiesQuery.data]);

    useEffect(() => {
        if (menusQuery.isSuccess) {
            const newNav = (menusQuery.data ?? [])
                .map((item) => {
                    if (location.pathname.startsWith(item.href)) {
                        item.current = 1;
                    } else {
                        item.current = 0;
                    }
                    return item;
                })
                .filter(
                    (n) =>
                        n.requiredRoles === null ||
                        checkAllPresent(
                            authService.roles.map((r) => r?.roleCode ?? ""),
                            JSON.parse(n.requiredRoles).roleGroups ?? [],
                        ) ||
                        checkOnePresent(
                            authService.roles.map((r) => r?.roleCode ?? ""),
                            JSON.parse(n.requiredRoles).roleGroups ?? [],
                        ),
                );
            const unique = getUnique(newNav, "href");
            setNavigation(unique);
        }
    }, [menusQuery.isSuccess, menusQuery.data, location.pathname, getUnique]);

    if (menusQuery.isLoading) {
        return <Loader2 />;
    }

    return (
        <div className=" flex  bg-white sm:bg-slate-200 dark:bg-slate-900 overflow-hidden">
            <Transition.Root show={props.sideBarOpen ?? false} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed inset-0 flex z-40 lg:hidden"
                    open={props.sideBarOpen}
                    onClose={() => {
                        props.setSideBarOpen ? props.setSideBarOpen(false) : null;
                    }}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="absolute flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-slate-600 dark:bg-slate-800">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => (props.setSideBarOpen ? props.setSideBarOpen(false) : null)}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex-shrink-0 flex items-center px-4">
                                <img className="h-8 w-auto" src={logo} alt="ClearERC logo" />
                            </div>
                            <nav className="mt-5 flex-shrink-0 divide-y divide-indigo-800 " aria-label="Sidebar">
                                <div className="px-2 space-y-1">
                                    {navigation.map((item) => (
                                        <NavLink
                                            key={item.name}
                                            to={item.href}
                                            className={classNames(
                                                item.current
                                                    ? "bg-indigo-400 text-white"
                                                    : "text-indigo-200 hover:text-white hover:bg-indigo-400",
                                                "group flex items-center px-2 py-2 text-base font-medium rounded-md",
                                            )}
                                            aria-current={item.current ? "page" : undefined}
                                            onClick={() => (props.setSideBarOpen ? props.setSideBarOpen(false) : null)}
                                        >
                                            <span
                                                className={
                                                    "fad " + item.icon + " mr-4 flex-shrink-0 h-6 w-6 text-indigo-200"
                                                }
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                            {/* Hide deprecating warning */}
                                            {/* {item.name === "Users" && (
                                                <div className="py-1 px-2 text-xs text-white rounded-full bg-red-500 ml-2">
                                                    Deprecating
                                                </div>
                                            )} */}
                                        </NavLink>
                                    ))}
                                    <ButtonNeoGen
                                        className={classNames(
                                            "text-indigo-200 hover:text-white hover:bg-indigo-600",
                                            "group flex items-center px-2 py-2 text-base font-medium rounded-md",
                                        )}
                                        aria-current={undefined}
                                    >
                                        <span
                                            className={"fad fa-bug mr-4 flex-shrink-0 h-6 w-6 text-indigo-200"}
                                            aria-hidden="true"
                                        />
                                        {"Debug"}
                                    </ButtonNeoGen>
                                </div>
                            </nav>
                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:flex lg:flex-shrink-0 h-screen">
                <div className="flex flex-col w-64">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex flex-col flex-grow bg-indigo-700 dark:bg-slate-800 pt-5 pb-4 overflow-hidden">
                        <div className="flex items-center flex-shrink-0 px-4">
                            <img className="h-8 w-auto" src={logo} alt="ClearERC logo" />
                        </div>
                        <nav className="flex-grow mt-5 flex-1 flex flex-col overflow-y-auto" aria-label="Sidebar">
                            <div className="px-2 space-y-1">
                                {navigation.map((item) => (
                                    <NavLink
                                        key={item.name}
                                        to={item.href}
                                        className={classNames(
                                            item.current
                                                ? "bg-indigo-800 text-white dark:text-green-400"
                                                : "text-indigo-100 dark:text-gray-500 hover:text-white hover:bg-indigo-600 dark:hover:text-green-400",
                                            "group flex items-center px-2 py-2  leading-6 font-medium rounded-md ",
                                        )}
                                        aria-current={item.current ? "page" : undefined}
                                    >
                                        <span
                                            className={
                                                "fal fa-lg " +
                                                item.icon +
                                                " mr-4 flex-shrink-0 h-1 w-6 text-indigo-200" +
                                                (item.current ? "" : "dark:text-gray-600") +
                                                "dark:group-hover:text-green-400 "
                                            }
                                            aria-hidden="true"
                                        />
                                        {item.name}
                                        {/* Hide deprecating warning */}
                                        {/* {item.name === "Users" && (
                                            <div className="py-1 px-2 text-xs text-white rounded-full bg-red-500 ml-2">
                                                Deprecating
                                            </div>
                                        )} */}
                                        {item.name === "Company Review" && companiesCount > 0 && (
                                            <div className="ml-1">({companiesCount})</div>
                                        )}
                                    </NavLink>
                                ))}
                            </div>
                            <Switch.Group
                                as="li"
                                className="flex-shrink-0 py-4 px-5 flex items-center justify-between mr-4 pb-0"
                            >
                                <div className="flex flex-col">
                                    <Switch.Label
                                        as="p"
                                        className="text-sm font-medium text-gray-100 dark:text-gray-500"
                                        passive
                                    >
                                        {!props.darkMode ? "Light Mode" : "Dark Mode"}
                                    </Switch.Label>
                                </div>
                                <Switch
                                    checked={props.darkMode}
                                    onChange={setWrapperEnabledDarkMode}
                                    className={classNames(
                                        props.darkMode ? "bg-blue-800" : "bg-gray-200",
                                        "ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500",
                                    )}
                                >
                                    <span className="sr-only">Use setting</span>
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                            props.darkMode ? "translate-x-5" : "translate-x-0",
                                            "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
                                        )}
                                    />
                                </Switch>
                            </Switch.Group>
                        </nav>
                    </div>
                </div>
            </div>

            <div className={"  bg-indigo-700 dark:bg-slate-800 w-full overflow-hidden h-screen min-h-screen "}>
                {props.children}
            </div>
        </div>
    );
}

type MenuProps = {
    children: JSX.Element;
    darkMode: boolean;
    setDarkMode: (darkMode: boolean) => void;
    sideBarOpen?: boolean;
    setSideBarOpen?: (sidebarOpen: boolean) => void;
};
