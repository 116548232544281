import { useQuery } from "@tanstack/react-query";
import { getRoleGroups } from "../actions/get-role-groups";

type Params = {
    authToken: string;
    filters?: any;
};

const getRoleGroupsQueryKey = ({ authToken, filters }: Params) => {
    return ["roleGroups", { authToken, filters }];
};

export const useRoleGroups = ({ authToken, filters }: Params) => {
    const query = useQuery({
        queryKey: getRoleGroupsQueryKey({ authToken, filters }),
        queryFn: async () => {
            const roleGroups = await getRoleGroups({ authToken, filters });
            return roleGroups;
        },
    });
    return query;
};
