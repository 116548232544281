import { z } from "zod";

export const callSchema = z.object({
    id: z.string().uuid(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    date: z.coerce.date(),
    duration: z.number().nullish(),
    dialerUserId: z.string().uuid().nullish(),
    dialerNumber: z.string().nullish(),
    receiverUserId: z.string().uuid().nullish(),
    receiverNumber: z.string().nullish(),
    notes: z.string().nullish(),
});

export type Call = z.infer<typeof callSchema>;
