import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { paymentRequestSchema } from "../domain/payment-request";

export type PaymentRequestFilters = {
    [key: string]: string;
};

export const getPaymentRequests = async ({
    authToken,
    filters,
}: {
    authToken: string;
    filters?: PaymentRequestFilters;
}) => {
    const response = await makeRequest({
        method: "get",
        path: `/payment-requests`,
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });
    const paymentRequests = parseSchema(z.array(paymentRequestSchema), response.data);
    return paymentRequests;
};
