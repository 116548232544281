import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { TextField } from "../../../layout/form/text-field";
import { useMutation } from "@tanstack/react-query";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { User } from "../../../jason-proof-of-concept/users/domain/user";
import { adminResetPassword } from "../../../jason-proof-of-concept/users/actions/admin-reset-password";
import { PasswordField } from "../../../layout/form/password-field";

const schema = z.object({
    password: z.string().min(8),
    passwordCheck: z.string().min(8),
});

type Data = z.infer<typeof schema>;

export const AdminResetPasswordModal = ({
    onClose,
    user,
    onUserUpdated,
}: {
    onClose: () => any;
    user: User;
    onUserUpdated: (updatedUser: User) => any;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    if (!user.id) {
        throw new Error("User must have an id");
    }
    const userId = user.id as string;

    const form = useForm({ schema });

    const mutation = useMutation({
        mutationFn: async (data: Data) => {
            if (data.password !== data.passwordCheck) {
                throw new Error("Passwords do not match");
            }
            const updatedUser = await adminResetPassword({
                authToken,
                id: userId,
                data: { password: data.password },
            });
            return updatedUser;
        },
    });

    const handleSubmit = async (data: Data) => {
        const updatedUser = await mutation.mutateAsync(data);
        onUserUpdated(updatedUser as any);
    };

    return (
        <ModalDialog show title={"Reset user password"} close={onClose} showOk={false} showCancel={false}>
            <Form onSubmit={form.handleSubmit(handleSubmit)} error={mutation.error as any}>
                <PasswordField label="New password" {...form.getFieldProps("password")} />
                <PasswordField label="New password (Again)" {...form.getFieldProps("passwordCheck")} />
                <ButtonNeoGen block type="submit" disabled={mutation.isLoading}>
                    Reset password
                </ButtonNeoGen>
            </Form>
        </ModalDialog>
    );
};
