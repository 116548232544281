import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { Company } from "../../domain/company";
import { NumberField } from "../../../layout/form/number-field";
import { TextAreaField } from "../../../layout/form/text-area-field";
import { User } from "../../../jason-proof-of-concept/users/domain/user";
import { Field } from "../../../layout/form/field";
import { DateTimeField } from "../../../layout/form/date-field";
import { useAuth } from "../../../auth/use-auth";
import { TextField } from "../../../layout/form/text-field";
import { useMutation } from "@tanstack/react-query";
import {
    CreatePaymentRequestData,
    createPaymentRequest,
} from "../../../payment-requests/actions/create-payment-request";
import { PaymentRequest, PaymentRequestStatus } from "../../../payment-requests/domain/payment-request";

export const CreatePaymentRequestModal = ({
    onClose,
    companyId,
    onCreate: onCreate,
    maxAmount,
    defaultValues,
    fromUser,
    fromCompany,
    toUser,
    toCompany,
}: {
    onClose: () => any;
    companyId: number;
    onCreate: (paymentRequest: PaymentRequest) => void;
    maxAmount?: number;
    defaultValues?: Partial<PaymentRequest>;
    fromUser?: User;
    fromCompany?: Company;
    toUser?: User;
    toCompany?: Company;
}) => {
    const schema = z.object({
        description: z.string().nullish(),
        requestedPaymentDate: z.date(),
        amount: maxAmount ? z.number().positive().max(maxAmount) : z.number().positive(),
        notes: z.string().nullish(),
        from: z.string().nullish(),
        fromClearErc: z.boolean().nullish(),
        fromUserId: z.string().uuid().nullish(),
        fromCompanyId: z.number().nullish(),
        to: z.string().nullish(),
        toClearErc: z.boolean().nullish(),
        toUserId: z.string().uuid().nullish(),
        toCompanyId: z.number().nullish(),
    });
    type Data = z.infer<typeof schema>;

    const auth = useAuth();
    const authToken = getAuthTokenNoThrow() || "no-auth-token";

    const form = useForm({
        schema,
        defaultValues: {
            notes: "",
            ...defaultValues,
            requestedPaymentDate: new Date(),
        },
    });

    const formData = form.getValues();

    const createPaymentRequestMutation = useMutation({
        mutationFn: async (data: CreatePaymentRequestData) => {
            const paymentRequest = await createPaymentRequest({ authToken, data });
            return paymentRequest;
        },
    });

    const handleSubmit = async (data: Data) => {
        const paymentRequest = await createPaymentRequestMutation.mutateAsync({
            companyId,
            status: PaymentRequestStatus.Pending,
            ...data,
        });
        onCreate(paymentRequest);
    };

    return (
        <>
            <ModalDialog show title={"New payment request"} close={onClose} showOk={false} showCancel={false} size="sm">
                <p className="text-center mb-6 text-gray-400">
                    Create a request for a payment to be made between two parties.
                </p>
                <Form
                    onSubmit={form.handleSubmit(handleSubmit as any)}
                    error={createPaymentRequestMutation.error as any}
                >
                    <div className="flex flex-row">
                        <div className="flex-1">
                            <Field label="From">
                                {formData.fromClearErc
                                    ? "ClearErc"
                                    : formData.from || fromUser?.firstName || fromCompany?.name}
                            </Field>
                        </div>
                        <div className="flex-1">
                            <Field label="To">
                                {formData.toClearErc ? "ClearErc" : formData.to || toUser?.firstName || toCompany?.name}
                            </Field>
                        </div>
                    </div>
                    <TextField label="Description" {...form.getFieldProps("description")} />
                    <DateTimeField
                        label="Requested payment date"
                        dateFormat="yyyy/MM/dd"
                        showTimeInput={false}
                        showTimeSelect={false}
                        {...form.getFieldProps("requestedPaymentDate")}
                    />
                    <NumberField label="Amount" {...form.getFieldProps("amount")} />
                    <TextAreaField label="Notes" {...form.getFieldProps("notes")} />
                    <div className="flex justify-end gap-4">
                        <ButtonNeoGen
                            type="outline"
                            disabled={createPaymentRequestMutation.isLoading}
                            onClick={() => onClose()}
                        >
                            Cancel
                        </ButtonNeoGen>
                        <ButtonNeoGen type="submit" disabled={createPaymentRequestMutation.isLoading}>
                            Create payment request
                        </ButtonNeoGen>
                    </div>
                </Form>
            </ModalDialog>
        </>
    );
};
