import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { paymentSchema } from "../domain/payment";

export const getPayments = async ({ authToken, filters }: { authToken: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: `/payments`,
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });
    const payments = parseSchema(z.array(paymentSchema), response.data);
    return payments;
};
