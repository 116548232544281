import { useEffect, useState } from "react";
import HorizontalMenu from "../horizontal-menu/menu";
import menuService from "../../services/menu.service";
import ButtonNeoGen from "../../layout/button-neogen";

const user = {
    name: "Tom Cook",
    email: "tom@example.com",
    imageUrl:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};
const navigation = [
    { name: "Dashboard", href: "#", current: true },
    { name: "Team", href: "#", current: false },
    { name: "Projects", href: "#", current: false },
    { name: "Calendar", href: "#", current: false },
    { name: "Reports", href: "#", current: false },
];
const userNavigation = [
    { name: "Your Profile", href: "#" },
    { name: "Settings", href: "#" },
    { name: "Sign out", href: "#" },
];

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

type DashboardTopProps = {
    className?: string;
    children?: React.ReactNode;
    isAffiliateInTraining?: boolean;
};

export default function DashboardTop(props: DashboardTopProps) {
    const [darkMode, setDarkMode] = useState(false);
    const [pageTitle, setPageTitle] = useState("Unknown");
    const [haveResettableUser, setHaveResettableUser] = useState(localStorage.getItem("haveResettableUser") === "1");
    //  from react router
    const url = window.location.pathname.split("/").pop();
    // get the associate json for the menuService entry based on the pageName varuable
    useEffect(() => {
        if (url) {
            menuService.getByURL(url).then((data) => {
                setPageTitle(data?.name ?? "Unknown");
            });
        }
    }, [url]);

    function resetUser() {
        const items = { ...localStorage };
        for (const key in items) {
            if (!key.startsWith("RESETTER")) {
                continue;
            }
            localStorage.setItem(key.substring(8), items[key]);
            localStorage.removeItem(key);
        }
        localStorage.setItem("haveResettableUser", "0");
        window.location.reload();
    }

    return (
        <>
            <div className="min-h-full">
                <div className="bg-gradient-to-b from-slate-400 to-slate-300 dark:from-slate-800 dark:to-slate-900 dark:bg-indigo-900 pb-40">
                    <HorizontalMenu
                        darkMode={darkMode}
                        setDarkMode={setDarkMode}
                        isAffiliateInTraining={props.isAffiliateInTraining}
                    />
                </div>

                <main className="-mt-36">
                    <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
                        {haveResettableUser && (
                            <div className="mb-3">
                                <ButtonNeoGen
                                    icon="fa fa-recycle"
                                    type="primary"
                                    onClick={() => {
                                        resetUser();
                                    }}
                                >
                                    Reset User
                                </ButtonNeoGen>
                            </div>
                        )}
                        {props.children}
                    </div>
                </main>
            </div>
        </>
    );
}
