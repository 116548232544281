import { z } from "zod";
import { makeRequest, parseSchema } from "../../../jason-proof-of-concept/shared/utils";
import { alertTypeSchema } from "../domain/alert-type";

export const getAlertTypes = async ({ authToken, filters }: { authToken: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: "/alert-types",
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });

    const alertTypes = parseSchema(z.array(alertTypeSchema), response.data);

    return alertTypes;
};
