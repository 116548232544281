import { z } from "zod";
import ModalDialog from "../../layout/modal-dialog";
import { useForm } from "../../hooks/useForm";
import { Form } from "../../layout/form/form";
import { TextField } from "../../layout/form/text-field";
import { CalendarEvent, calendarEventEngagementTypes } from "../domain/calendar-event";
import { DateTimeField } from "../../layout/form/date-field";
import { useMutation } from "@tanstack/react-query";
import { updateCalendarEvent } from "../actions/update-calendar-event";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import ButtonNeoGen from "../../layout/button-neogen";
import { TextAreaField } from "../../layout/form/text-area-field";
import { useUsers } from "../../jason-proof-of-concept/users/hooks/use-users";
import { SelectField } from "../../layout/form/select-field";

const schema = z.object({
    title: z.string(),
    description: z.string(),
    date: z.date(),
    engagementType: z.string(),
    assigneeId: z.string(),
});

type Data = z.infer<typeof schema>;

export const EditCalendarEventModal = ({
    onClose,
    calendarEvent,
    onUpdate,
}: {
    onClose: () => any;
    calendarEvent: CalendarEvent;
    onUpdate: (calendarEvent: CalendarEvent) => any;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth";
    const usersQuery = useUsers({ authToken });
    const users = usersQuery.data || [];
    const form = useForm({
        schema,
        defaultValues: {
            title: calendarEvent.title || undefined,
            description: calendarEvent.description || undefined,
            date: calendarEvent.date || undefined,
            engagementType: calendarEvent.engagementType || undefined,
            assigneeId: calendarEvent.assigneeId || undefined,
        },
    });

    const mutation = useMutation({
        mutationFn: async (data: Data) => {
            const updatedEvent = await updateCalendarEvent({ authToken, id: calendarEvent.id, data });
            return updatedEvent;
        },
    });

    const handleSubmit = async (data: Data) => {
        const updatedEvent = await mutation.mutateAsync(data);
        onUpdate(updatedEvent);
    };

    return (
        <ModalDialog size="sm" show title="Update calendar event" close={onClose} showCancel={false} showOk={false}>
            <Form onSubmit={form.handleSubmit(handleSubmit as any)}>
                <DateTimeField label="Date" {...form.getFieldProps("date")} />
                <SelectField
                    label="Assignee (Agent)"
                    isRequired
                    {...form.getFieldProps("assigneeId")}
                    options={Object.values(users).map((a) => ({
                        value: a.id,
                        label: `${a.firstName} ${a.lastName} ${a.name ? `(${a.name})` : ""}`,
                    }))}
                />
                <SelectField
                    label="Engagement type"
                    isRequired
                    {...form.getFieldProps("engagementType")}
                    options={Object.values(calendarEventEngagementTypes).map((a) => ({
                        value: a,
                        label: a.slice(0, 1).toUpperCase() + a.slice(1).replaceAll("_", " "),
                    }))}
                />
                <TextField label="Title" {...form.getFieldProps("title")} />
                <TextAreaField label="Description" {...form.getFieldProps("description")} />
                <ButtonNeoGen block type="submit">
                    {mutation.isLoading ? "Loading..." : "Update"}
                </ButtonNeoGen>
            </Form>
        </ModalDialog>
    );
};
