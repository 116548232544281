import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { paymentRequestSchema } from "../domain/payment-request";

const createPaymentRequestSchema = paymentRequestSchema
    .omit({ createdAt: true, updatedAt: true })
    .partial({ id: true });

export type CreatePaymentRequestData = z.infer<typeof createPaymentRequestSchema>;

export const createPaymentRequest = async ({
    authToken,
    data,
}: {
    authToken: string;
    data: CreatePaymentRequestData;
}) => {
    const response = await makeRequest({
        method: "post",
        path: "/payment-requests",
        authToken,
        data,
    });

    const paymentRequest = parseSchema(paymentRequestSchema, response.data);
    return paymentRequest;
};
