import { useQuery } from "@tanstack/react-query";
import { getInteractions } from "../actions/get-interactions";

type Params = {
    authToken: string;
    filters?: any;
    enabled?: boolean;
};

const getInteractionsQueryKey = ({ authToken, filters }: Params) => {
    return ["interactions", { authToken, filters }];
};

export const useInteractions = ({ authToken, filters, enabled }: Params) => {
    const query = useQuery({
        queryKey: getInteractionsQueryKey({ authToken, filters }),
        queryFn: async () => {
            const interactions = await getInteractions({ authToken, filters });
            return interactions;
        },
        enabled,
    });
    return query;
};
