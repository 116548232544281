import { PaperClipIcon } from "@heroicons/react/20/solid";
/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')

  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          'blue-gray': colors.blueGray,
        },
      },
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
    BanknotesIcon,
    Bars3Icon,
    BellIcon,
    BookmarkSquareIcon,
    CogIcon,
    EnvelopeIcon,
    FireIcon,
    HomeIcon,
    InboxIcon,
    KeyIcon,
    ListBulletIcon,
    MagnifyingGlassCircleIcon,
    PencilSquareIcon,
    PhoneIcon,
    PhotoIcon,
    SquaresPlusIcon,
    UserIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import interactionsService from "../../services/interactions.service";
import usersService from "../../services/users.service";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import NewMessage from "../messages/modals/new-message";
import ShowMessage from "../messages/modals/show-message";
import CustomerCard from "./customer-card";
import BasicCard from "./cards/basic";
import NotesCard from "./cards/notes";
import MessagesCard from "./cards/messages";
import PhoneCard from "./cards/phone";
import SummaryCard from "./cards/summary";
import { UsersIcon } from "@heroicons/react/24/solid";

const subNavigation = [
    {
        name: "Basic Information",
        description: "Basic information about the customer and a feed that shows all interactions",
        id: "basic",
        icon: UserIcon,
        current: true,
    },
    {
        name: "Notes",
        description: "Any notes that you have taken about the customer.",
        id: "notes",
        icon: PencilSquareIcon,
        current: false,
    },
    {
        name: "Phone Calls",
        description: "History of phone calls with the customer.",
        id: "phone",
        icon: PhoneIcon,
        current: false,
    },
    {
        name: "Messages",
        description: "Message history with the customer.",
        id: "messages",
        icon: EnvelopeIcon,
        current: false,
    },
    {
        name: "Summary",
        description: "A summary of the responses from the customer.",
        id: "summary",
        icon: ListBulletIcon,
        current: false,
    },
    // {
    //     name: "Files",
    //     description: "Files that the customer has uploaded.",
    //     files: "files",
    //     icon: PaperClipIcon,
    //     current: false,
    // },
    // {
    //     name: "Additional Resources",
    //     description: "Quis viverra netus donec ut auctor fringilla facilisis. Nunc sit donec cursus sit quis et.",
    //     href: "#",
    //     icon: MagnifyingGlassCircleIcon,
    //     current: false,
    // },
];

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

function compareUsers(a: ClearERCUser, b: ClearERCUser) {
    const aSort = a.name ?? a.email ?? "";
    const bSort = b.name ?? b.email ?? "";
    if (aSort.toLowerCase() < bSort.toLowerCase()) {
        return -1;
    }
    if (aSort.toLowerCase() > bSort.toLowerCase()) {
        return 1;
    }
    return 0;
}

export default function CustomerCard2() {
    const { id } = useParams();
    const [show, setShow] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [messageId, setMessageId] = useState(0);
    const [selectedUser, setSelectedUser] = useState("");
    // const [users, setUsers] = useState<ErcUser[]>([]);
    const [page, setPage] = useState("basic");
    const [user, setUser] = useState<ClearERCUser>();
    const usersQuery = useQuery(["users", "sorted"], async () => {
        const response = await usersService.getAll();
        if (response) {
            return response.data.sort(compareUsers);
        }
    });

    const messagesQuery = useQuery(["messages"], async () => {
        const response = await interactionsService.getAll();
        if (response) {
            return response.data;
        }
    });

    useEffect(() => {
        if (usersQuery.data) {
            const foundUser = usersQuery.data.find((u) => u.id === id);
            if (foundUser) {
                setUser(foundUser);
                setPage("basic");
                subNavigation.forEach((i) => (i.current = false));
                subNavigation[0].current = true;
            }
        }
    }, [id, usersQuery.data]);

    function getCurrentCard() {
        switch (page) {
            case "basic":
                return <BasicCard user={user} setUser={setUser} />;
            case "notes":
                return <NotesCard user={user} />;
            case "messages":
                return <MessagesCard user={user} />;
            case "phone":
                return <PhoneCard user={user} />;
            case "summary":
                return <SummaryCard user={user} />;
        }
    }

    return (
        <div className="flex flex-1 overflow-hidden bg-white shadow sm:rounded-lg h-full dark:bg-gray-800">
            <main className="flex flex-1 overflow-hidden">
                <div className="flex flex-1 flex-col overflow-y-auto xl:overflow-hidden">
                    <div className="flex flex-1 xl:overflow-hidden">
                        {/* Secondary sidebar */}
                        <nav
                            aria-label="Sections"
                            className="min-h-screen w-96 flex-shrink-0 border-r border-blue-gray-200 dark:border-gray-900 bg-white xl:flex xl:flex-col dark:bg-gray-800"
                        >
                            <div className="flex h-16 flex-shrink-0 items-center border-b border-gray-200 px-6 dark:border-gray-900">
                                <p className="text-lg font-medium text-blue-gray-900 dark:text-gray-400">
                                    {user?.name}{" "}
                                </p>
                            </div>
                            <div className="min-h-0 flex-1 overflow-y-auto">
                                {subNavigation.map((item) => (
                                    <button
                                        key={item.name}
                                        onClick={() => {
                                            subNavigation.forEach((i) => (i.current = false));
                                            item.current = true;

                                            setPage(item.id ?? "basic");
                                        }}
                                        className={classNames(
                                            item.current
                                                ? "bg-blue-50 bg-opacity-50 dark:bg-indigo-900"
                                                : "hover:bg-blue-50 hover:bg-opacity-50 hover:dark:bg-blue-900",
                                            "flex p-6 border-b border-blue-gray-200 text-left w-full dark:border-gray-900",
                                        )}
                                        aria-current={item.current ? "page" : undefined}
                                    >
                                        <item.icon
                                            className="-mt-0.5 h-6 w-6 flex-shrink-0 text-blue-gray-400"
                                            aria-hidden="true"
                                        />
                                        <div className="ml-3 text-sm">
                                            <p className="font-medium text-gray-900 dark:text-gray-300">{item.name}</p>
                                            <p className="mt-1 text-gray-500">{item.description}</p>
                                        </div>
                                    </button>
                                ))}
                            </div>
                        </nav>
                        <div className="flex-1 xl:overflow-y-auto">
                            <div className="mx-auto max-w-5xl py-5 px-4 sm:px-6 lg:px-8">{getCurrentCard()}</div>
                        </div>
                    </div>
                </div>
            </main>

            {/* <NewMessage show={show} setShow={setShow} /> */}
            <ShowMessage id={messageId} show={showMessage} setShow={setShowMessage} />
        </div>
    );
}
