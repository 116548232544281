import axios, { AxiosResponse } from "axios";
// import { API_URL } from "./api.service";
import authHeader from "./auth-header";

const EXTERNAL_SYNC_SERVICE = process.env.REACT_EXTERNAL_SYNC_SERVICE ?? "http://localhost:4567";

export type DealResponse = {
    data: Deal[];
};

// export type Deal = {
//     data?: [
//         {
//             boards: [
//                 {
//                     items: [
//                         {
//                             name: string;
//                             column_values: [
//                                 {
//                                     title: string;
//                                     value: string;
//                                     text: string;
//                                 },
//                             ];
//                         },
//                     ];
//                 },
//             ];
//         },
//     ];
// };

export type Deal = {
    boards?: any[];
    data?: any[];
};

class ExternalSyncService {
    deals: Deal[] = [];
    constructor() {
        console.log("ExternalSyncService constructor");
    }
    async getDeals() {
        if (this.deals.length === 0) {
            return await this.updateDeals();
        }
        return this.deals;
    }
    updateDeals(): Promise<void | Deal[] | any> {
        // console.log*()
        return axios
            .get<Deal[]>(EXTERNAL_SYNC_SERVICE + "/deals", { headers: authHeader() })
            .then((r) => {
                console.error(r);
                this.deals = r.data;
                return r.data;
            })
            .catch((e) => {
                console.error(EXTERNAL_SYNC_SERVICE);
                console.error(e);
                console.error(e?.response);
                console.error(e?.response?.status);
                if (e?.response?.status === 401 || e?.response?.status === 403) {
                    console.error("Logout6");
                    localStorage.removeItem("user");
                    window.location.href = `/login/2?redirectUri=${encodeURIComponent(window.location.pathname)}`;
                    throw new Error("Unauthorized");
                    // window.location.reload();
                }
            });
    }
    getPhoneFromItem(item: { boards?: any[] | undefined; data?: any[] | undefined; column_values?: any }): string {
        const phone = item.column_values?.find((c: { title: string }) => c.title === "Phone");
        if (phone) {
            return phone.text;
        }
        return "";
    }

    getStageFromItem(item: { boards?: any[] | undefined; data?: any[] | undefined; column_values?: any }): string {
        const phone = item.column_values?.find((c: { title: string }) => c.title === "Stage");
        if (phone) {
            return phone.text;
        }
        return "";
    }

    getAffiliateFromItem(item: { boards?: any[] | undefined; data?: any[] | undefined; column_values?: any }): string {
        const affiliate = item.column_values?.find((c: { title: string }) => c.title === "Affiliate ID");
        if (affiliate) {
            return affiliate.text;
        }
        return "";
    }
    getLinkFromItem(item: { boards?: any[] | undefined; data?: any[] | undefined; column_values?: any }): string {
        /*
        {
        "title": "Platform Link",
        "value": "{\"url\":\"https://login.clearerc.com/summary/\",\"text\":\"Profile\"}",
        "text": "Profile - https://login.clearerc.com/summary/"
      },*/

        const link = item.column_values?.find((c: { title: string }) => c.title === "Platform Link");
        if (link) {
            const linkValue = JSON.parse(link.value);
            const id = linkValue?.url?.split("/").pop();
            return id ?? "";
            // return link.text;
        }
        return "";
    }

    getLastContactedFromItem(item: {
        boards?: any[] | undefined;
        data?: any[] | undefined;
        column_values?: any;
    }): string {
        const stage = item.column_values?.find((c: { title: string }) => c.title === "Last Contacted");
        if (stage) {
            return stage.text;
        }
        return "";
    }

    getLastContactedUpdatedFromItem(item: {
        boards?: any[] | undefined;
        data?: any[] | undefined;
        column_values?: any;
    }): string {
        const stage = item.column_values?.find((c: { title: string }) => c.title === "Last  Updated");
        if (stage) {
            return stage.text;
        }
        return "";
    }

    getNameFromItem(item: { boards?: any[] | undefined; data?: any[] | undefined; name?: any }): string {
        return item.name;
    }
}

export default new ExternalSyncService();
