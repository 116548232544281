/* eslint-disable react/display-name */
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { forwardRef, InputHTMLAttributes, useState } from "react";

export type NumberInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "form">;

export const NumberInput = forwardRef((props: NumberInputProps & { hasError?: boolean; value: number }, ref: any) => {
    const [value, setValue] = useState(props.value || null);
    const isControlled = props.value !== undefined;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
            const updatedE = { ...e, target: { ...e.target, value: Number.parseFloat(e.target.value) as any } };
            props.onChange(updatedE);
        }

        if (!isControlled) {
            setValue(Number.parseFloat(e.target.value));
        }
    };

    return (
        <div style={{ position: "relative" }}>
            {props.hasError && (
                <div className={"absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none "}>
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
            )}
            <input
                type="number"
                step="any"
                onChange={handleChange}
                {...props}
                value={isControlled ? props.value || undefined : value || undefined}
                className={
                    " dark:bg-gray-800  " +
                    (props.hasError
                        ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                        : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-900  dark:border-gray-900 dark:text-gray-300")
                }
                ref={ref}
            />
        </div>
    );
});
