import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { companySchema } from "../domain/company";

export const getCompany = async ({ authToken, id }: { authToken: string; id: number }) => {
    const response = await makeRequest({
        method: "get",
        path: `/companies/${id}`,
        authToken,
    });

    const company = parseSchema(companySchema, response.data);

    return company;
};
