import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { callSchema } from "../domain/call";

export const getCalls = async ({ authToken, filters }: { authToken: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: "/calls",
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });

    const dtos = parseSchema(z.array(callSchema), response.data);

    const calls = parseSchema(
        z.array(callSchema),
        dtos.map((dto) => ({ ...dto })),
    );

    return calls;
};
