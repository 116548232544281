import { useMutation } from "@tanstack/react-query";
import ButtonNeoGen from "../../../layout/button-neogen";
import ModalDialog from "../../../layout/modal-dialog";
import { deleteAlertRule } from "../actions/delete-alert-rule";
import { getAuthTokenNoThrow } from "../../../services/auth-header";

export const DeleteAlertRuleModal = ({ alertRuleId, onClose }: { alertRuleId: number; onClose: () => void }) => {
    const authToken = getAuthTokenNoThrow() || "no-token";

    const deleteMutation = useMutation({
        mutationFn: async () => {
            return await deleteAlertRule({ authToken, id: alertRuleId });
        },
    });

    const handleDelete = async () => {
        await deleteMutation.mutateAsync();
        onClose();
    };

    return (
        <ModalDialog title="Delete Alert" close={onClose} size="xs" show={true} showOk={false} showCancel={false}>
            <div className="text-center">
                <p>Are you sure you want to delete this alert?</p>
                <div className="flex justify-center gap-3 my-3">
                    <ButtonNeoGen className="w-full" type="outline" onClick={onClose}>
                        Cancel
                    </ButtonNeoGen>
                    <ButtonNeoGen className="w-full" type="danger" onClick={handleDelete}>
                        Yes
                    </ButtonNeoGen>
                </div>
            </div>
        </ModalDialog>
    );
};
