import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { TextField } from "../../../layout/form/text-field";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { Company, CompanyStatus } from "../../domain/company";
import { SelectField } from "../../../layout/form/select-field";
import { useMemo } from "react";
import { User } from "../../../jason-proof-of-concept/users/domain/user";
import { sortUsers } from "../../../sections/utilities/sortUsers";
import { useMutation } from "@tanstack/react-query";
import { useSetupCompany } from "../../hooks/use-setup-company";
import { RoleGroup } from "../../../role-groups/domain/role-group";
import { useUpdateCompany } from "../../hooks/use-update-company";
import { startCase } from "lodash";

const schema = z.object({
    status: z.nativeEnum(CompanyStatus),
});

type Data = z.infer<typeof schema>;

export const EditCompanyStatusModal = ({
    onClose,
    company,
    onCompanyUpdated: onCompanyUpdated,
}: {
    onClose: () => any;
    company: Company;
    onCompanyUpdated: (company: Company) => void;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const form = useForm({
        schema,
        defaultValues: { status: company.status },
    });

    const updateCompanyMutation = useUpdateCompany();

    const submitMutation = useMutation({
        mutationFn: async (data: Data) => {
            const updatedCompany = await updateCompanyMutation.mutateAsync({
                authToken,
                id: company.id,
                data: {
                    status: data.status,
                    statusUpdatedAt: new Date(),
                },
            });
            return updatedCompany;
        },
    });

    const handleSubmit = async (data: Data) => {
        const company = await submitMutation.mutateAsync(data);
        onCompanyUpdated(company);
    };

    return (
        <>
            <ModalDialog show title={"Edit Company Status"} close={onClose} showOk={false} showCancel={false} size="xs">
                <Form onSubmit={form.handleSubmit(handleSubmit as any)} error={updateCompanyMutation.error as any}>
                    <SelectField
                        label="Status"
                        {...form.getFieldProps("status")}
                        options={Object.values(CompanyStatus).map((status) => {
                            return { value: status, label: startCase(status) };
                        })}
                    />
                    <div className="flex justify-end gap-4">
                        <ButtonNeoGen
                            type="outline"
                            disabled={updateCompanyMutation.isLoading}
                            onClick={() => onClose()}
                        >
                            Cancel
                        </ButtonNeoGen>
                        <ButtonNeoGen type="submit" disabled={updateCompanyMutation.isLoading}>
                            Update Status
                        </ButtonNeoGen>
                    </div>
                </Form>
            </ModalDialog>
        </>
    );
};
