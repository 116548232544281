import { useMutation } from "@tanstack/react-query";
import { UpdatePaymentRequestData, updatePaymentRequest } from "../actions/update-payment-request";

export const useUpdatePaymentRequest = () => {
    const mutation = useMutation({
        mutationFn: async ({
            authToken,
            id,
            data,
        }: {
            authToken: string;
            id: string;
            data: UpdatePaymentRequestData;
        }) => {
            const paymentRequest = await updatePaymentRequest({ authToken, id, data });
            return paymentRequest;
        },
    });
    return mutation;
};
