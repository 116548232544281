import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { getMagicLinkByCode } from "../actions/get-magic-link-by-code";
import { MagicLinkTypes } from "../domain/magic-link";
import { ClientMagicLinkSignUpForm } from "../components/client-magic-link-sign-up-form";
import { AffiliateMagicLinkSignUpForm } from "../components/affiliate-magic-link-sign-up-form";
import Loader2 from "../../sections/utilities/Loader2";
import { UserInviteForm } from "../components/user-invite-form";

export type MagicLinkDataType = {
    code: string;
    id: string;
    createdById: string;
    type: MagicLinkTypes;
    data: Record<string, any>;
    usedAt?: Date | null | undefined;
    options?:
        | {
              affiliateUserId?: string | undefined;
          }
        | null
        | undefined;
};

export const MagicLinkPage = () => {
    const authToken = getAuthTokenNoThrow() || "no-token";
    const { code } = useParams();

    const query = useQuery(["magic-link"], async () => {
        const magicLink = code ? await getMagicLinkByCode({ authToken, code }) : undefined;
        return magicLink;
    });
    const magicLink: MagicLinkDataType | undefined = query.data;

    return (
        <div>
            {!magicLink && !query.error && (
                <div>
                    <Loader2 />
                </div>
            )}
            {magicLink && code && magicLink.type === MagicLinkTypes.affiliateSignUp && (
                <AffiliateMagicLinkSignUpForm magicLink={magicLink} code={code} />
            )}
            {magicLink && code && magicLink.type === MagicLinkTypes.clientSignUp && (
                <ClientMagicLinkSignUpForm magicLink={magicLink} code={code} />
            )}
            {magicLink && code && magicLink.type === MagicLinkTypes.userInvite && (
                <UserInviteForm magicLink={magicLink} code={code} />
            )}
        </div>
    );
};
