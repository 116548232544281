import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { getPayments } from "../actions/get-payments";
import { Payment } from "../domain/payment";

type Filters = { where?: any };

const getPaymentsQueryKey = ({ authToken, filters }: { authToken: string; filters?: Filters }) => {
    return ["payments", { authToken, filters }];
};

export const usePayments = (
    { authToken, filters }: { authToken: string; filters?: Filters },
    options?: UseQueryOptions<Payment[]>,
) => {
    const query = useQuery({
        queryKey: getPaymentsQueryKey({ authToken, filters }),
        queryFn: async () => {
            const payments = await getPayments({ authToken, filters });
            return payments;
        },
        ...options,
    });
    return query;
};
