import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { getUsers } from "../actions/get-users";
import { User } from "../domain/user";

type Filters = { where: any };

const getUsersQueryKey = ({ authToken, filters }: { authToken: string; filters?: Filters }) => {
    return ["users", { authToken, filters }];
};

export const useUsers = (
    { authToken, filters }: { authToken: string; filters?: Filters },
    options?: UseQueryOptions<User[]>,
) => {
    const query = useQuery({
        queryKey: getUsersQueryKey({ authToken, filters }),
        queryFn: async () => {
            const users = await getUsers({ authToken, filters });
            return users;
        },
        ...options,
    });
    return query;
};
