import { useMutation } from "@tanstack/react-query";
import { UpdateUserCompanyData, updateUserCompany } from "../actions/update-user-company";

export const useUpdateUserCompany = () => {
    const mutation = useMutation({
        mutationFn: async ({ authToken, id, data }: { authToken: string; id: number; data: UpdateUserCompanyData }) => {
            const userCompany = await updateUserCompany({ authToken, id, data });
            return userCompany;
        },
    });
    return mutation;
};
