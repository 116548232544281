import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { AlertUserSettings } from "../domain/alert-user-settings";
import { alertUserSettingsSchema } from "./get-alert-user-settings";

export const updateAlertUserSettings = async ({
    authToken,
    data,
    id,
}: {
    authToken?: string;
    data: Partial<AlertUserSettings>;
    id: number;
}) => {
    const response = await makeRequest({
        method: "patch",
        path: "/alert-user-settings",
        queryParams: { id },
        authToken,
        data,
    });

    const alertUserSettings = parseSchema(alertUserSettingsSchema, response.data);

    return alertUserSettings;
};
