import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { CompanyReviewUser } from "../domain/company-review-user";

export const companyReviewUserSchema = z.object({
    id: z.number().nullish(),
    userId: z.string().nullish(),
});

export const createCompanyReviewUsers = async ({
    authToken,
    data,
}: {
    authToken?: string;
    data: Partial<CompanyReviewUser[]>;
}) => {
    const response = await makeRequest({
        method: "post",
        path: "/company-review-users",
        authToken,
        data,
    });

    const companyReviewUsers = parseSchema(z.array(companyReviewUserSchema), response.data);

    return companyReviewUsers;
};
