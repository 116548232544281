import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { paymentSchema } from "../domain/payment";

const createPaymentSchema = paymentSchema.omit({ createdAt: true, updatedAt: true }).partial({ id: true });

export type CreatePaymentData = z.infer<typeof createPaymentSchema>;

export const createPayment = async ({ authToken, data }: { authToken: string; data: CreatePaymentData }) => {
    const response = await makeRequest({
        method: "post",
        path: "/payments",
        authToken,
        data,
    });

    const payment = parseSchema(paymentSchema, response.data);
    return payment;
};
