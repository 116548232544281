import { useEffect } from "react";
import SignInForm from "./sign-in-form";

export default function Login() {
    useEffect(() => {
        if (localStorage.getItem("theme") !== null) {
            if (localStorage.getItem("theme") === "dark") {
                document.body.classList.add("dark-theme");
                document.body.classList.add("dark");
            } else {
                document.body.classList.remove("dark-theme");
                document.body.classList.remove("dark");
            }
        } else {
            if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
                document.body.classList.add("dark-theme");
                document.body.classList.add("dark");
                localStorage.setItem("theme", "dark");
            } else {
                document.body.classList.remove("dark-theme");
                document.body.classList.remove("dark");
                localStorage.setItem("theme", "light");
            }
        }
    }, []);

    return (
        <div className="bg-slate-300 dark:bg-slate-600 h-screen m-0 p-0 min-h-screen">
            <SignInForm />
        </div>
    );
}
