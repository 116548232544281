import { useQuery } from "@tanstack/react-query";
import { getCalls } from "../actions/get-calls";

type Params = {
    authToken: string;
    filters?: any;
};

const getCallsQueryKey = ({ authToken, filters }: Params) => {
    return ["calls", { authToken, filters }];
};

export const useCalls = ({ authToken, filters }: Params) => {
    const query = useQuery({
        queryKey: getCallsQueryKey({ authToken, filters }),
        queryFn: async () => {
            const calls = await getCalls({ authToken, filters });
            return calls;
        },
    });
    return query;
};
