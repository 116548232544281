import { useQuery } from "@tanstack/react-query";
import { getCalendarEvents } from "../actions/get-calendar-events";

type Params = {
    authToken: string;
    filters?: any;
};

const getCalendarEventsQueryKey = ({ authToken, filters }: Params) => {
    return ["calendarEvents", { authToken, filters }];
};

export const useCalendarEvents = ({ authToken, filters }: Params) => {
    const query = useQuery({
        queryKey: getCalendarEventsQueryKey({ authToken, filters }),
        queryFn: async () => {
            const calendarEvents = await getCalendarEvents({ authToken, filters });
            return calendarEvents;
        },
    });
    return query;
};
