import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { CalendarEvent, calendarEventSchema } from "../domain/calendar-event";

export const updateCalendarEvent = async ({
    authToken,
    id,
    data,
}: {
    authToken: string;
    id: number;
    data: Partial<CalendarEvent>;
}) => {
    const response = await makeRequest({
        method: "patch",
        path: `/calendar-events/${id}`,
        authToken,
        data,
    });

    const calendarEvent = parseSchema(calendarEventSchema, response.data);

    return calendarEvent;
};
