import { Theme } from "searchpal";

const theme = new Theme({
    shadow: "0 5px 20px rgba(0, 0, 0, .5)",
    light: { accent: "orange" },
    dark: { accent: "red" },
});

theme.light.option.selected("orange", "#fff");
theme.dark.option.selected("red", "#fff");

// Global
theme.bg("#d3d3d3");
// Light & Dark
theme.light.bg("white");
theme.dark.bg("#000080");

// Global
theme.text("rgb(60,60,60)", "rgb(200,200,200)");
// Light & Dark
theme.light.text("black", "grey");
theme.dark.text("white", "#d3d3d3");

// Global
theme.accent("lightblue", "darkblue");
// Light & Dark
theme.light.accent("#00165a", "#fff");
theme.dark.accent("red", "white");

theme.border("#000", 2); // 2px
// Light & Dark
theme.light.border("grey", ".2rem");
theme.dark.border("transparent", 0);

// Global
theme.shadow("0px 10px 10px rgb(150,150,150,.4)");
// Light & Dark
theme.light.shadow("0px 10px 10px 5px rgb(200,200,200,.5)");
theme.dark.shadow("0px 10px 10px 5px rgb(0,0,0,.6)");

// Global
theme.backdrop("black", "30%");
// Light & Dark
theme.light.backdrop("#d3d3d3", ".6");
theme.dark.backdrop("#00165a", "55%");

// Global
theme.option("#d3d3d3", "darkblue");
// Light & Dark
theme.light.option("white", "#000");
theme.dark.option("transparent", "#fff");

// Global
theme.option.selected("blue", "lightblue");
// Light & Dark
theme.light.option.selected("#000", "#fff");
theme.dark.option.selected("#fff", "#000");

// Global
theme.set({
    accent: "#3b82f6",
    accentText: "#fff",
    borderWidth: "1px",
});
// Light & Dark
theme.light.set({
    background: "#fff",
    text: "#27272a",
    textSecondary: "#929294",
    borderColor: "#f2f3f6",
    shadow: "0 25px 50px -12px rgba(156,163,175,.2)",
    backdrop: "#e5e7eb",
    backdropOpacity: ".65",
    optionBackground: "transparent",
    optionText: "#27272a",
    optionSelectedBackground: "#f4f4f5",
    optionSelectedText: "#27272a",
});
theme.dark.set({
    background: "#1f2937",
    text: "#fff",
    textSecondary: "#8e939a",
    borderColor: "#393939",
    shadow: "0 25px 50px -12px rgba(31,41,55,.8)",
    backdrop: "#111827",
    backdropOpacity: ".65",
    optionBackground: "transparent",
    optionText: "#fff",
    optionSelectedBackground: "#4b5563",
    optionSelectedText: "#fff",
});

export default theme;
