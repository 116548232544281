import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { userCompanySchema } from "../domain/user-company";

export const getUserCompany = async ({ authToken, id }: { authToken: string; id: number }) => {
    const response = await makeRequest({
        method: "get",
        path: `/user-companies/${id}`,
        authToken,
    });

    const userCompany = parseSchema(userCompanySchema, response.data);

    return userCompany;
};
