import { useQuery } from "@tanstack/react-query";
import { getCompanies } from "../actions/get-companies";

type Params = {
    authToken: string;
    filters?: any;
};

const getCompaniesQueryKey = ({ authToken, filters }: Params) => {
    return ["companies", { authToken, filters }];
};

export const useCompanies = ({ authToken, filters }: Params) => {
    const query = useQuery({
        queryKey: getCompaniesQueryKey({ authToken, filters }),
        queryFn: async () => {
            const companies = await getCompanies({ authToken, filters });
            return companies;
        },
    });
    return query;
};
