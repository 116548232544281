import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import Feed from "../../../layout/feed";
import userService from "../../../services/user.service";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ButtonNeoGen from "../../../layout/button-neogen";
import { NavLink } from "react-router-dom";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import { getProcessflowProgresses } from "../../../jason-proof-of-concept/processflow-progresses/actions/get-processflow-progresses";
import { getDataFromProgressData } from "../../../jason-proof-of-concept/wizard/wizard-container";
import { getEstimatedPayoutFromData } from "../../../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { Currency } from "../../../billing/components";
import TableNeogen from "../../../layout/table-neogen";
import { getCalendarEvents } from "../../../calendar-events/actions/get-calendar-events";
import { useRecoilState } from "recoil";
import userAtom from "../../../atoms/userAtom";
import { updateCalendarEvent } from "../../../calendar-events/actions/update-calendar-event";
import { CalendarEvent, calendarEventStatusEnums } from "../../../calendar-events/domain/calendar-event";
import { z } from "zod";
import { AddCalendarEventModal } from "../../../calendar-events/components/add-calendar-event-modal";
import { EditCalendarEventModal } from "../../../calendar-events/components/edit-calendar-event-modal";

export default function BasicCard({ user, setUser }: { user?: ClearERCUser; setUser: (user: ClearERCUser) => void }) {
    const userId = user?.id;
    const [signedInUser] = useRecoilState(userAtom);
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const [phoneNumber, setPhoneNumber] = useState<string>(user?.phone ?? "Unknown");
    const [editingPhoneNumber, setEditingPhoneNumber] = useState<boolean>(false);
    const cache = useQueryClient();
    const [showCallingModal, setShowCallingModal] = useState<boolean>(false);
    const [calendarEventToEdit, setCalendarEventToEdit] = useState<CalendarEvent | undefined>();

    const calendarEventsQuery = useQuery(["calendar-invites", { userId }], async () => {
        const calendarEvents = userId ? await getCalendarEvents({ authToken, filters: { where: { userId } } }) : [];
        return calendarEvents;
    });
    const calendarEvents =
        calendarEventsQuery.data?.filter((event) => event.status === calendarEventStatusEnums.upcoming) || [];

    const schema = z.object({
        status: z.string(),
    });

    type Data = z.infer<typeof schema>;

    const updateCalendarEventMutation = useMutation({
        mutationFn: async ({ calendarEvent, data }: { calendarEvent: CalendarEvent | undefined; data: Data }) => {
            if (calendarEvent) {
                const updatedEvent = await updateCalendarEvent({ authToken, id: calendarEvent.id, data });
                return updatedEvent;
            }
        },
    });

    useEffect(() => {
        if (
            user?.phone !== phoneNumber &&
            user?.phone !== undefined &&
            phoneNumber !== undefined &&
            phoneNumber !== "" &&
            phoneNumber !== "Unknown"
        ) {
            console.log("phone number changed from " + user?.phone + " to " + phoneNumber);
            if (user.id) {
                if (!user.realm) {
                    user.realm = "clear-erc";
                }
                user.phone = phoneNumber;
                userService.update(user.id ?? "asdasd", user).then((res) => {
                    console.log(res);
                });
            }
            console.error(1);
            setUser(user);
            setPhoneNumber(user?.phone ?? "");
            cache.invalidateQueries(["user", user?.id]);

            // setEditingPhoneNumber(false);
        } else if (user?.phone && phoneNumber === "Unknown") {
            setPhoneNumber(user.phone);
        }
    }, [cache, phoneNumber, setUser, user, user?.phone]);

    const query = useQuery(["user-summary-data", userId, authToken], async () => {
        const group = 7;
        const [usersProgress] = await getProcessflowProgresses({
            authToken,
            filters: { userId, group },
        });
        const data = getDataFromProgressData(usersProgress?.data as any);
        const w22020 = data["Average full time W2 Employees in 2020?"];
        const w22021 = data["Average full time W2 Employees in 2021?"];
        const estimatedAmount = getEstimatedPayoutFromData({ data });
        return { estimatedAmount, w22020, w22021 };
    });
    const estimatedAmount = query?.data?.estimatedAmount;
    const w22020 = query?.data?.w22020;
    const w22021 = query?.data?.w22021;

    return (
        <>
            <div
                style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
            >
                <h1 className="text-3xl font-bold tracking-tight text-blue-gray-900">Basic Information</h1>
                {user && (
                    <NavLink
                        to={`/users/${user.id}/application`}
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <span className="fa fa-folder-open mr-3"></span>
                        Open Application
                    </NavLink>
                )}
            </div>
            <form className="divide-y-gray-200 mt-6 space-y-8 divide-y dark:divide-y-gray-900">
                <div className="mt-5 border-t border-gray-200 dark:border-gray-900">
                    <dl className="divide-y divide-gray-200 dark:border-gray-900 dark:divide-gray-900">
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">Company Name</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-300">
                                <span className="flex-grow">{user?.name}</span>
                                <span className="ml-4 flex-shrink-0">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-gray-800"
                                    >
                                        Update
                                    </button>
                                </span>
                            </dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">Contact name</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-300">
                                <span className="flex-grow">
                                    {(user?.firstName ?? "") + " " + (user?.lastName ?? "")}
                                </span>
                                <span className="ml-4 flex-shrink-0">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-gray-800"
                                    >
                                        Update
                                    </button>
                                </span>
                            </dd>
                        </div>

                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">Email address</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-300">
                                <span className="flex-grow">
                                    <a href={"mailto:" + user?.email}>{user?.email}</a>
                                </span>
                                <span className="ml-4 flex-shrink-0">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-gray-800"
                                    >
                                        Update
                                    </button>
                                </span>
                            </dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 items-center">
                            <dt className="text-sm font-medium text-gray-500">Phone Number</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-300 items-center h-6">
                                <span className="flex-grow mt-0">
                                    {editingPhoneNumber ? (
                                        <PhoneInput
                                            inputClass="w-full relative rounded-md mt-1"
                                            placeholder="Enter phone number"
                                            value={phoneNumber}
                                            country={"us"}
                                            onChange={(e164) => setPhoneNumber((e164 ?? "")?.toString())}
                                        />
                                    ) : (
                                        <ButtonNeoGen
                                            type="info"
                                            onClick={() => {
                                                setEditingPhoneNumber((c) => !c);
                                            }}
                                            icon={"fal fa-edit"}
                                        >
                                            Edit {user?.phone}
                                        </ButtonNeoGen>
                                    )}
                                </span>
                                <span className="ml-4 flex-shrink-0  mt-0">
                                    {editingPhoneNumber ? (
                                        <div className="self-top my-0">
                                            <ButtonNeoGen
                                                onClick={() => {
                                                    setEditingPhoneNumber((c) => !c);
                                                }}
                                            >
                                                Done
                                            </ButtonNeoGen>
                                        </div>
                                    ) : (
                                        <ButtonNeoGen
                                            className="!bg-orange-400"
                                            onClick={() => {
                                                setShowCallingModal(true);
                                            }}
                                            icon={"fal fa-phone"}
                                        >
                                            Call Number
                                        </ButtonNeoGen>
                                    )}
                                </span>
                            </dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">W2 Employees 2020</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-300">
                                <span className="flex-grow">{w22020 || "-"}</span>
                            </dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">W2 Employees 2021</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-300">
                                <span className="flex-grow">{w22021 || "-"}</span>
                            </dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">Estimated deal value</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-300">
                                <span className="flex-grow">
                                    {estimatedAmount ? <Currency amount={estimatedAmount} /> : "-"}
                                </span>
                            </dd>
                        </div>
                    </dl>
                    <Feed />
                    <div className="py-4 sm:py-5">
                        <dt className="text-sm font-medium text-gray-500 mb-3">Upcoming calendar events</dt>
                        {calendarEventsQuery.isLoading ? (
                            <p className="text-sm">Loading...</p>
                        ) : calendarEvents.length > 0 ? (
                            <TableNeogen
                                entries={calendarEvents
                                    .filter((event) => event.status === calendarEventStatusEnums.upcoming)
                                    .map((event) => ({
                                        id: event.id,
                                        date: event.date,
                                        engagementType: event.engagementType,
                                        assignee: `${event.assignee?.firstName} ${event.assignee?.lastName}`,
                                        title: event.title,
                                        description: event.description,
                                    }))}
                                // ignoreFields={[]}
                                headers={["Date", "Engagement Type", "Assignee", "Title", "Description"]}
                                formatters={[
                                    {
                                        field: "date",
                                        type: "DateTime",
                                    },
                                ]}
                                actionsAreDropDown={true}
                                actions={[
                                    {
                                        label: "Edit",
                                        icon: "fa fa-pencil-square",
                                        onClick: (calendarEventId: number) => {
                                            const calendarEvent = calendarEvents.find((c) => c.id === calendarEventId);
                                            console.log({ calendarEventId, calendarEvent });
                                            if (calendarEvent) {
                                                setCalendarEventToEdit(calendarEvent);
                                            }
                                        },
                                    },
                                    {
                                        label: "Mark as complete",
                                        onClick: async (entryId) => {
                                            await updateCalendarEventMutation.mutateAsync({
                                                calendarEvent: calendarEvents.find((event) => event.id === entryId),
                                                data: { status: calendarEventStatusEnums.completed },
                                            });
                                            calendarEventsQuery.refetch();
                                        },
                                        icon: "fal fa-check",
                                    },
                                    {
                                        label: "Cancel",
                                        onClick: async (entryId) => {
                                            await updateCalendarEventMutation.mutateAsync({
                                                calendarEvent: calendarEvents.find((event) => event.id === entryId),
                                                data: { status: calendarEventStatusEnums.cancelled },
                                            });
                                            calendarEventsQuery.refetch();
                                        },
                                        icon: "fad fa-trash !text-red-500 ",
                                    },
                                ]}
                            />
                        ) : (
                            <p className="text-sm">No upcoming calendar events</p>
                        )}
                    </div>
                    {calendarEventToEdit && (
                        <EditCalendarEventModal
                            calendarEvent={calendarEventToEdit}
                            onClose={() => setCalendarEventToEdit(undefined)}
                            onUpdate={() => {
                                calendarEventsQuery.refetch();
                                setCalendarEventToEdit(undefined);
                            }}
                        />
                    )}
                    {showCallingModal && user && (
                        // <CustomerCallModal user={user} onClose={() => setShowCallingModal(false)} />
                        <AddCalendarEventModal
                            user={user}
                            onClose={() => {
                                calendarEventsQuery.refetch();
                                setShowCallingModal(false);
                            }}
                        />
                    )}
                </div>
            </form>
        </>
    );
}
