import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { interactionSchema } from "../domain/interaction";

export const getInteractions = async ({ authToken, filters }: { authToken: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: "/interactions",
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });

    const interactions = parseSchema(z.array(interactionSchema), response.data);

    return interactions;
};
