import { useState, useMemo } from "react";
import PageDescription from "../../layout/page-description";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { getAuditLogs } from "../audit-logs/actions/get-audit-logs";
import { Tab } from "@headlessui/react";
import { classNames } from "../../billing/utils";
import ButtonNeoGen from "../../layout/button-neogen";
import { usePromise, usePromiseLazy } from "../../jason-proof-of-concept/shared/hooks";
import { AuditLogsTable } from "../audit-logs/components/audit-log-table";
import { AlertsTable } from "./components/alerts-table";
import { getAlertRules } from "./actions/get-alert-rules";
import { AlertRule } from "./domain/alert-rule";
import { getAlertTypes } from "./actions/get-alert-types";
import { AlertType } from "./domain/alert-type";
import { getAlertRuleChannels } from "./actions/get-alert-rule-channels";
import { AlertRuleChannel } from "./domain/alert-rule-channel";
import { CreateAlertRuleModal } from "./components/create-alert-rule-modal";
import { DeleteAlertRuleModal } from "./components/delete-alert-rule-modal";
import { UpdateAlertRuleModal } from "./components/update-alert-rule-modal";
import { useQuery } from "@tanstack/react-query";
import { getUsers } from "../../jason-proof-of-concept/users/actions/get-users";
import { sortUsers } from "../utilities/sortUsers";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import roleGroupService, { roleAssignments } from "../../services/role-group.service";
import authService from "../../services/auth.service";

export const AlertsPage = () => {
    const [showCreateAlertRulesModal, setShowCreateAlertRuleModal] = useState(false);
    const [alertRuleIdToDelete, setAlertRuleIdToDelete] = useState<number | undefined>();
    const [alertRuleIdToUpdate, setAlertRuleIdToUpdate] = useState<number | undefined>();
    const [activeTab, setActiveTab] = useState<string>("Alerts");

    const authToken = getAuthTokenNoThrow() || "no-token";

    const auditLogsQuery = usePromiseLazy(async () => {
        const auditLogs = await getAuditLogs({ authToken });
        return auditLogs;
    }, []);

    const alertTypesQuery = usePromise(async () => {
        const alertTypes = await getAlertTypes({ authToken });
        return alertTypes;
    }, []);

    const alertRulesQuery = usePromise(async () => {
        const alertRules = await getAlertRules({ authToken });
        return alertRules;
    }, []);

    const alertRuleChannelsQuery = usePromise(async () => {
        const alertRuleChannels = await getAlertRuleChannels({ authToken });
        return alertRuleChannels;
    }, []);

    const alertTypesData: AlertType[] | undefined = alertTypesQuery.result || [];
    const alertRulesData: AlertRule[] | undefined = alertRulesQuery.result || [];
    const alertRuleChannelsData: AlertRuleChannel[] | undefined = alertRuleChannelsQuery.result || [];

    const alertRuleToUpdate = alertRulesData.find((alertRule) => alertRule.id === alertRuleIdToUpdate);
    const alertRuleChannelsToUpdate = alertRuleChannelsData.filter(
        (channel) => channel.alertRuleId === alertRuleIdToUpdate,
    );

    const usersQuery = useQuery(["users"], async () => {
        const response = await getUsers({ authToken });
        return response || [];
    });
    const users = useMemo(() => sortUsers((usersQuery.data as ClearERCUser[]) || []), [usersQuery.data]);

    const roleGroupsQuery = useQuery(["roleGroups"], async () => {
        const response = await roleGroupService.getAll();
        return response?.data || [];
    });
    const allRoleGroups = roleGroupsQuery.data || [];
    const usersRoleGroups = (authService.getCurrentUser()?.user?.roleGroups || []) as any[];
    const roleGroupsIds = usersRoleGroups.reduce<number[]>((acc, roleGroup: any) => {
        return [...acc, ...(roleAssignments?.[roleGroup.id] || [])];
    }, []);
    const finalRoleGroups = allRoleGroups.filter((rg) => roleGroupsIds.includes(rg.id || 9999));

    return (
        <>
            <PageDescription title="Notifications" description="Set up custom notifications on ClearERC" />
            <div className="flex justify-between items-start w-full">
                <div className="w-full max-w-md">
                    <Tab.Group>
                        <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
                            {["Notification config", "Audit Logs"].map((tab) => (
                                <Tab
                                    key={tab}
                                    onClick={() => setActiveTab(tab)}
                                    className={({ selected }) =>
                                        classNames(
                                            "w-full rounded-lg py-2 text-sm font-medium leading-5 text-gray-500",
                                            "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none",
                                            selected
                                                ? "bg-white shadow"
                                                : "text-blue-100 hover:bg-white/[0.12] hover:text-white",
                                        )
                                    }
                                >
                                    {tab}
                                </Tab>
                            ))}
                        </Tab.List>
                    </Tab.Group>
                </div>

                <div className="max-w-xs">
                    {activeTab === "Audit Logs" ? (
                        <ButtonNeoGen
                            text="Refresh logs"
                            disabled={auditLogsQuery.isLoading}
                            onClick={() => auditLogsQuery.execute()}
                            icon="fas fa-bell"
                        />
                    ) : (
                        <ButtonNeoGen
                            text="Add notification type"
                            onClick={() => setShowCreateAlertRuleModal(true)}
                            icon="fas fa-bell"
                        />
                    )}
                </div>
            </div>
            {activeTab === "Audit Logs" ? (
                <AuditLogsTable auditLogsData={auditLogsQuery.result} />
            ) : (
                <AlertsTable
                    alertRulesData={alertRulesData}
                    alertRuleChannelsData={alertRuleChannelsData}
                    alertTypesData={alertTypesData}
                    setAlertRuleToDelete={setAlertRuleIdToDelete}
                    setAlertRuleToUpdate={setAlertRuleIdToUpdate}
                />
            )}
            {showCreateAlertRulesModal && (
                <CreateAlertRuleModal
                    onClose={() => {
                        setShowCreateAlertRuleModal(false);
                        alertRulesQuery.execute();
                        alertRuleChannelsQuery.execute();
                    }}
                    alertTypes={alertTypesData}
                    users={users}
                    roleGroups={finalRoleGroups}
                />
            )}

            {alertRuleIdToDelete && (
                <DeleteAlertRuleModal
                    alertRuleId={alertRuleIdToDelete}
                    onClose={() => {
                        setAlertRuleIdToDelete(undefined);
                        alertRulesQuery.execute();
                        alertRuleChannelsQuery.execute();
                    }}
                />
            )}

            {alertRuleToUpdate && (
                <UpdateAlertRuleModal
                    onClose={() => {
                        setAlertRuleIdToUpdate(undefined);
                        alertRulesQuery.execute();
                        alertRuleChannelsQuery.execute();
                    }}
                    alertTypes={alertTypesData}
                    defaultValues={{
                        id: alertRuleIdToUpdate,
                        alertTypeId: alertRuleToUpdate?.alertTypeId,
                        title: alertRuleToUpdate?.title,
                        body: alertRuleToUpdate?.body,
                        channels: alertRuleChannelsToUpdate.map((channel) => ({
                            id: channel.id,
                            alertRuleId: channel.alertRuleId,
                            recipients: channel.recipients,
                            channel: channel.channel,
                            channelId: channel.channelId,
                            link: channel.link,
                        })),
                    }}
                    users={users}
                    roleGroups={finalRoleGroups}
                />
            )}
        </>
    );
};
