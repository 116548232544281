import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CogIcon, XMarkIcon } from "@heroicons/react/24/outline";
import NewMessage from "../messages/modals/new-message";
import userAtom from "../../atoms/userAtom";
import { useRecoilState } from "recoil";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { formatDistanceToNow } from "date-fns";
import ButtonNeoGen from "../../layout/button-neogen";
import { useMutation } from "@tanstack/react-query";
import SwitchNeoGen from "../../layout/switch-neogen";
import { usePromise } from "../../jason-proof-of-concept/shared/hooks";
import { getAlertUserSettings } from "../../alert-user-settings/actions/get-alert-user-settings";
import { Alert, AlertStatus } from "../../alerts/domain/alert";
import { AlertUserSettings } from "../../alert-user-settings/domain/alert-user-settings";
import { updateAlertUserSettings } from "../../alert-user-settings/actions/update-alert-user-settings";
import { updateAlerts } from "../../alerts/actions/update-alerts";
import { getAlertRules } from "../alerts/actions/get-alert-rules";

export default function MessagesOverlay({
    show,
    setShow,
    alertsQuery,
}: {
    show: boolean;
    setShow: (show: boolean) => void;
    alertsQuery: any;
}) {
    const [open, setOpen] = useState(false);
    const [user] = useRecoilState(userAtom);
    const authToken = getAuthTokenNoThrow() || "no-auth";
    const [showSettings, setShowSettings] = useState(false);
    const [alertsConfig, setAlertsConfig] = useState<Record<string, any>[]>([]);

    const appAlerts = alertsQuery.result;

    const alertRulesQuery = usePromise(async () => {
        const alertRules = await getAlertRules({ authToken });
        return alertRules;
    }, []);

    const alertUserSettingsQuery = usePromise(async () => {
        if (!user || !user.id) {
            throw new Error("User not found");
            return;
        }
        const alertUserSettings = await getAlertUserSettings({
            authToken,
            filter: { where: { userId: user.id } },
        });

        if (alertUserSettings) {
            setAlertsConfig(
                alertUserSettings.map((a) => ({
                    id: a.id,
                    alertRuleId: a.alertRuleId,
                    appEnabled: a.appEnabled,
                    emailEnabled: a.emailEnabled,
                })),
            );
        }

        return alertUserSettings;
    }, []);

    useEffect(() => {
        const alertRules = alertRulesQuery.result;
        setAlertsConfig(
            (alertsConfig || [])?.map((config) => ({
                ...config,
                alertRuleTitle: alertRules?.find((rule) => rule.id === config.alertRuleId)?.title,
            })),
        );
    }, [alertRulesQuery.result, alertUserSettingsQuery.result, alertsConfig]);

    const updateAlertMutation = useMutation({
        mutationFn: async ({ authToken, id, data }: { authToken?: string; id: number; data: Partial<Alert> }) => {
            return await updateAlerts({ authToken, id, data });
        },
    });

    const handleUpdate = async (status: AlertStatus) => {
        if (appAlerts?.length === 0) return;

        appAlerts.map(async (alert: Alert) => {
            await updateAlertMutation.mutateAsync({
                authToken,
                id: alert.id,
                data: {
                    status: status,
                },
            });
        });

        alertsQuery.execute();
    };

    const updateAlertUserSettingsMutation = useMutation({
        mutationFn: async ({
            authToken,
            id,
            data,
        }: {
            authToken?: string;
            id: number;
            data: Partial<AlertUserSettings>;
        }) => {
            return await updateAlertUserSettings({ authToken, id, data });
        },
    });

    type DataType = {
        appEnabled: boolean;
        emailEnabled?: boolean;
    };

    const handleUpdateSettings = async (ruleId: number) => {
        if (!ruleId) {
            throw new Error("Alert Rule Id not found");
        }
        const updatedSettings = alertsConfig.find((config) => config.alertRuleId === ruleId);

        if (updatedSettings) {
            const data: DataType = {
                appEnabled: updatedSettings.appEnabled,
            };
            if (updatedSettings.emailEnabled !== null) {
                data.emailEnabled = updatedSettings.emailEnabled;
            }

            await updateAlertUserSettingsMutation.mutateAsync({
                authToken,
                id: updatedSettings.id,
                data,
            });
        }
    };

    const setEnabled = (ruleId: number, field: string) => {
        const updatedConfig = (alertsConfig || [])?.map((config) => {
            if (config.alertRuleId === ruleId) {
                handleUpdateSettings(ruleId);
                return {
                    ...config,
                    [field]: !config[field],
                };
            }
            return config;
        });
        setAlertsConfig(updatedConfig);
    };

    return (
        <>
            <Transition.Root show={show} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => setShow(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                        <div className="flex h-full flex-col overflow-y-auto bg-white dark:bg-gray-800 py-6 shadow-xl">
                                            <div className="px-4 sm:px-6">
                                                <div className="flex items-start justify-between">
                                                    <Dialog.Title className="text-lg font-medium text-gray-900 dark:text-gray-400 flex items-center gap-1">
                                                        Notifications
                                                        <button
                                                            onClick={() => {
                                                                setShowSettings(!showSettings);
                                                                alertUserSettingsQuery.execute();
                                                            }}
                                                            className="p-1 rounded-full hover:cursor-pointer"
                                                        >
                                                            <CogIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </Dialog.Title>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className="rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                            onClick={() => setShow(false)}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="relative mt-6 flex-1 px-4 sm:px-6 overflow-y-auto">
                                                {showSettings ? (
                                                    alertsConfig.length > 0 ? (
                                                        <div>
                                                            <div className="flex justify-between mb-3 border-b-[1px] pb-2 border-b-gray-400 font-medium">
                                                                <p>Notification Type</p>
                                                                <div className="flex gap-7">
                                                                    <p>App</p>
                                                                    <p>Email</p>
                                                                </div>
                                                            </div>
                                                            {(alertsConfig || [])?.map((config) => {
                                                                if (!config.alertRuleTitle) return;
                                                                return (
                                                                    <div
                                                                        key={config.alertRuleId}
                                                                        className="flex justify-between py-1"
                                                                    >
                                                                        {config.alertRuleTitle}
                                                                        <div
                                                                            className={`flex ${
                                                                                config.emailEnabled === null
                                                                                    ? "gap-7"
                                                                                    : "gap-3"
                                                                            }`}
                                                                        >
                                                                            <SwitchNeoGen
                                                                                enabled={config.appEnabled}
                                                                                setEnabled={() =>
                                                                                    setEnabled(
                                                                                        config.alertRuleId,
                                                                                        "appEnabled",
                                                                                    )
                                                                                }
                                                                            />
                                                                            {config.emailEnabled !== null ? (
                                                                                <SwitchNeoGen
                                                                                    enabled={config.emailEnabled}
                                                                                    setEnabled={() =>
                                                                                        setEnabled(
                                                                                            config.alertRuleId,
                                                                                            "emailEnabled",
                                                                                        )
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                "N/A"
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                            <div className="flex justify-end">
                                                                <ButtonNeoGen
                                                                    className="mt-5"
                                                                    onClick={() => setShowSettings(!showSettings)}
                                                                >
                                                                    Close
                                                                </ButtonNeoGen>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div>No notifications are configured for you at this time.</div>
                                                    )
                                                ) : appAlerts && appAlerts.length > 0 ? (
                                                    <>
                                                        <div className="flex items-center justify-end gap-3">
                                                            <ButtonNeoGen
                                                                type="outline-primary"
                                                                size="xs"
                                                                onClick={() => handleUpdate("read")}
                                                                disabled={
                                                                    !appAlerts ||
                                                                    appAlerts?.filter(
                                                                        (alert: Alert) => alert.status === "unread",
                                                                    )?.length === 0
                                                                }
                                                            >
                                                                Mark all as read
                                                            </ButtonNeoGen>
                                                            <ButtonNeoGen
                                                                type="outline-primary"
                                                                size="xs"
                                                                onClick={() => handleUpdate("archived")}
                                                                disabled={!appAlerts || appAlerts?.length === 0}
                                                            >
                                                                Archive all
                                                            </ButtonNeoGen>
                                                        </div>
                                                        {appAlerts.map((alert: Alert) => {
                                                            return alert.link ? (
                                                                <a
                                                                    key={alert.id}
                                                                    href={alert.link}
                                                                    className="p-3 hover:cursor-pointer"
                                                                >
                                                                    <div className="p-3 border-b-2 border-b-gray-200 flex items-center justify-between gap-3">
                                                                        <div>
                                                                            <div className="flex items-center gap-3">
                                                                                {alert.status === "unread" && (
                                                                                    <div className="h-2 w-2 rounded-full bg-blue-600" />
                                                                                )}
                                                                                <p className="text-xs font-semibold">
                                                                                    {alert.title}
                                                                                </p>
                                                                            </div>
                                                                            <p>{alert.body}</p>
                                                                        </div>
                                                                        <p className="text-xs text-gray-400 text-right">
                                                                            {/* {format(alert.createdAt, "kk:mm dd/MM/yy")} */}
                                                                            {formatDistanceToNow(alert.createdAt)} ago
                                                                        </p>
                                                                    </div>
                                                                </a>
                                                            ) : (
                                                                <div
                                                                    className="p-3 border-b-2 border-b-gray-200 flex items-center justify-between gap-3"
                                                                    key={alert.id}
                                                                >
                                                                    <div>
                                                                        <div className="flex items-center gap-3">
                                                                            {alert.status === "unread" && (
                                                                                <div className="h-2 w-2 rounded-full bg-blue-600" />
                                                                            )}
                                                                            <p className="text-xs font-semibold">
                                                                                {alert.title}
                                                                            </p>
                                                                        </div>
                                                                        <p>{alert.body}</p>
                                                                    </div>
                                                                    <p className="text-xs text-gray-400 text-right">
                                                                        {/* {format(alert.createdAt, "kk:mm dd/MM/yy")} */}
                                                                        {formatDistanceToNow(alert.createdAt)} ago
                                                                    </p>
                                                                </div>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    "Your notifications list is empty"
                                                )}
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <NewMessage show={open} setShow={setOpen} type={1} />
        </>
    );
}
